import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import AppConstants from 'src/app/shared/commons/app.constants';
@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(private httpClient: HttpClient) {}
  getTagList(): Observable<any> {
    return this.httpClient.get(
      AppConstants.SERVICE_BASE_URL + '/api/common/Tag'
    );
  }
  getById(id: any) {
    return this.httpClient.get<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Tag/' + id
    );
  }
  add(tag: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/tag/addtag',
      tag,
      { headers: AppConstants.getHeaders() }
    );
  }
  update(id: any, tag: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Tag/' + id,
      tag,
      { headers: AppConstants.getHeaders() }
    );
  }
  delete(id: number) {
    return this.httpClient.delete<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/soft/Tag/' + id
    );
  }
  getTagListByTag(name: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/tag/getbyTagName/Tag/',
      name
    );
  }
}
