import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { Router } from '@angular/router';
import { OrderService  } from '../order/order.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  orderList:any=[];
  productid:any='';
  orderid:any={};
  editId:any=[];
  errorDialog:any;
  somethingWrong:any;
  successDialog:any;
  deleteId:any;
  dtOptions: DataTables.Settings = {}; 
  dtTrigger: Subject<any> = new Subject<any>();
  title = 'datatables';
  role:any='';
  editOrderFlag=false;
  editData:any=[];
  productMultipleArray:any=[];
  orderForm=new FormGroup({});
  oredreDetail:any={};
  showAddproductValid:any = [];
  productList:any = [];
  productId:any=[];
  product:any;
  notes:any;
  constructor( private formBuilder: FormBuilder,public orderService: OrderService,public router: Router,private translateService: TranslateService, public alertService: AlertService) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };
   
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.getAllOrder();
    this.getProductlist();
  }
  createForm() {   
    this.orderForm = this.formBuilder.group({
      id:[''],
      product_quantity: [''],
    });
  }
  getAllOrder()
  {
    this.orderList=[];
    this.orderService.getOrdersList().subscribe(
      (data) => {
       
        if (data['is_error'] == false) {      
          for(var i=0;i<data['data'].length;i++)
          {
            var str = data['data'][i]['Face_Shape_id']['customer_name'];
             var splits = str.split(" ");  
             this.orderList.push({'name':splits[0],'surname':splits[1],'order_detail':data['data'][i]})

          }
          // console.log(this.orderList);
          this.dtTrigger.next();       
        } 
      },
      (error: HttpErrorResponse) => {
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  editOrder(id :any){
   this.editId = id; 
    this.createForm();
    this.editOrderFlag=true;
    this.productMultipleArray = [];
    this.showAddproductValid = [];
    this.productId = [];
    this.getproductById(id);  
    }

    getproductById(id:any){
      this.orderid='';
      this.productMultipleArray = [];
      this.orderService.getById(id).subscribe(
        (data) => {
         // console.log(data['data']['orders']);
          this.editData = data['data']['orders'];
          this.orderid= data['data'];
          for(let i=this.productList.length-1;i>=0;i--){
              for(let j=0;j<this.editData.length;j++){
                if(this.productList[i] && (this.productList[i].id === this.editData[j].product_id.id)){
                  this.productList.splice(i,1);
                }
              }
          }
          // console.log (this.orderid);
          for(var i=0;i< data['data']['orders'].length;i++)
          {
            this.productMultipleArray.push({'product_id': data['data']['orders'][i]['product_id'].id,'product_quantity': data['data']['orders'][i].product_quantity})
            this.showAddproductValid.push(false)
            this.productId.push('');
          }
         //console.log(this.productMultipleArray);
        },
        (error: HttpErrorResponse) => {
          // console.log(error.name + ' ' + error.message);
          // this.alertService.showDangerAlert('Error', 'Something went wrong while adding an salon detail.');
        }
      );
  
    }
    addProduct(){
      //this.getproductById(this.editId);
      this.showAddproductValid.push(true);
      this.productId.push('');
      this.productMultipleArray.push({'product_id':'','product_quantity':null});
      // console.log('multiple',this.productMultipleArray)
      this.editData.push({'product_id':{'id':'','product_name':'','product_brand':'','product_price':''},'product_quantity':null});
      // console.log('productids',this.productId)
      // this.productList = this.productList.filter((res:any)=>{
      //   if(res.id !== this.productId[this.productId.length-2]){
      //     return res;
      //   }
      // })
    }
    getProductlist(){
      this.orderService.getProductList().subscribe(res=>{
        if(res['data'] && res['is_error']==false){
          this.productList = res["data"];
        }
      })
    }
    selectProduct(i:any){
      this.product = this.productList.filter((res:any)=>{
        if(res.id==this.productId[i]){
          return res;
        }
      })
      if(this.product.length>0){
        this.editData[i].product_id.id= this.product[0].id;
        this.editData[i].product_id.product_name = this.product[0].product_name;
        this.editData[i].product_id.product_brand = this.product[0].product_brand;
        this.editData[i].product_id.product_price = this.product[0].product_price;
        this.productMultipleArray[i].product_id = this.product[0].id;
      }
      // console.log('product',this.product);
    }
    submitOrderForm()
    {
      this.oredreDetail.order_status="worked";
      this.oredreDetail.is_confirm = "true";
      this.oredreDetail.orders=this.productMultipleArray;
      // console.log(this.oredreDetail);
      this.orderService.update(this.editId,this.oredreDetail).subscribe(
        (data) => {
          this.dtTrigger.unsubscribe();
          let updateMsg = this.translateService.instant('order.orderconfirm');
          this.alertService.showSuccessAlert(this.successDialog, updateMsg);
          setTimeout(() => {
            this.getAllOrder();
          }, 1000);
        },
        (error: HttpErrorResponse) => {
          // console.log(error.name + ' ' + error.message);
          this.alertService.showDangerAlert(
            this.errorDialog,
            this.somethingWrong
          );
        }
      );

    }
    showModal(id:any)
    {
      this.deleteId = id;
    }
    showNotes(note:any){
      this.notes = note
    }
    deleteProduct(productid:any,orderid:any)
    {
     this.productid = productid;
     this.orderid = orderid;
    }
    deleteProductDetails(productid:any,orderid:any,typeDelete?:any,index?:any)
    {
     let productDetails :any={};
     productDetails.order_id=orderid;
     productDetails.product_id=productid;
    //  console.log(productDetails);
     if(typeDelete!='simpleRemove'){
      this.orderService.deleteProduct(productDetails).subscribe(
        (data) => {         
          let DeleteMsg = this.translateService.instant('product.productDeleteMsg');
          this.alertService.showSuccessAlert(this.successDialog, DeleteMsg);
          setTimeout(() => {
          this.productMultipleArray = [];
          this.showAddproductValid = [];
          this.productId = [];
            this.getproductById(orderid);
          }, 1000);
        },
        (error: HttpErrorResponse) => {
          // console.log(error.name + ' ' + error.message);
          this.alertService.showDangerAlert(
            this.errorDialog,
            this.somethingWrong
          );
        }
      );
    }else{
      this.editData.splice(index,1);
      this.productMultipleArray.splice(index,1);
      this.productId.splice(index,1);
      // console.log(this.productMultipleArray)
    }  
    }
    deleteOrder(id:any)
    {
     // console.log(id);
      this.orderService.delete(id).subscribe(
        (data) => {
          this.dtTrigger.unsubscribe();
          let DeleteMsg = this.translateService.instant('customer.customerdeleteMsg');
          this.alertService.showSuccessAlert(this.successDialog, DeleteMsg);
          setTimeout(() => {
            this.getAllOrder();
          }, 1000);
        },
        (error: HttpErrorResponse) => {
          // console.log(error.name + ' ' + error.message);
          this.alertService.showDangerAlert(
            this.errorDialog,
            this.somethingWrong
          );
        }
      );  
    }
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
}
