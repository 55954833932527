import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { TagService } from '../../tags/tag.service';
import { TrainerService } from '../trainer.service';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import AppConstants from 'src/app/shared/commons/app.constants';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-addtrainer',
  templateUrl: './addtrainer.component.html',
  styleUrls: ['./addtrainer.component.css'],
})
export class AddtrainerComponent implements OnInit {
  errorDialog: any;
  loading = false;
  successDialog: any;
  somethingWrong: any;
  editData: any;
  baseURL = AppConstants.SERVICE_BASE_URL;
  hair_length = AppConstants.hair_length;
  hair_type = AppConstants.hair_type;
  hair_color = AppConstants.hair_color;
  age = AppConstants.age;
  imgurl: any;
  imgurlThumb: any;
  imageUrlThumb: any;
  tagList: any = [];
  tag_checked = false;
  submitted = false;
  hairLengthArray: any = [];
  hairTypehArray: any = [];
  hairColorArray: any = [];
  ageArray: any = [];
  tarinerId: any;
  tagErrorMsg = '';
  isAddMode: boolean = true;
  private image: any;
  private imageThumb: any;
  imageUrl: any;
  request: any = {};
  tagarray: any = [];
  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    public tagService: TagService,
    public alertService: AlertService,
    public trainerService: TrainerService,
    public router: Router
  ) {}
  trainerform = new FormGroup({});
  ngOnInit(): void {
    this.loading = false;
    // this.loading = true;
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.tarinerId = localStorage.getItem('trainer_id');
    if (this.tarinerId != null) {
      this.isAddMode = false;
      this.editTrainer(this.tarinerId);
      this.updateValidation();
    } else {
      this.addValidation();
    }
    this.getTagList();
  }
  addValidation() {
    this.trainerform = this.formBuilder.group({
      image_path_name: ['', [Validators.required]], //video
      image_thum_name: ['', [Validators.required]], //thumb
      title: ['', Validators.required],
      tags: [''],
      hair_length: [''],
      hair_type: [''],
      hair_color: [''],
      age: [''],
    });
  }
  updateValidation() {
    this.trainerform = this.formBuilder.group({
      image_path_name: [''],
      image_thum_name: [''], //thumb
      title: ['', Validators.required],
      tags: [''],
      hair_length: [''],
      hair_type: [''],
      hair_color: [''],
      age: [''],
    });
  }
  onSubmit() {
    this.submitted = true;
    const formData = new FormData();
    formData.append('file_original_name', this.imageThumb);
    formData.append('video_original_name', this.image);
    this.request.title = this.trainerform.controls['title'].value;
    this.request.tags = this.tagarray;
    this.request.hair_length = this.hairLengthArray;
    this.request.hair_type = this.hairTypehArray;
    this.request.hair_color = this.hairColorArray;
    this.request.age = this.ageArray;
    let requestNew = JSON.stringify(this.request);
    formData.append('request', requestNew);

    if (this.trainerform.invalid) {
      return;
    }
    if (this.isAddMode) {
      this.addTrainer(formData);
    } else {
      this.updateTrainer(formData);
    }
  }
  addTrainer(formData: any) {
    this.loading = true;
    this.trainerService.add(formData).subscribe(
      (data) => {
        this.loading = false;
        let trainerAdd = this.translateService.instant('trainer.trainerAdded');
        this.alertService.showSuccessAlert(this.successDialog, trainerAdd);
        this.router.navigate(['trainerList']);
      },
      (error: HttpErrorResponse) => {
        //console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  editTrainer(id: any) {
    this.trainerService.getById(id).subscribe(
      (data) => {
        this.editData = data;
        // console.log(this.editData['data']);
        this.imgurl = this.editData['data'].file_path_name;
        this.imgurlThumb = this.editData['data'].file_path_name;
        this.formValidation.title.setValue(this.editData['data'].title);
        for (var i = 0; i < this.editData['data'].tags.length; i++) {
          this.tagarray.push(this.editData['data'].tags[i].id);
        }
        this.hairColorArray = this.editData['data'].hair_color;
        this.hairTypehArray = this.editData['data'].hair_type;
        this.hairLengthArray = this.editData['data'].hair_length;
        this.ageArray = this.editData['data'].age;
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        //  this.alertService.showDangerAlert('Error', 'Something went wrong while adding an salon detail.');
      }
    );
  }
  updateTrainer(formData: any) {
    this.loading = true;
    this.trainerService.update(this.tarinerId, formData).subscribe(
      (data) => {
        this.loading = false;
        this.router.navigate(['trainerList']);
        let trainerUpdate = this.translateService.instant(
          'trainer.trainerUpdate'
        );
        this.alertService.showSuccessAlert(this.successDialog, trainerUpdate);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }

  onFileChange($event: any) {
    const files = $event.target.files || $event.srcElement.files;
    const file = files[0];
    const type = this.checkExtension(file);
    if (file && type != -1) {
      this.image = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        this.imageUrl = event.target.result;
      };
    } else {
      let videoselect = this.translateService.instant('trainer.videoMsg');
      this.alertService.showDangerAlert(this.errorDialog, videoselect);
    }
  }
  checkExtension(file: any) {
    let mimeType = ['video/mp4', 'video/x-m4v', 'video/*'];
    return mimeType.indexOf(file.type);
  }

  onFileChangeThumb($event: any) {
    const files = $event.target.files || $event.srcElement.files;
    const file = files[0];
    const type = this.checkExtensionThumb(file);
    if (file && type != -1) {
      this.imageThumb = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        this.imageUrlThumb = event.target.result;
      };
    } else {
      let imagReq = this.translateService.instant('image.imageReq');
      this.alertService.showDangerAlert(this.errorDialog, imagReq);
    }
  }
  checkExtensionThumb(file: any) {
    let mimeType = ['image/png', 'image/jpeg', 'image/jpg'];
    return mimeType.indexOf(file.type);
  }
  getTagList() {
    this.tagService.getTagList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.tagList = data.data;
          //console.log(this.tagList);
        } else {
          // this.alertService.showDangerAlert('Error', 'tag not exist.');
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  get formValidation() {
    return this.trainerform.controls;
  }
  onChange(index: number, _id: string, isChecked: any) {
    this.tag_checked = true;
    if (isChecked.target.checked) {
      this.tagarray.push(_id);
    } else {
      this.tagarray.splice(this.tagarray.indexOf(_id), 1);
    }
    // console.log(this.tagarray);
    this.formValidation.tags.setValue(this.tagarray);
  }

  onChangeHairLength(index: number, _id: number, isChecked: any) {
    if (isChecked.target.checked) {
      this.hairLengthArray.push(_id);
    } else {
      this.hairLengthArray.splice(this.hairLengthArray.indexOf(_id), 1);
    }
    this.formValidation.hair_length.setValue(this.hairLengthArray);
    // console.log(this.hairLengthArray);
  }

  onChangeHairType(index: number, _id: number, isChecked: any) {
    if (isChecked.target.checked) {
      this.hairTypehArray.push(_id);
    } else {
      this.hairTypehArray.splice(this.hairTypehArray.indexOf(_id), 1);
    }
    this.formValidation.hair_type.setValue(this.hairTypehArray);
  }

  onChangeAge(index: number, _id: number, isChecked: any) {
    if (isChecked.target.checked) {
      this.ageArray.push(_id);
    } else {
      this.ageArray.splice(this.ageArray.indexOf(_id), 1);
    }
    this.formValidation.age.setValue(this.ageArray);
  }
  onChangeHairColor(index: number, _id: number, isChecked: any) {
    if (isChecked.target.checked) {
      this.hairColorArray.push(_id);
    } else {
      this.hairColorArray.splice(this.hairColorArray.indexOf(_id), 1);
    }
    this.formValidation.hair_color.setValue(this.hairColorArray);
  }
}
