import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import AppConstants from 'src/app/shared/commons/app.constants';
@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private httpClient: HttpClient) {}
  getProductList(): Observable<any> {
    return this.httpClient.get(
      AppConstants.SERVICE_BASE_URL + '/api/common/Product'
    );
  }

  getById(id: any) {
    return this.httpClient.get<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Product/' + id
    );
  }
  add(product: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/product/addproduct',
      product
    );
  }
  update(id: any, product: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/product/updateproduct/' + id,
      product
    );
  }
  delete(id: number) {
    return this.httpClient.delete<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/soft/Product/' + id
    );
  }
  getSalonListByName(name: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/salon/getbySalonName/Salon',
      name
    );
  }
  getproductListBysalonId(id: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/product/getproductbysalon',
      id
    );
  }
}
