import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ImageService } from 'src/app/components/images/image.service';
import { TranslateService } from '@ngx-translate/core';
import { MessagingService } from '../../services/messaging.service'
declare var $: any;
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent implements OnInit {
  logindata: any;
  loginMsg: any;
  submitted = false;
  constructor(
    private translateService: TranslateService,
    public imageService: ImageService,
    private formBuilder: FormBuilder,
    public router: Router,
    public authService: AuthService,
    public alertService: AlertService,
    private messagingService:MessagingService
  ) {}
  form = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });
  message:any;
  //form validation
  get formvalidation() {
    return this.form.controls;
  }
  //user Login
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      this.router.navigate(['/']);
    } else {
      localStorage.removeItem('edit_id');
      this.authService
        .login(
          this.formvalidation.username.value,
          this.formvalidation.password.value,
          1
        )
        .subscribe(
          (data) => {
            this.logindata = data;
            // console.log( this.logindata);
            if (!this.logindata['is_error']) {
             // console.log(this.logindata['data']['user']['salonName']);
             // console.log(this.logindata['data']['user']['role']);
              localStorage.setItem(
                'user',
                JSON.stringify(this.logindata['data']['user']['salonName'])
              );
              localStorage.setItem(
                'userId',this.logindata['data']['user']['_id']
              )
              localStorage.setItem(
                'role',
                JSON.stringify(this.logindata['data']['user']['role'])
              );
              // this.messagingService.requestPermission()
              // this.messagingService.receiveMessage()
              // this.message = this.messagingService.currentMessage
              if(this.logindata['data']['user']['role']=="2")
              
              this.router.navigateByUrl('/dashboard');
              else if(this.logindata['data']['user']['role']=="1")
              this.router.navigateByUrl('/productList');
            } else {
              this.alertService.showDangerAlert('Error', 'Invalid Credential');
              this.form.reset();
              this.router.navigateByUrl('/');
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  ngOnInit() {
    if (this.authService.isLoggedIn == true) {
      this.router.navigateByUrl('/dashboard');
    }
  }
  ngAfterViewInit(){
    $(document).ready(function(){
      
      $('.success').prepend('<a></a>');
      $('.success a:last').attr('href','./orderList');
      let html = $('.success').html()
      $('a:last').html(html);
      $('a:last').parent().next().remove()
    

    })
  }
}
