<app-sidebar></app-sidebar>

<div class="body-padding-top">
    <div class="container-fluid">
      <div class="row pt-3">
        <div class="col-12">
          <h1 class="page-title">
            Add Health
          </h1>
        </div> 
      </div> 
      <div class="row pt-3">
        <div class="col-12 col-xl-12">
          <form [formGroup]="healthform"  class="form" (ngSubmit)="onSubmit()">
            <div class="row mb-3">
              <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
                <label>
                  Select image
                </label>
              </div>
              <div class="col-12 col-sm-12 col-xl-9">
                <div class="input-group mb-2 up-load-img-box">
                  <input  formControlName="image_path_name"                      
                  id="file"                       
                  type="file"                       
                  class="form-control" 
                  multiple=""
                  accept="image/x-png,image/jpeg" 
                  (change)="onFileChange($event)" [ngClass]="{ 'is-invalid': submitted && formValidation.image_path_name.errors }"/>  
                  <div *ngIf="submitted && formValidation.image_path_name.errors" class="invalid-feedback">
                    <div *ngIf="formValidation.image_path_name.errors.required"> image is required</div>
                </div>             
                </div>
              </div>
            </div> 
            <div class="row mb-3">
                <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
                  <label>
                  problem Name
                  </label>
                </div>
                <div class="col-12 col-sm-12 col-xl-9">
                  <div class="form-group input-group">                    
                    <input type="text" formControlName="problem_name" class="form-control small-control" placeholder="Problem Name"  maxlength="50" pattern="^[a-zA-Z_\- ]+$"  [ngClass]="{ 'is-invalid': submitted && formValidation.problem_name.errors }" />
                    <div *ngIf="submitted && formValidation.problem_name.errors" class="invalid-feedback">
                        <div *ngIf="formValidation.problem_name.errors.required"> Problem Name is required</div>
                        <div *ngIf="formValidation.problem_name.errors.pattern"> Problem Name should contain only alphabate (a-z)</div>
                    </div>
                </div>
                </div>
              </div>  
              <div class="row mb-3">
                <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
                  <label>
                    Curative therapy
                  </label>
                </div>
                <div class="col-12 col-sm-12 col-xl-9">
                  <div class="form-group input-group">                    
                    <textarea formControlName="curative_therapy" class="form-control"  placeholder=" Curative therapy" rows="5"  ></textarea>
                </div>
                </div>
              </div> 
              <div class="row mb-3">
                <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
                  <label>
                   Select Products
                  </label>
                </div>
                <div class="col-12 col-sm-12 col-xl-9">
                  <div class="input-group images-checkbox-box">
                    <div class="checkbox-box" style="display:flex !important;" >
                      <label class="container">product1
                        <input type="checkbox"    (change)="onChange(1,'1',$event)">
                        <span class="checkmark"></span>
                      </label> 
                    </div>                    
                  </div>
                  </div>
                
                </div> 
            <div class="text-right">
              <button type="submit" class="btn btn-default mr-2">Save</button>
              <button type="button" class="btn btn-default btn-secondary" routerLink="/healthList">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>