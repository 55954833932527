import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FaceshapeService } from '../faceshape/faceshape.service';
import AppConstants from 'src/app/shared/commons/app.constants';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-faceshape',
  templateUrl: './faceshape.component.html',
  styleUrls: ['./faceshape.component.css'],
})
export class FaceshapeComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    public faceShapeService: FaceshapeService,
    public router: Router,
    public alertService: AlertService
  ) {}
  errorDialog: any;
  baseURL = AppConstants.SERVICE_BASE_URL;
  folderName: any;
  successDialog: any;
  somethingWrong: any;
  index = 1;
  deleteId = '';
  deletepath = '';
  request: any = {};
  glass: any = [];
  other: any = [];
  editData: any;
  submitted = false;
  image: any = '';
  imageUrl: any = '';
  images: string[] = [];
  otherImages: string[] = [];
  otherimage = '';
  otherimageUrl = '';
  deleteData: any = [];
  errorToEnanche = '';
  errorToAvoid = '';
  shapeindex = 11;
  to_enanche_checked = false;
  to_avoid_checked = false;
  faceshapeform = new FormGroup({});
  face_shape_type = '1';
  face_shape_name = '';
  formData = new FormData();
  editotherFlag = false;
  otherDesc:any;
  otherLink:any;
  face_shape_id:any;
  glassOtherImage:any;
  ngOnInit(): void {
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.createFaceValidation();
    this.getFaceShapeByID(1);
    
  }
  createFaceValidation() {
    this.faceshapeform = this.formBuilder.group({
      to_enanche: new FormArray([new FormControl('')]),
      to_avoid: new FormArray([new FormControl('')]),
      face_shape_name: [''],
      face_shape_type: [''],
      glass: [''],
      fileSource: [''],
      filesList: [''],
      other: [''],
      otherfilesList: [''],
    });
  }

  get to_enanche():FormArray{
    return this.faceshapeform.get('to_enanche') as FormArray
  }

  get to_avoid():FormArray{
    return this.faceshapeform.get('to_avoid') as FormArray
  }

  addEnhance(){
    this.to_enanche.push(new FormControl('')) 
  }

  addAvoid(){
    this.to_avoid.push(new FormControl('')) 
  }

  removeEnhance(i:number){
    this.to_enanche.removeAt(i);
  }
  removeAvoid(i:number){
    this.to_avoid.removeAt(i);
  }

  onSubmit() {
    this.submitted = true;
    this.formValidation.face_shape_type.setValue(this.face_shape_type);
    this.formValidation.face_shape_name.setValue(this.face_shape_name);
    for (var i = 0; i < this.images.length; i++) {
      this.formData.append('glass', this.images[i]);
    }
    for (var i = 0; i < this.otherImages.length; i++) {
      this.formData.append('other', this.otherImages[i]);
    }
    if (this.face_shape_name) {
      this.formData.append('face_shape_name', this.face_shape_name);
    } else {
      this.face_shape_name = 'Rectangle Male';
      this.formData.append('face_shape_name', this.face_shape_name);
    }
    if (this.face_shape_type) {
      this.formData.append('face_shape_type', this.face_shape_type);
    } else {
      this.face_shape_type = '1';
      this.formData.append('face_shape_type', this.face_shape_type);
    }
    let enhancheArr:any[] = [];
    enhancheArr = this.to_enanche.value;
    for(let i=0;i<this.to_enanche.length;i++){
      if(enhancheArr[i]!='' && enhancheArr[i]!=null){
      this.formData.append(
        'to_enhance',
        enhancheArr[i]
      );
    }
    }

    let avoideArr:any[] = [];
    avoideArr = this.to_avoid.value;
    for(let i=0;i<this.to_avoid.length;i++){
      if(avoideArr[i]!='' && avoideArr[i]!=null){
      this.formData.append(
        'to_avoid',
        avoideArr[i]
      );
      }
    }


    console.log('enhance',this.to_enanche);
    
    // this.formData.append(
    //   'to_avoid',
    //   this.faceshapeform.controls['to_avoid'].value
    // );
    if (this.faceshapeform.invalid) {
      this.to_enanche_checked = true;
      this.to_avoid_checked = true;
      let enanchereq = this.translateService.instant('face.enanchereq');
      this.errorToEnanche = enanchereq;
      let avoidreq = this.translateService.instant('face.toavoidreq');
      this.errorToAvoid = avoidreq;
      return;
    }
    this.faceShapeService.add(this.formData).subscribe(
      (data) => {
        // console.log(data['message']);
        let faceshapeMsg = this.translateService.instant('faceshapeMsg');
        this.alertService.showSuccessAlert(this.successDialog, faceshapeMsg);
        setTimeout(() => {
          // this.index=1;
          this.getFaceShapeByID(1);
          window.location.reload(true);
        }, 1000);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }

  onFileChange($event: any) {
    //   if ($event.target.files && $event.target.files[0]) {
    //     var filesAmount = $event.target.files.length;
    //     for (let i = 0; i < filesAmount; i++) {
    //             var reader = new FileReader();
    //             reader.onload = (event:any) => {
    //               //console.log(event.target.result);
    //                this.images.push(event.target.result);
    //                this.faceshapeform.patchValue({
    //                   fileSource: this.images,
    //                  // filesList:this.faceshapeform,
    //                });
    //             }
    //             reader.readAsDataURL($event.target.files[i]);
    //     }
    // }
    const files = $event.target.files || $event.srcElement.files;
    var filesAmount = $event.target.files.length;
    for (let i = 0; i < filesAmount; i++) {
      const file = files[i];
      const type = this.checkExtension(file);

      if (file && type != -1) {
        this.image = file;
        this.images.push(this.image);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event: any) => {
          this.imageUrl = event.target.result;
        };
      } else {
        let imageMsg = this.translateService.instant('imageValidation');
        this.alertService.showDangerAlert(this.errorDialog, imageMsg);
      }
    }
  }

  ClickFaceShape(id: any, name: any) {
    this.glass = [];
    this.other = [];
    this.index = 1;
    this.face_shape_type = id;
    this.face_shape_name = name;
    this.getFaceShapeByID(id);
  }
  getFaceShapeByID(id: any) {
    // this.formValidation.to_avoid.setValue(['']);
    // this.formValidation.to_enanche.setValue(['']);
    // this.formValidation.to_enanche.setValue(['']);
    this.to_enanche_checked = false;
    this.to_avoid_checked = false;
    this.faceShapeService.getById(id).subscribe(
      (data) => {
        if (data['is_error'] == false) {
          this.editData = data;
          this.folderName = this.editData['data']['folderName'];
          // console.log(this.folderName);
          this.glass = this.editData['data']['face_shape_list'].glass;
          this.deleteData = this.editData['data']['face_shape_list'];
          this.other = this.editData['data']['face_shape_list'].other;
          // this.formValidation.to_avoid.setValue(
          //   this.editData['data']['face_shape_list'].to_avoid
          // );
          // this.formValidation.to_enanche.patchValue(
          //   this.editData['data']['face_shape_list'].to_enhance
          // );
          this.faceshapeform.setControl('to_enanche',this.formBuilder.array(this.editData['data']['face_shape_list'].to_enhance || ['']))
          this.faceshapeform.setControl('to_avoid',this.formBuilder.array(this.editData['data']['face_shape_list'].to_avoid || ['']))
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        // this.alertService.showDangerAlert('Error', 'Something went wrong while adding an salon detail.');
      }
    );
  }

  addupdateglass(id:any,imageName:any,i:number){
    this.glassOtherImage = '';
    this.face_shape_id = id;
    this.glassOtherImage = imageName;
    this.otherDesc = '';
    this.otherLink = '';
    if(this.glass && this.glass[i].glass_description){
      this.otherDesc = this.glass[i].glass_description;
    }
    if(this.glass && this.glass[i].glass_link){
      this.otherLink = this.glass[i].glass_link;
    }

  }

  AddUpdateDescLinkGlass(){
    let content = {
                    "glass_image": this.glassOtherImage,
                    "glass_description": this.otherDesc,
                    "glass_link": this.otherLink
                  }
      this.faceShapeService.AddUpdateDescAndLink(this.face_shape_id,content).subscribe(res=>{
        if(res['is_error']==false){
          let glassupdate = this.translateService.instant('face.glassdescsuccess');
          this.alertService.showSuccessAlert(this.successDialog,glassupdate)
          this.getFaceShapeByID(this.face_shape_type);
        }else{
          this.alertService.showDangerAlert(this.somethingWrong,res['msg'])
        }
      })
    
  }


  addupdateother(id:any,imageName:any,i:number){
    this.glassOtherImage = '';
    this.face_shape_id = id;
    this.glassOtherImage = imageName;
    this.otherDesc = '';
    this.otherLink = '';
    if(this.other && this.other[i].other_description){
      this.otherDesc = this.other[i].other_description;
    }
    if(this.other && this.other[i].other_link){
      this.otherLink = this.other[i].other_link;
    }

  }

  AddUpdateDescLinkOther(){
    let content = {
                    "other_image": this.glassOtherImage,
                    "other_description": this.otherDesc,
                    "other_link": this.otherLink
                  }
      this.faceShapeService.AddUpdateDescAndLink(this.face_shape_id,content).subscribe(res=>{
        if(res['is_error']==false){
          let otherupdate = this.translateService.instant('face.otherdescsuccess');
          this.alertService.showSuccessAlert(this.successDialog,otherupdate)
          this.getFaceShapeByID(this.face_shape_type);
        }else{
          this.alertService.showDangerAlert(this.somethingWrong,res['msg'])
        }
      })
    
  }


  currentEnanche() {
    this.index = 1;
  }

  currentAvoid() {
    this.index = 2;
    // if (this.faceshapeform.controls['to_enanche'].value) {
    // 	this.to_enanche_checked = false;
    // 	this.index = 2;
    // } else {
    // 	this.index = 1;
    // 	this.to_enanche_checked = true;
    // 	this.errorToEnanche = 'To enanche is required';
    // }
  }

  currentAccessories() {
    this.index = 3;
  }

  nextavoid() {
    if (this.faceshapeform.controls['to_enanche'].value) {
      this.to_enanche_checked = false;
      this.index = 2;
    } else {
      this.index = 1;
      this.to_enanche_checked = true;
      let enanchereq = this.translateService.instant('face.enanchereq');
      this.errorToEnanche = enanchereq;
    }
  }
  prevenanche() {
    this.index = 1;
  }
  nextaccessories() {
    if (this.faceshapeform.controls['to_avoid'].value) {
      this.to_avoid_checked = false;
      this.index = 3;
    } else {
      this.index = 2;
      this.to_avoid_checked = true;
      let avoidreq = this.translateService.instant('face.toavoidreq');
      this.errorToAvoid = avoidreq;
    }
  }
  prevAvoid() {
    this.index = 2;
  }
  get formValidation() {
    return this.faceshapeform.controls;
  }

  checkExtension(file: any) {
    let mimeType = ['image/png', 'image/jpeg', 'image/jpg'];
    return mimeType.indexOf(file.type);
  }
  onOtherFileChange($event: any) {
    const files = $event.target.files || $event.srcElement.files;
    var filesAmount = $event.target.files.length;
    for (let i = 0; i < filesAmount; i++) {
      const file = files[i];
      const type = this.checkExtension(file);
      if (file && type != -1) {
        this.otherimage = file;
        this.otherImages.push(this.otherimage);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event: any) => {
          this.otherimageUrl = event.target.result;
        };
      } else {
        let imageMsg = this.translateService.instant('imageValidation');
        this.alertService.showDangerAlert(this.errorDialog, imageMsg);
      }
    }
  }

  removeSelectedFile(id: any, path: any) {
    this.deleteId = id;
    this.deletepath = path;
  }
  deleteGlassImage(id: any, path: any) {
    this.request.glass = path;
    // console.log(this.request);
    this.faceShapeService.deleteImage(id, this.request).subscribe(
      (data) => {
        // console.log(data);
        let imagedelete = this.translateService.instant('imageDelete');
        this.alertService.showSuccessAlert(this.successDialog, imagedelete);
        setTimeout(() => {
          window.location.reload(true);
          this.getFaceShapeByID(id);
        }, 1000);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  removeOtherSelectedFile(id: any, path: any) {
    this.deleteId = id;
    this.deletepath = path;
  }
  deleteOtherImage(id: any, path: any) {
    this.request.other = path;
    // console.log(this.request);
    this.faceShapeService.deleteImage(id, this.request).subscribe(
      (data) => {
        //this.dtTrigger.unsubscribe();
        let imagedelete = this.translateService.instant('imageDelete');
        this.alertService.showSuccessAlert(this.successDialog, imagedelete);
        setTimeout(() => {
          window.location.reload(true);
          this.getFaceShapeByID(id);
        }, 1000);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
}
