import { Component, OnInit } from '@angular/core';
import AppConstants from 'src/app/shared/commons/app.constants';
import { ProductService } from '../product/product.service';
import { ProducttagService } from '../product-tag/producttag.service';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HealthService } from '../health/health.service';
// import { debounce } from "rxjs/operators";
// import { interval, Subscription } from "rxjs";
@Component({
  selector: 'app-product-tag',
  templateUrl: './product-tag.component.html',
  styleUrls: ['./product-tag.component.css'],
})
export class ProductTagComponent implements OnInit {
  // mform: FormGroup = new FormGroup({
  //   name: new FormControl()
  // });
 
  // obs: Subscription = new Subscription;

  tagForm = new FormGroup({});
  searchProduct:any={};
  request: any = {};
  addNewTagFlag: boolean = true;
  tagPostData: any = {};
  tagArray: any = [];
  tag_product = false;
  hair_product = false;
  errorMessageTag = '';
  product_checked = false;
  errorMessageProduct = '';
  //request:any;
  tagDataList: any = [];
  public placeholder:any='';
  public keyword = 'product_tag_name';
  public not_found:any='';
  public historyHeading: any='';
  tagList: any = [];
  submitted = true;
  ProductIdArray: any = [];
  skinList = AppConstants.skinList;
  productList: any;
  hairProductarray: any = [];
  postData: any = {};
  errorDialog: any;
  successDialog: any;
  somethingWrong: any;
  productTagFilter = AppConstants.productTagFilter;
  constructor(
    public healthService: HealthService,
    private translateService: TranslateService,
    public productTagService: ProducttagService,
    public productService: ProductService,
    public alertService: AlertService,
    private formBuilder: FormBuilder,
    private router :Router
  ) {}

  ngOnInit(): void {
  

    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.placeholder= this.translateService.instant('productTag.entertagname'); 
    this.historyHeading= this.translateService.instant('tag.recentlySelected'); 
    this.not_found=this.translateService.instant('tag.not_found');
    this.addNewTagFlag = true;
    this.getAllProduct();
    this.getTagList();
    this.createform();
  }
  createform() {
    this.tagForm = this.formBuilder.group({
      product_tag_name: [{ value: '', disabled: false }, Validators.required],
    });
  }
  productTagListview(){
    this.router.navigate(['viewProductTagList']);

  }
  getAllProduct() {
    this.productService.getProductList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.productList = data.data;
        } else {
          //	this.alertService.showDangerAlert('Error', 'Product not exist.');
        }
      },
      (error: HttpErrorResponse) => {
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  get formValidation() {
    return this.tagForm.controls;
  }

  submitTagForm() {
    // console.log(this.tagForm.value);
    if (this.tagForm.valid) {
      this.productTagService.add(this.tagForm.value).subscribe(
        (data) => {
          if (data['is_error'] == true) {
            let tagExist = this.translateService.instant('productTag.tagExist');
            this.alertService.showDangerAlert(this.errorDialog, tagExist);
          } else {
            let tagAdded = this.translateService.instant('productTag.tagAddedMsg');
            this.alertService.showSuccessAlert(this.successDialog, tagAdded);
            this.addNewTagFlag = false;
            //$("body").removeClass("modal-open");
          }
          this.getTagList();
        },
        (error: HttpErrorResponse) => {
          this.alertService.showDangerAlert(
            this.errorDialog,
            this.somethingWrong
          );
        }
      );
    }
  }

  getTagList() {
    this.productTagService.getTagList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.tagList = data.data;
          for (var i = 0; i < this.tagList.length; i++) {
            this.tagDataList.push(this.tagList[i]['product_tag_name']);
          }
        } else {
          //this.alertService.showDangerAlert('Error', 'tag not exist.');
        }
      },
      (error: HttpErrorResponse) => {
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }

  onChangeProduct(index: number, _id: string, isChecked: any) {
    this.product_checked = true;
    if (isChecked.target.checked) {
      this.ProductIdArray.push(_id);
    } else {
      // if (this.ProductIdArray.indexOf(index) == 0) {
      // 	this.ProductIdArray.splice(this.ProductIdArray.indexOf(index), 1);
      // } else {
      this.ProductIdArray.splice(this.ProductIdArray.indexOf(_id), 1);
      //}
    }
    // console.log(this.ProductIdArray);
    // this.postData['product_id']=this.ProductIdArray;
  }
  onChangeProductTag(
    index: number,
    _id: number,
    isChecked: any,
    productTag: any
  ) {
    this.hair_product = false;
    this.tag_product = true;
    this.ProductIdArray = [];
    let request: any = {};
    this.tagPostData.product_tag_id = _id;
    request.tag_id = _id;
    this.productTagService.getProductByTag(_id).subscribe(
      (data) => {
        if (data['data'].length != 0) {
          if (data['is_error'] == false) {
            for (var i = 0; i < data['data'].product_id.length; i++) {
              this.product_checked = true;
              this.ProductIdArray.push(data['data'].product_id[i].id);
            }
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
    // this.formValidation.product_tag_name.setValue(this.hairProductarray);
  }

  onChangeProductHair(
    index: number,
    _id: number,
    isChecked: any,
    field: any,
    value: any
  ) {
    this.tag_product = false;
    this.hair_product = true;
    this.ProductIdArray = [];
    this.request.field = field;
    this.request.value = value;
    this.productTagService.getProductByHair(this.request).subscribe(
      (data) => {
        if (data['data'].length != 0) {
          if (data['is_error'] == false) {
            for (var i = 0; i < data['data'].length; i++) {
              this.product_checked = true;
              this.ProductIdArray.push(data['data'][i].id);
            }
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  onSubmitTagHairPro() {
    if (!this.product_checked) {
      let msgProduct = this.translateService.instant(
        'healthPro.selectOneProduct'
      );
      this.errorMessageProduct = msgProduct;
      return;
    }
    if (this.tag_product) {
      this.tagPostData.product_id = this.ProductIdArray;
      this.productTagService.addTagProduct(this.tagPostData).subscribe(
        (data) => {
          let tagproduct = this.translateService.instant(
            'productTag.productTagAssign'
          );
          this.alertService.showSuccessAlert(this.successDialog, tagproduct);
          setTimeout(() => {
            this.formRefresh();
          }, 1000);
        },
        (error: HttpErrorResponse) => {
          this.alertService.showDangerAlert(
            this.errorDialog,
            this.somethingWrong
          );
        }
      );
    } else if (this.hair_product) {
      this.request.product_id = this.ProductIdArray;
      this.productTagService.addHairProduct(this.request).subscribe(
        (data) => {
          let tagproduct = this.translateService.instant(
            'productTag.productTagAssign'
          );
          this.alertService.showSuccessAlert(this.successDialog, tagproduct);
          setTimeout(() => {
            this.formRefresh();
          }, 1000);
        },
        (error: HttpErrorResponse) => {
          this.alertService.showDangerAlert(
            this.errorDialog,
            this.somethingWrong
          );
        }
      );
    } else {
      this.errorMessageTag = this.translateService.instant(
        'productTag.tagFilter'
      );
    }
  }
  addNewTag() {
    this.tagForm.reset();
    this.addNewTagFlag = true;
  }

  formRefresh() {
    this.tag_product = false;
    this.hair_product = false;
    this.product_checked = false;
    this.ProductIdArray = [];
    this.productTagFilter = [];
    this.getTagList();
    setTimeout(() => {
      this.productTagFilter = AppConstants.productTagFilter;
    }, 100);
  }

  searchByProduct(name:any)  {
    this.searchProduct.product_name = name;
    // this.obs=this.mform.valueChanges
    // .pipe(debounce(() => interval(500)))
    // .subscribe(data => console.log(data));
    this.healthService.getProductListByProduct(this.searchProduct).subscribe(
      (data) => {
        //  console.log(data.is_error);
        if (data.is_error == false) {
          this.productList = data.data;
        }
       
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
}
