import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TrainerService } from '../trainer/trainer.service';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { TagService } from '../tags/tag.service';
import { Router } from '@angular/router';
import AppConstants from 'src/app/shared/commons/app.constants';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-trainer',
  templateUrl: './trainer.component.html',
  styleUrls: ['./trainer.component.css'],
})
export class TrainerComponent implements OnInit {
  currentPlayingVideo: any;
  errorDialog: any;
  successDialog: any;
  somethingWrong: any;
  baseURL = AppConstants.SERVICE_BASE_URL;
  hair_length = AppConstants.hair_length;
  hair_type = AppConstants.hair_type;
  hair_color = AppConstants.hair_color;
  age = AppConstants.age;
  trainerList: any = [];
  tagarray: any = [];
  tagArrayList = [{ tags: [] }];
  trainer: any = [];
  searchTagArray: any = [];
  deleteId = '';
  tagList: any = [];
  videoPath: any;
  searchTagTrainerList: any = [];
  searchTag: any = { tag_name: '' };
  hairLengthArray: any = [];
  hairTypehArray: any = [];
  hairColorArray: any = [];
  ageArray: any = [];
  postData: any = {};
  hair_length_arry = [{ hair_length: [] }];
  constructor(
    private translateService: TranslateService,
    public trainerService: TrainerService,
    public alertService: AlertService,
    public tagService: TagService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.postData.hair_length = [];
    this.postData.hair_type = [];
    this.postData.hair_color = [];
    this.postData.age = [];
    this.getTrainerList();
    this.getTagList();
  }
  getTrainerList() {
    this.trainerService.getTrainerList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.searchTagTrainerList = data.data;
          this.trainerList = data.data;
        } else {
          //this.alertService.showDangerAlert('Error', 'tag not exist.');
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  show(id: any) {
    this.deleteId = id;
  }
  deletetrainer(id: any) {
    this.trainerService.delete(id).subscribe(
      (data) => {
        let trainerDelete = this.translateService.instant(
          'trainer.trainerDelete'
        );
        this.alertService.showSuccessAlert(this.successDialog, trainerDelete);
        setTimeout(() => {
          this.getTrainerList();
        }, 5000);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  getTagList() {
    this.tagService.getTagList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.tagList = data.data;
        } else {
          //  this.alertService.showDangerAlert('Error', 'tag not exist.');
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  addTrainer() {
    localStorage.removeItem('trainer_id');
    this.router.navigate(['addEditTrainer']);
  }
  editTrainer(id: any) {
    localStorage.removeItem('trainer_id');
    localStorage.setItem('trainer_id', id);
    this.router.navigate(['addEditTrainer']);
  }

  showVideo(path: any) {
    this.videoPath = '';
    setTimeout(() => {
      this.videoPath = path;
    });
  }

  getVideoURL() {
    return this.baseURL + this.videoPath;
  }
  searchByTag(name: any) {
    this.searchTag.tag_name = name;
    this.tagService.getTagListByTag(this.searchTag).subscribe(
      (data) => {
        //  console.log(data.is_error);
        if (data.is_error == false) {
          this.tagList = data.data;
        }
        //console.log( this.tagList)
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }

  onChange(index: number, _id: string, isChecked: any) {
    if (isChecked.target.checked) {
      this.tagarray.push(_id);
    } else {
      this.tagarray.splice(this.tagarray.indexOf(_id), 1);
    }
    this.searchByTagId();
  }
  searchByTagId() {
    if (this.tagarray.length > 0) {
      this.searchTagArray = this.searchTagTrainerList;
      let tagtest: any = [];
      let tempArray: any = [];
      this.trainerList = [];
      this.trainer = []
      for (var i = 0; i < this.searchTagArray.length; i++) {
        tagtest = [];
        for (var j = 0; j < this.searchTagArray[i].tags.length; j++) {
          tagtest.push(this.searchTagArray[i].tags[j].id);
        }
        tempArray.push({ tags: tagtest, image: this.searchTagArray[i] });
      }
      this.tagArrayList = tempArray;
      // for (var j = 0; j < this.tagarray.length; j++) {
      //   let result = this.tagArrayList.filter((x) =>
      //     x.tags.find((a) => a === this.tagarray[j])
      //   );
      //   this.trainer = result;
      // }
      // console.log(this.tagArrayList);
      // console.log(this.tagarray);
      // this.trainer =  this.tagArrayList.filter((val:any) => {
      // return  val['tags'].filter((val1:any)=>{return this.tagarray.some((tags:any) =>{return val1 === tags})}) 
      // });
      let trainers: any[] = []
      this.tagArrayList.forEach((val:any)=>{
        this.tagarray.forEach((val1:any)=>{
          if(val['tags'].includes(val1)){
            trainers.push(val)
          }
        })
      })
      this.trainer = trainers.filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i);
      // console.log(this.trainer)
      for (var i = 0; i < this.trainer.length; i++) {
        this.trainerList[i] = this.trainer[i]['image'];
      }
      // console.log(this.trainerList);
    } else {
      this.getTrainerList();
      return;
    }
  }
  onChangeHairLength(index: number, _id: number, isChecked: any) {
    // this.hairLength_checked=true;
    if (isChecked.target.checked) {
      this.hairLengthArray.push(_id);
    } else {
      //  if (this.hairLengthArray.indexOf(index) == 0) {
      //    this.hairLengthArray.splice(this.hairLengthArray.indexOf(index), 1);
      //  }
      //  else {
      this.hairLengthArray.splice(this.hairLengthArray.indexOf(_id), 1);
      //}
    }
    this.postData.hair_length = this.hairLengthArray;
    //  console.log(this.postData.hair_length);
    this.searchByHair();
  }

  onChangeHairType(index: number, _id: number, isChecked: any) {
    //this.hairType_checked=true;
    if (isChecked.target.checked) {
      this.hairTypehArray.push(_id);
    } else {
      // if (this.hairTypehArray.indexOf(index) == 0) {
      //   this.hairTypehArray.splice(this.hairTypehArray.indexOf(index), 1);
      // }
      // else {
      this.hairTypehArray.splice(this.hairTypehArray.indexOf(_id), 1);
      //}
    }
    // console.log(this.hairTypehArray);
    this.postData.hair_type = this.hairTypehArray;
    this.searchByHair();
  }

  onChangeAge(index: number, _id: number, isChecked: any) {
    //this.hairLength_checked=true;
    if (isChecked.target.checked) {
      this.ageArray.push(_id);
    } else {
      // if (this.ageArray.indexOf(index) == 0) {
      //   this.ageArray.splice(this.ageArray.indexOf(index), 1);
      // }
      // else {
      this.ageArray.splice(this.ageArray.indexOf(_id), 1);
      //}
    }
    this.postData.age = this.ageArray;
    this.searchByHair();
  }
  onChangeHairColor(index: number, _id: number, isChecked: any) {
    //this.hairColor_checked=true;
    if (isChecked.target.checked) {
      this.hairColorArray.push(_id);
    } else {
      // if (this.hairColorArray.indexOf(index) == 0) {
      //   this.hairColorArray.splice(this.hairColorArray.indexOf(index), 1);
      // }
      // else {
      this.hairColorArray.splice(this.hairColorArray.indexOf(_id), 1);
      // }
    }
    this.postData.hair_color = this.hairColorArray;
    this.searchByHair();
  }

  searchByHair() {
    //this.hair_length_arry=[];
    //console.log(this.postData);
    this.hair_length_arry = this.searchTagTrainerList;
    //  this.hair_type_array=  this.searchTagTrainerList;
    //  this.hair_color_array=  this.searchTagTrainerList;
    //  this.age_array= this.searchTagTrainerList;
    let concatedArray = [];
    let hair_type_arr:any[] = [];
    let hair_length_arr:any[] = [];
    let hair_color_arr:any[] = [];
    let age_arr:any[] = [];
    let hair_type_array = [{ hair_type: [] }];
    let hair_color_array = [{ hair_color: [] }];
    let age_array = [{ age: [] }];
    if (
      this.postData['hair_length'].length > 0 ||
      this.postData['age'].length > 0 ||
      this.postData['hair_color'].length > 0 ||
      this.postData['hair_type'].length > 0
    ) {
      this.trainerList = [];
      if (this.postData['hair_length'].length > 0) {
        // for (var j = 0; j < this.postData['hair_length'].length; j++) {
        //   let result = this.hair_length_arry.filter((x:any) =>
        //     x.hair_length.includes(this.postData['hair_length'][j])
        //   );
        //   this.trainerList = result;
        // }
        this.hair_length_arry.forEach((val:any)=>{
          this.postData['hair_length'].forEach((val1:any) =>{
            if(val['hair_length'].includes(val1)){
              hair_type_arr.push(val);
            }
          })
        })
        //hair_type_arr =  this.hair_length_arry.filter((val:any) =>this.postData['hair_length'].some((hair_length:any)=>val['hair_length']==hair_length));
       // console.log(this.trainerList)
       // this.trainerList = this.hair_length_arry.some(r=> this.postData['hair_length'].includes(r.hair_length))
       // hair_type_array = this.trainerList;
      } else {
       // hair_type_arr = this.searchTagTrainerList;
      }
      if (this.postData['hair_type'].length > 0) {
        // for (var j = 0; j < this.postData['hair_type'].length; j++) {
        //   let hair_type_result = hair_type_array.filter((x) =>
        //     x.hair_type.find((a) => a == this.postData['hair_type'][j])
        //   );
        //   this.trainerList = hair_type_result;
        // }
        this.hair_length_arry.forEach((val:any)=>{
          this.postData['hair_type'].forEach((val1:any) =>{
            if(val['hair_type'].includes(val1)){
              hair_length_arr.push(val);
            }
          })
        })
       // hair_length_arr =  this.hair_length_arry.filter((val:any) =>this.postData['hair_type'].some((hair_type:any)=>val['hair_type']==hair_type));
        //hair_color_array = this.trainerList;
      } else {
       // hair_length_arr = this.searchTagTrainerList;
      }

      if (this.postData['hair_color'].length > 0) {
        // for (var j = 0; j < this.postData['hair_color'].length; j++) {
        //   let hair_color_result = hair_color_array.filter((x) =>
        //     x.hair_color.find((a) => a == this.postData['hair_color'][j])
        //   );
        //   this.trainerList = hair_color_result;
        // }
        this.hair_length_arry.forEach((val:any)=>{
          this.postData['hair_color'].forEach((val1:any) =>{
            if(val['hair_color'].includes(val1)){
              hair_color_arr.push(val);
            }
          })
        })
       // hair_color_arr =  this.hair_length_arry.filter((val:any) =>this.postData['hair_color'].some((hair_color:any)=>val['hair_color']==hair_color));
        //age_array = this.trainerList;
      } else {
        //hair_color_arr = this.searchTagTrainerList;
      }
      if (this.postData['age'].length > 0) {
        // for (var j = 0; j < this.postData['age'].length; j++) {
        //   let age_result = age_array.filter((x) =>
        //     x.age.find((a) => a == this.postData['age'][j])
        //   );
        //   this.trainerList = age_result;
        // }
        this.hair_length_arry.forEach((val:any)=>{
          this.postData['age'].forEach((val1:any) =>{
            if(val['age'].includes(val1)){
              age_arr.push(val);
            }
          })
        })
       // age_arr =  this.hair_length_arry.filter((val:any) =>this.postData['age'].some((age_array:any)=>val['age']==age_array));
      };
      concatedArray = [...hair_type_arr,...hair_length_arr,...hair_color_arr,...age_arr];
      this.trainerList = concatedArray.filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i)
    }
    
    // console.log(this.trainerList);
    if (
      this.postData['hair_length'].length == 0 &&
      this.postData['age'].length == 0 &&
      this.postData['hair_color'].length == 0 &&
      this.postData['hair_type'].length == 0
    ) {
      this.getTrainerList();
      return;
    }
  }

  resetFilter(){
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.postData.hair_length = [];
    this.postData.hair_type = [];
    this.postData.hair_color = [];
    this.postData.age = [];
    this.hair_length = [];
    this.hair_type = [];
    this.hair_color = [];
    this.age = [];
    this.hairLengthArray = [];
    this.hairTypehArray = [];
    this.hairColorArray = [];
    this.ageArray = [];
    this.tagarray = [];
    setTimeout(()=>{
      this.hair_length = AppConstants.hair_length;
      this.hair_type = AppConstants.hair_type;
      this.hair_color = AppConstants.hair_color;
      this.age = AppConstants.age;
    },100)
   
    this.getTrainerList();
    this.getTagList();
  }

  onPlayingVideo(event: any) {
    this.currentPlayingVideo = event.target;
  }

  pauseVideo() {
    this.currentPlayingVideo.pause();
  }
}
