<app-sidebar></app-sidebar>
<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-12 col-sm-6">
        <h1 class="page-title">
          {{ "trainer.executionList" | translate }}
        </h1>
      </div>
      <div class="col-12 col-sm-6 text-sm-right">
        <button class="btn btn-default" (click)="addTrainer()">
          {{ "trainer.addExecution" | translate }}
        </button>
      </div>
    </div>
    <div class="tab-box tab-box-vertical pt-3" role="tabpanel">
      <div class="row">
        <div class="col-sm-9">
          <div class="row">
            <div
              class="col-xl-3 col-md-4 col-sm-4 col-6"
              *ngFor="let trainer of trainerList"
            >
              <div class="tags-list-img mb-0">
                <img
                  src="{{ baseURL }}{{ trainer.file_path_name }}"
                  (click)="editTrainer(trainer.id)"
                />
                <div class="images-btn">
                  <button
                    data-toggle="modal"
                    data-target="#videoModal"
                    (click)="showVideo(trainer.video_path_name)"
                  >
                    <i class="fal fa-eye"></i>
                  </button>
                  <button
                    data-toggle="modal"
                    data-target="#deleteModalCenter"
                    (click)="show(trainer.id)"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </button>
                </div>
              </div>
              <p class="videoTitle multiline" [innerHTML]="trainer.title"></p>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <ul
            class="nav nav-tabs nav-justified nav-tabs-dropdown main-tab"
            role="tablist"
          >
            <li role="presentation">
              <a
                href="#tags"
                aria-controls="tags"
                role="tab"
                data-toggle="tab"
                class="active"
                >{{ "tags" | translate }}</a
              >
            </li>
            <li role="presentation">
              <a
                href="#filter"
                aria-controls="filter"
                role="tab"
                data-toggle="tab"
                >{{ "filter" | translate }}</a
              >
            </li>
          </ul>
          <button type="button" class="resetFilter btn btn-default" (click)="resetFilter()">{{'reset' | translate}}</button>
          <div class="tab-content pt-3">
            <div role="tabpanel" class="tab-pane tab-in active" id="tags">
              <div class="search-input">
                <label for="Search">{{ "search" | translate }}</label>
                <input
                  type="text"
                  placeholder="{{ 'search' | translate }}"
                  name="search"
                  #searchInput
                  (change)="searchByTag(searchInput.value)"
                />
              </div>
              <div class="product-scroll mt-3">
                <div
                  class="checkbox-box mt-0 mb-3"
                  *ngFor="let tag of tagList; let i = index"
                >
                  <label class="container"
                    >{{ tag.tag_name }}
                    <input
                      type="checkbox"
                      id="{{ tag.id }}"
                      value="{{ tag.id }}"
                      (change)="onChange(i, tag.id, $event)"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane" id="filter">
              <div class="row">
                <div class="col-sm-6">
                  <div class="checkbox-box">
                    <div class="checkbox-box-title">
                      {{ "hairLength" | translate }}
                    </div>
                    <label
                      class="container"
                      *ngFor="let length of hair_length; let i = index"
                      >{{ 'hair_length.'+length.length | translate}}
                      <input
                        type="checkbox"
                        id="{{ length.id }}"
                        value="{{ length.id }}"
                        (change)="onChangeHairLength(i, length.id, $event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="checkbox-box">
                    <div class="checkbox-box-title">
                      {{ "hairType" | translate }}
                    </div>
                    <label
                      class="container"
                      *ngFor="let type of hair_type; let j = index"
                      >{{ 'hair_type.'+type.type | translate }}
                      <input
                        type="checkbox"
                        id="{{ type.id }}"
                        value="{{ type.id }}"
                        (change)="onChangeHairType(j, type.id, $event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="checkbox-box">
                    <div class="checkbox-box-title">
                      {{ "haircolor" | translate }}
                    </div>
                    <label
                      class="container"
                      *ngFor="let color of hair_color; let c = index"
                      >{{ 'hair_color.'+color.color | translate}}
                      <input
                        type="checkbox"
                        id="{{ color.id }}"
                        value="{{ color.id }}"
                        (change)="onChangeHairColor(c, color.id, $event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="checkbox-box">
                    <div class="checkbox-box-title">
                      {{ "age" | translate }}
                    </div>
                    <label
                      class="container"
                      *ngFor="let age of age; let a = index"
                      >{{ 'ageList.'+age.age | translate }}
                      <input
                        type="checkbox"
                        id="{{ age.id }}"
                        value="{{ age.id }}"
                        (change)="onChangeAge(a, age.id, $event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- videp Modal -->
<!-- <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"> -->
<div
  class="modal fade"
  data-backdrop="false"
  id="videoModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content video-content">
      <div class="modal-body p-0">
        <button
          (click)="pauseVideo()"
          type="button"
          class="close modal-close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <video
          class="w-100"
          (playing)="onPlayingVideo($event)"
          controls
          autoplay
          *ngIf="videoPath"
        >
          <source [src]="getVideoURL()" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</div>

<div id="deleteModalCenter" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h4 class="modal-title text-center w-100">
          {{ "areYouSure" | translate }}
        </h4>
      </div>
      <div class="modal-body text-center">
        <p class="mb-0">{{ "deleteRecord" | translate }}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "cancel" | translate }}
        </button>
        <button
          type="button"
          (click)="deletetrainer(deleteId)"
          data-dismiss="modal"
          class="btn btn-danger"
        >
          {{ "delete" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
