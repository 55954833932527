<app-sidebar></app-sidebar>
<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-6">
        <h1 class="page-title">
          {{ "productTag.productTagList" | translate }}
        </h1>
      </div>
      <div class="col-6 text-right">
        <button class="btn btn-default" (click)="back()">
          {{ "backBtn" | translate }}
        </button>
      </div> 
    </div>
    <div class="row pt-3">
      <div class="col-12">
        <div class="table-responsive">
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="row-border hover table"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>{{ "tag.tagname" | translate }}</th>               
                <th class="text-right" width="130px">
                  {{ "action" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let tag of tagList;let i=index;">
                <td>{{i+1}}</td>
                <td>{{ tag.product_tag_name | titlecase }}</td>
                
                <td class="text-right">
                  <button class="btn edit-icon"  data-toggle="modal"
                  data-target="#editModalCenter"
                  (click)="editTag(tag.id,tag.product_tag_name )">
                    <i class="fas fa-pen"></i>
                  </button>
                  <button
                    class="btn btn-danger delete-icon mx-2"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    (click)="show(tag.id)"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
 <div id="exampleModalCenter" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h4 class="modal-title text-center w-100">
          {{ "areYouSure" | translate }}
        </h4>
      </div>
      <div class="modal-body text-center">
        <p class="mb-0">{{ "deleteRecord" | translate }}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "cancel" | translate }}
        </button>
        <button
          type="button"
          (click)="deleteTag(deleteId)"
          data-dismiss="modal"
          class="btn btn-danger"
        >
          {{ "delete" | translate }}
        </button>
      </div>
    </div>
  </div>
</div> 

<ng-container *ngIf="editTagFlag">
    <div id="editModalCenter" class="modal fade">
      <div class="modal-dialog modal-dialog-centered modal-confirm">
        <div class="modal-content">
          <div class="modal-header flex-column">
            <!-- <div class="icon-box">
              <i class="fas fa-times"></i>
            </div>						 -->
            <h5 class="modal-title w-100 font-weight-bold text-center">
              {{ "tag.editTag" | translate }}
            </h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
          </div> -->
          </div>
          <form
            autocomplete="off"
            novalidate
            [formGroup]="tagForm"
            (ngSubmit)="submitTagForm()"
          >
            <div class="modal-body">
                <div class="row">
                    <div
                      class="col-12 col-sm-12 col-xl-3 d-flex align-items-center"
                    >
                      <label>
                      {{"tag.tagname" | translate}}
                      </label>
                    </div>
                <div class="col-12 col-sm-12 col-xl-9">
                    <div class="form-group input-group">
                      <input
                        type="text"
                        formControlName="product_tag_name"
                        class="form-control small-control"                        
                        maxlength="50"
                        placeholder="{{'tag.tagname' | translate}}"
                      />
                      <div
                      *ngIf="
                        submitted && formValidation.product_tag_name.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="formValidation.product_tag_name.errors.required"                      >
                        {{ "tag.tagrequired" | translate }}
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
              <!-- <div class="ng-autocomplete">
                <ng-autocomplete
                  #ngAutoCompleteStatic
                  [data]="tagDataList"
                  formControlName="tag_name"
                  [initialValue]=""
                  [placeholder]="placeholder"
                  [searchKeyword]="keyword"               
                  [historyHeading]="historyHeading"
                  [notFoundText]="not_found"
                  [itemTemplate]="itemTemplateStatic"
                  [notFoundTemplate]="notFoundTemplate"
                >
                </ng-autocomplete>
  
                <ng-template #itemTemplateStatic let-item>
                  <a [innerHTML]="item"></a>
                </ng-template>
  
                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div> -->
            </div>
            <div class="modal-footer justify-content-center">
              <div>
                <button
                  class="btn btn-default submitbtn mr-2"
                  data-dismiss="modal"
                  type="button"
                  (click)="submitTagForm()"
                  [disabled]="tagForm.invalid"
                >
                  {{ "saveBtn" | translate }}
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-default btn-secondary"
                >
                  {{ "cancel" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-container>
