<app-sidebar></app-sidebar>
<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-6">
        <h1 class="page-title">
          {{ "salon_list" | translate }}
        </h1>
      </div>
      <div class="col-6 text-right">
        <button class="btn btn-default" (click)="addNewUser()">
          {{ "addNewUser" | translate }}
        </button>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-12">
        <div class="table-responsive">
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="row-border hover table"
          >
            <thead>
              <tr>
                <th>{{ "salonName" | translate }}</th>
                <th>{{ "salonAdminName" | translate }}</th>
                <th>{{ "salonAdminEmail" | translate }}</th>
                <th>{{ "phoneNumber" | translate }}</th>
                <th class="text-right" width="130px">
                  {{ "action" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of userList">
                <td>{{ user.salonName }}</td>
                <td>{{ user.salon_admin_name }}</td>
                <td>{{ user.salon_admin_email }}</td>
                <td>{{ user.salon_admin_phone }}</td>
                <td class="text-right">
                  <button class="btn edit-icon" (click)="editSalon(user.id)">
                    <i class="fas fa-pen"></i>
                  </button>
                  <button
                    class="btn btn-danger delete-icon mx-2"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    (click)="show(user.id)"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="exampleModalCenter" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h4 class="modal-title text-center w-100">
          {{ "areYouSure" | translate }}
        </h4>
      </div>
      <div class="modal-body text-center">
        <p class="mb-0">{{ "deleteRecord" | translate }}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "cancel" | translate }}
        </button>
        <button
          type="button"
          (click)="deleteSalon(deleteId)"
          data-dismiss="modal"
          class="btn btn-danger"
        >
          {{ "delete" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
