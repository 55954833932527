import { Component, OnInit } from '@angular/core';
import { ProductService } from './product.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import AppConstants from 'src/app/shared/commons/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { SalonService } from 'src/app/components/dashboard/salon.service';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent implements OnInit {
  deleteId: any;
  salonDetail:any={};
  salon_list:any;
  errorDialog: any;
  successDialog: any;
  somethingWrong: any;
  image_path_name: any;
  dtTrigger: Subject<any> = new Subject<any>();
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  baseURL = AppConstants.SERVICE_BASE_URL;
  productList: any = [];
  role:any='';
  constructor(
    private salonService:SalonService,
    private translateService: TranslateService,
    public router: Router,
    public productService: ProductService,
    public alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };
    this.getAllProduct();
    this.getSalonList();
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
  }
  getSalonList()
  {
    this.salonService.getAllSaloons().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.salon_list = data.data;        
        } 
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  salonSelect(id:any)
  {
   
    this.salonDetail.salon_id=id;   
    this.productService.getproductListBysalonId(this.salonDetail).subscribe(
      (data) => {   
    // console.log(data);
        if (data.is_error == false) {         
           this.dtTrigger.unsubscribe();
          this.productList = data.data;
            this.dtTrigger.next();
         // console.log( this.productList);
        } else {
          // this.alertService.showDangerAlert('Error', 'Product not exist.');
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  addNewProduct() {
    localStorage.removeItem('product_id');
    this.router.navigate(['addEditProduct']);
  }
  getAllProduct() {
    this.productService.getProductList().subscribe(
      (data) => {
        if (data.is_error == false) {
         
          this.productList = data.data;
          this.dtTrigger.next();
         // console.log( this.productList);
        } else {
          // this.alertService.showDangerAlert('Error', 'Product not exist.');
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  editProduct(id: any) {
    localStorage.removeItem('product_id');
    localStorage.setItem('product_id', id);
    this.router.navigate(['addEditProduct']);
  }
  deleteProduct(id: any) {
    this.productService.delete(id).subscribe(
      (data) => {
        this.dtTrigger.unsubscribe();
        if (data['is_error'] == false) {
          let productDelete = this.translateService.instant(
            'product.productDeleteMsg'
          );
          this.alertService.showSuccessAlert(this.successDialog, productDelete);
          setTimeout(() => {
            this.getAllProduct();
          }, 1000);
        } else {
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  showModal(id: any) {
    this.deleteId = id;
  }
  showProductModal(path: any) {
    this.image_path_name = path;
  }
}
