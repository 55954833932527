import { Component } from '@angular/core';
import { MessagingService } from './services/messaging.service';
import {AlertService} from './shared/commons/alert.service'
import * as $ from 'jquery'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
 
  title = 'Weeme';
  isloggedin= false;
  message:any;
  constructor(private messagingService: MessagingService,private alertService:AlertService) { }
  ngOnInit() {
    // this.messagingService.requestPermission()
    // this.messagingService.receiveMessage()
    // this.message = this.messagingService.currentMessage;
    // this.alertService.showInfoAlert('test','test')
   }
   
}
