import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import AppConstants from 'src/app/shared/commons/app.constants';
@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(public httpClient: HttpClient) { }
  getOrdersList() : Observable<any>{ 
    return this.httpClient.get(
      AppConstants.SERVICE_BASE_URL + '/api/common/Orders'
    );
  }
  getById(id: any) {
    return this.httpClient.get<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Orders/' + id
    );
  }
  update(id: any, product: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Orders/' + id,
      product
    );
  }
  delete(id: number) {
    return this.httpClient.delete<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/soft/Orders/' + id
    );
  }
  deleteProduct(product: number) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/product/removeProductFromCart/' , product
    );
  }
  getProductList(): Observable<any> {
    return this.httpClient.get(
      AppConstants.SERVICE_BASE_URL + '/api/common/Product'
    );
  }
}
