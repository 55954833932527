import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { SalonModel } from 'src/app/model/salon-model';
// import { AdminUserModel } from 'src/app/model/adminUser-model';
import AppConstants from 'src/app/shared/commons/app.constants';

@Injectable({
  providedIn: 'root'
})
export class SalonService {
  // dataChange: BehaviorSubject<SalonModel[]> = new BehaviorSubject<SalonModel[]>([]);

  constructor(private httpClient: HttpClient) { }

  getAllSaloons(): Observable<any> {
    return this.httpClient.get(AppConstants.SERVICE_BASE_URL + '/api/common/Salon',
      { headers: AppConstants.getHeaders() });
  }

  getById(id:any) {   
    return this.httpClient.get<any>(AppConstants.SERVICE_BASE_URL + '/api/common/Salon/'+id,
     { headers: AppConstants.getHeaders() });
  }

  add(salon:any) {    
    return this.httpClient.post<any>(AppConstants.SERVICE_BASE_URL + '/api/salon/addsalon',salon);
  }
 

  update(id:any,salon:any) {    
    return this.httpClient.post<any>(AppConstants.SERVICE_BASE_URL + '/api/salon/updatesalon/'+
      id,salon);
 
  }
  delete(id: number) {
    return this.httpClient.delete<any>(AppConstants.SERVICE_BASE_URL + '/api/common/soft/Salon/' + id,
      { headers: AppConstants.getHeaders() });
  }
  changePassword(credential:any){
    return this.httpClient.post<any>(AppConstants.SERVICE_BASE_URL + '/api/salon/resetPassword',credential,
    { headers: AppConstants.getHeaders() });
  }

  //list of saloon
  // getAll(): void {
  //   this.httpClient.get<any>(AppConstants.SERVICE_BASE_URL + '/salonlist',
  //     { headers: AppConstants.getHeaders() }).subscribe(data => {

  //       const saloonList = data.response as SalonModel[];
  //       if (data.resp_type === true) {
  //         this.dataChange.next(saloonList);
  //       }
  //       return data;

  //     }, (error: HttpErrorResponse) => {
  //       console.log(error.name + ' ' + error.message);

  //     });
  // }

}
