<app-sidebar></app-sidebar>
<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-12">
        <h1 class="page-title">
          {{ "face.faceshapeinfo" | translate }}
        </h1>
      </div>
    </div>
    <form [formGroup]="faceshapeform" class="form" (ngSubmit)="onSubmit()">
      <div class="tab-box pt-3" role="tabpanel">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-4 border-left-box">
            <ul
              class="nav nav-tabs nav-justified nav-tabs-dropdown main-tab"
              role="tablist"
            >
              <li role="presentation">
                <a
                  href="#rectangemale"
                  (click)="ClickFaceShape(1, 'Rectangle Male')"
                  aria-controls="reactangemale"
                  role="tab"
                  data-toggle="tab"
                  class="active"
                  >{{ "face.reactanglemale" | translate }}</a
                >
              </li>
              <li role="presentation">
                <a
                  href="#rectanglefemale"
                  (click)="ClickFaceShape(2, 'Rectangle Female')"
                  aria-controls="rectanglefemale"
                  role="tab"
                  data-toggle="tab"
                  >{{ "face.rectanglefemale" | translate }}</a
                >
              </li>

              <li role="presentation">
                <a
                  href="#ovalmale"
                  (click)="ClickFaceShape(3, 'Oval Male')"
                  aria-controls="ovalmale"
                  role="tab"
                  data-toggle="tab"
                >
                  {{ "face.ovalmale" | translate }}</a
                >
              </li>
              <li role="presentation">
                <a
                  href="#ovalfemale"
                  (click)="ClickFaceShape(4, 'Oval Female')"
                  aria-controls="ovalfemale"
                  role="tab"
                  data-toggle="tab"
                >
                  {{ "face.ovalfemale" | translate }}</a
                >
              </li>
              <li role="presentation">
                <a
                  href="#squaremale"
                  (click)="ClickFaceShape(5, 'Square Male')"
                  aria-controls="squaremale"
                  role="tab"
                  data-toggle="tab"
                  >{{ "face.squaremale" | translate }}</a
                >
              </li>
              <li role="presentation">
                <a
                  href="#squarefemale"
                  (click)="ClickFaceShape(6, 'Square Female')"
                  aria-controls="squarefemale"
                  role="tab"
                  data-toggle="tab"
                  >{{ "face.squarefemale" | translate }}</a
                >
              </li>

              <li role="presentation">
                <a
                  href="#roundmale"
                  (click)="ClickFaceShape(7, 'Round Male')"
                  aria-controls="roundmale"
                  role="tab"
                  data-toggle="tab"
                  >{{ "face.roundmale" | translate }}</a
                >
              </li>
              <li role="presentation">
                <a
                  href="#roundfemale"
                  (click)="ClickFaceShape(8, 'Round Female')"
                  aria-controls="roundfemale"
                  role="tab"
                  data-toggle="tab"
                >
                  {{ "face.roundfemale" | translate }}</a
                >
              </li>

              <li role="presentation">
                <a
                  href="#diamondmale"
                  (click)="ClickFaceShape(9, 'Diamond Male')"
                  aria-controls="diamondmale"
                  role="tab"
                  data-toggle="tab"
                >
                  {{ "face.diamondmale" | translate }}</a
                >
              </li>
              <li role="presentation">
                <a
                  href="#diamondfemale"
                  (click)="ClickFaceShape(10, 'Diamond Female')"
                  aria-controls="diamondfemale"
                  role="tab"
                  data-toggle="tab"
                >
                  {{ "face.diamondfemale" | translate }}</a
                >
              </li>
              <li role="presentation">
                <a
                  href="#trianglemale"
                  (click)="ClickFaceShape(11, 'Triangle Male')"
                  aria-controls="trianglemale"
                  role="tab"
                  data-toggle="tab"
                >
                  {{ "face.trianglemale" | translate }}</a
                >
              </li>
              <li role="presentation">
                <a
                  href="#trianglefemale"
                  (click)="ClickFaceShape(12, 'Triangle Female')"
                  aria-controls="trianglefemale"
                  role="tab"
                  data-toggle="tab"
                  >{{ "face.trianglefemale" | translate }}</a
                >
              </li>
            </ul>
            <!-- <div *ngIf="submitted && formValidation.face_shape_type.errors" class="invalid-feedback">
              <div *ngIf="formValidation.face_shape_type.errors.required">face shape type is required</div>
            </div> -->
          </div>
          <div class="col-lg-9 col-md-9 col-sm-8">
            <div class="tab-content">
              <div role="tabpanel1" class="tab-pane tab-in active">
                <ul
                  class="nav nav-tabs nav-justified nav-tabs-dropdown"
                  role="tablist1"
                >
                  <li role="presentation1">
                    <a
                      href="#to_enanche"
                      (click)="currentEnanche()"
                      aria-controls="to_enanche"
                      role="tab"
                      data-toggle="tab"
                      [ngClass]="{ active: index == 1 }"
                      >{{ "face.toenanche" | translate }}</a
                    >
                  </li>
                  <li role="presentation1">
                    <a
                      href="#to_avoid"
                      (click)="currentAvoid()"
                      aria-controls="to_avoid"
                      role="tab"
                      data-toggle="tab"
                      [ngClass]="{ active: index == 2 }"
                      >{{ "face.toavoid" | translate }}</a
                    >
                  </li>
                  <li role="presentation1">
                    <a
                      href="#accessories"
                      (click)="currentAccessories()"
                      aria-controls="accessories"
                      role="tab"
                      data-toggle="tab"
                      [ngClass]="{ active: index == 3 }"
                      >{{ "face.accessories" | translate }}</a
                    >
                  </li>
                </ul>
                <div class="tab-content">
                  
                  
                  <div
                    role="tabpanel1"
                    class="tab-pane"
                    [ngClass]="{ active: index == 1 }"
                    id="to_enanche"
                  >
                  <div id="customFields">
                    <div class="addCF" (click)="addEnhance()">+</div>
                  </div>
                <ng-container formArrayName="to_enanche" >
                    <div class="form-group input-group enhance_rm" [formGroup]="$any(to_enanche)"  *ngFor="let enhance of to_enanche.controls; let i=index" >
                      <textarea
                        [formControlName]="i"
                        placeholder="{{ 'face.toenanche' | translate }}"
                        rows="5"
                        cols="100"
                        id="to_enanche_{{i}}"
                        [ngClass]="{
                          'is-invalid':
                            submitted && formValidation.to_enanche.errors
                        }"
                      ></textarea>
                      <div id="customFields" class="custom_rm">
                        <div class="remCF" *ngIf="i!=0" (click)="removeEnhance(i)">-</div>
                      </div>  
                      <!-- <div *ngIf="submitted && formValidation.to_enanche.errors" class="invalid-feedback">
                        <div *ngIf="formValidation.to_enanche.errors.required">{{'face.enanchereq'|translate}}</div>
                      </div> -->
                    </div>
                  </ng-container>
                    <!-- <span *ngIf="to_enanche_checked" class="custom-error">{{
                      errorToEnanche
                    }}</span> -->
                    <ul
                      class="nav nav-tabs justify-content-end nav-tabs-dropdown main-tab"
                      role="tablist"
                    >
                      <span *ngIf="!to_enanche_checked">
                        <li role="presentation">
                          <button
                            (click)="nextavoid()"
                            class="btn-default"
                            role="tab"
                            data-toggle="tab"
                          >
                            {{ "nextBtn" | translate }}
                          </button>
                        </li>
                      </span>
                      <span *ngIf="to_enanche_checked">
                        <li role="presentation">
                          <button
                            href="#to_avoid"
                            (click)="nextavoid()"
                            class="btn-default"
                            role="tab"
                            data-toggle="tab"
                          >
                            {{ "nextBtn" | translate }}
                          </button>
                        </li>
                      </span>
                    </ul>
                    <!-- <div>
                      <button type="submit" class="btn btn-default">Save</button>
                    </div> -->
                  </div>
                  <div
                    role="tabpanel1"
                    class="tab-pane"
                    [ngClass]="{ active: index == 2 }"
                    id="to_avoid"
                  >
                  <div id="customFields">
                    <div class="addCF" (click)="addAvoid()">+</div>
                  </div>
                  <ng-container formArrayName="to_avoid" >
                    <div class="form-group input-group" [formGroup]="$any(to_avoid)"  *ngFor="let avoid of to_avoid.controls; let i=index" >
                      <textarea
                        [formControlName]="i"
                        placeholder="{{ 'face.toavoid' | translate }}"
                        rows="5"
                        cols="100"
                        id="to_avoid_{{i}}"
                        [ngClass]="{
                          'is-invalid':
                            submitted && formValidation.to_avoid.errors
                        }"
                      ></textarea>
                      <div id="customFields" class="custom_rm">
                        <div class="remCF" *ngIf="i!=0" (click)="removeAvoid(i)">-</div>
                      </div>  
                      <!-- <div *ngIf="submitted && formValidation.to_enanche.errors" class="invalid-feedback">
                        <div *ngIf="formValidation.to_enanche.errors.required">{{'face.enanchereq'|translate}}</div>
                      </div> -->
                    </div>
                  </ng-container>
                    <!-- <div *ngIf="submitted && formValidation.to_avoid.errors" class="invalid-feedback">
                        <div *ngIf="formValidation.to_avoid.errors.required">{{'face.toavoidreq'|translate}}</div>
                      </div> -->
                    <span *ngIf="to_avoid_checked" class="custom-error">{{
                      errorToAvoid
                    }}</span>
                    <ul
                      class="nav nav-tabs justify-content-end nav-tabs-dropdown main-tab"
                      role="tablist"
                    >
                      <li role="presentation">
                        <button
                          class="btn btn-default btn-secondary mr-2"
                          (click)="prevenanche()"
                          role="tab"
                          data-toggle="tab"
                          href="#to_enanche"
                        >
                          {{ "backBtn" | translate }}
                        </button>
                      </li>

                      <li role="presentation" *ngIf="!to_avoid_checked">
                        <button
                          (click)="nextaccessories()"
                          class="btn-default"
                          role="tab"
                          data-toggle="tab"
                        >
                          {{ "nextBtn" | translate }}
                        </button>
                      </li>

                      <li role="presentation" *ngIf="to_avoid_checked">
                        <button
                          href="#accessories"
                          (click)="nextaccessories()"
                          class="btn-default"
                          role="tab"
                          data-toggle="tab"
                        >
                          {{ "nextBtn" | translate }}
                        </button>
                      </li>

                      <!-- <li role="presentation">
                        <button  href="#accessories"  (click)="nextaccessories()" class="btn-default" role="tab" data-toggle="tab" >Next</button>
                      </li> -->
                    </ul>

                    <!-- <div>
                      <button type="submit" class="btn btn-default">Save</button>
                    </div> -->
                  </div>
                  <div
                    role="tabpanel1"
                    class="tab-pane"
                    [ngClass]="{ active: index == 3 }"
                    id="accessories"
                  >
                    <div role="tabpanel1" class="tab-pane tab-in active">
                      <ul
                        class="nav nav-tabs nav-justified nav-tabs-dropdown"
                        role="tablist1"
                      >
                        <li role="presentation1">
                          <a
                            href="#glass"
                            aria-controls="glass"
                            role="tab"
                            data-toggle="tab"
                            class="active"
                            >{{ "face.glass" | translate }}</a
                          >
                        </li>
                        <li role="presentation1">
                          <a
                            href="#other"
                            aria-controls="other"
                            role="tab"
                            data-toggle="tab"
                            >{{ "face.other" | translate }}</a
                          >
                        </li>
                      </ul>
                      <div class="tab-content pl-3">
                        <div
                          role="tabpanel1"
                          class="tab-pane active"
                          id="glass"
                        >
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label for="file">{{
                                  "face.file" | translate
                                }}</label>
                                <input
                                  formControlName="glass"
                                  id="file"
                                  type="file"
                                  class="form-control"
                                  multiple=""
                                  (change)="onFileChange($event)"
                                />
                              </div>
                            </div>
                          </div>
                          <!-- <div class="col-12 col-sm-4 col-xl-4 p-0 d-flex align-items-center" >
                <input style="display:none;" formControlName="image_path_name"
                id="file"
                type="file"
                (change)="onFileChange($event)" >
                <label for="file" class="glass-list mb-0" *ngIf="imageUrl">
                    <img src="{{imageUrl}}" class="glass-img">
                </label>

            </div> -->

                          <div class="row" *ngIf="glass">
                            <div
                              class="col-6 col-sm-3"
                              *ngFor="let url of glass; let i = index"
                            >
                              <div class="glass-list">
                                <img
                                  src="{{baseURL}}/{{folderName}}{{
                                    url.glass_image
                                  }}"
                                  class="glass-img"
                                />
                                <div class="glass-list-icon" *ngIf="url">
                                  <a
                                    (click)="
                                      removeSelectedFile(deleteData.id, url.glass_image)
                                    "
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                  >
                                    <i class="fal fa-trash-alt"></i>
                                  </a>
                                  
                                  <!-- <button  class="btn btn-danger delete-icon mx-2" data-toggle="modal" data-target="#exampleModalCenter" (click)="show(user.id)">
                                    <i class="fas fa-times"></i>
                                  </button> -->
                                </div>
                                <div class="glass-list-icon-edit">
                                  <a class="btn edit-icon" (click)="addupdateglass(deleteData.id, url.glass_image,i)" data-toggle="modal"
                                  data-target="#editGlassModal">
                                    <i class="fas fa-pen"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div role="tabpanel1" class="tab-pane" id="other">
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label for="file">{{
                                  "face.file" | translate
                                }}</label>
                                <input
                                  formControlName="other"
                                  id="otherfile"
                                  type="file"
                                  class="form-control"
                                  multiple="true"
                                  (change)="onOtherFileChange($event)"
                                />
                              </div>
                            </div>
                          </div>
                          <!-- <div class="row">
                            <div class="col-sm-3" *ngFor='let other of other; let i = index'>
                              <div class="glass-list">
                                <img [src]="http://212.71.253.181:5030/image/faceShape/other" class="glass-img">
                                <div class="glass-list-icon" >
                                  <a (click)="removeOtherSelectedFile(i)">
                                    <i class="fal fa-trash-alt"></i>
                                  </a>
                                </div>
                              </div>
                             </div>
                          </div>  -->

                          <div class="row" *ngIf="other">
                            <div
                              class="col-6 col-sm-3"
                              *ngFor="let other of other; let i = index"
                            >
                              <div class="glass-list">
                                <img
                                  src="{{baseURL}}/{{folderName}}{{
                                    other.other_image
                                  }}"
                                  class="glass-img"
                                />
                                <div class="glass-list-icon" *ngIf="other">
                                  <a
                                    (click)="
                                      removeOtherSelectedFile(
                                        deleteData.id,
                                        other.other_image
                                      )
                                    "
                                    data-toggle="modal"
                                    data-target="#deleteOtherModal"
                                  >
                                    <i class="fal fa-trash-alt"></i>
                                  </a>
                                </div>
                                <div class="glass-list-icon-edit">
                                  <a class="btn edit-icon" data-toggle="modal"
                                  data-target="#editOtherModal" (click)="addupdateother(deleteData.id,other.other_image,i)">
                                    <i class="fas fa-pen"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ul
                          class="nav nav-tabs justify-content-end nav-tabs-dropdown main-tab"
                          role="tablist"
                        >
                          <li role="presentation">
                            <button
                              class="btn btn-default btn-secondary mr-2"
                              (click)="prevAvoid()"
                              role="tab"
                              data-toggle="tab"
                              href="#to_avoid"
                            >
                              {{ "backBtn" | translate }}
                            </button>
                          </li>
                          <li>
                            <button type="submit" class="btn btn-default mr-2">
                              {{ "saveBtn" | translate }}
                            </button>
                          </li>
                          <!-- <li role="presentation">
                          <button class="btn btn-default btn-secondary" >Cancel</button>

                          </li> -->
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div id="exampleModalCenter" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h4 class="modal-title text-center w-100">
          {{ "areYouSure" | translate }}
        </h4>
      </div>
      <div class="modal-body text-center">
        <p class="mb-0">{{ "deleteRecord" | translate }}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "cancel" | translate }}
        </button>
        <button
          type="button"
          (click)="deleteGlassImage(deleteId, deletepath)"
          data-dismiss="modal"
          class="btn btn-danger"
        >
          {{ "delete" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-container >
  <div id="editOtherModal" class="modal fade">
    <div class="modal-dialog modal-dialog-centered modal-confirm">
      <div class="modal-content">
        <div class="modal-header flex-column">
          <!-- <div class="icon-box">
            <i class="fas fa-times"></i>
          </div>						 -->
          <h5 class="modal-title w-100 font-weight-bold text-center">
            {{ "face.editOther" | translate }} 
          </h5>
          <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        </div> -->
        </div>
        <form
          autocomplete="off"
          novalidate
          #f="ngForm"
        
        >
          <div class="modal-body">
              <div class="row">
                  <!-- <div
                    class="col-12 col-sm-12 col-xl-3 d-flex align-items-center"
                  >
                    <label>
                    {{"tag.tagname" | translate}}
                    </label>
                  </div> -->
              <div class="col-12 col-sm-12 col-xl-12">
                <div class="form-group input-group">
                  <textarea
                    type="text"
                    name="description"
                    [(ngModel)]="otherDesc"
                    class="form-control small-control"                        
                    placeholder="{{'face.enterdesc' | translate}}"
                  ></textarea>
                 
               
              </div>
              <div class="form-group input-group">
                <input
                  type="text"
                  name="link"
                  [(ngModel)]="otherLink"
                  class="form-control small-control"                        
                  placeholder="{{'face.enterLink' | translate}}"
                />
               
             
              </div>

               </div>
            </div>
           
          </div>
          <div class="modal-footer justify-content-center">
            <div>
              <button
                class="btn btn-default submitbtn mr-2"
                data-dismiss="modal"
                type="button"
                [disabled]="f.invalid"
                (click)="AddUpdateDescLinkOther()"
              >
                {{ "saveBtn" | translate }}
              </button>
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-default btn-secondary"
              >
                {{ "cancel" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>

<ng-container >
  <div id="editGlassModal" class="modal fade">
    <div class="modal-dialog modal-dialog-centered modal-confirm">
      <div class="modal-content">
        <div class="modal-header flex-column">
          <!-- <div class="icon-box">
            <i class="fas fa-times"></i>
          </div>						 -->
          <h5 class="modal-title w-100 font-weight-bold text-center">
            {{ "face.editGlass" | translate }} 
          </h5>
          <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        </div> -->
        </div>
        <form
          autocomplete="off"
          novalidate
          #f="ngForm"
        
        >
          <div class="modal-body">
              <div class="row">
                  <!-- <div
                    class="col-12 col-sm-12 col-xl-3 d-flex align-items-center"
                  >
                    <label>
                    {{"tag.tagname" | translate}}
                    </label>
                  </div> -->
              <div class="col-12 col-sm-12 col-xl-12">
                  <div class="form-group input-group">
                    <textarea
                      type="text"
                      name="description"
                      [(ngModel)]="otherDesc"
                      class="form-control small-control"                        
                      placeholder="{{'face.enterdesc' | translate}}"
                    ></textarea>
                   
                 
                </div>
                <div class="form-group input-group">
                  <input
                    type="text"
                    name="link"
                    [(ngModel)]="otherLink"
                    class="form-control small-control"                        
                    placeholder="{{'face.enterLink' | translate}}"
                  />
                 
               
                </div>
               </div>
            </div>
           
          </div>
          <div class="modal-footer justify-content-center">
            <div>
              <button
                class="btn btn-default submitbtn mr-2"
                data-dismiss="modal"
                type="button"
                (click)="AddUpdateDescLinkGlass()"
                [disabled]="f.invalid"
              >
                {{ "saveBtn" | translate }}
              </button>
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-default btn-secondary"
              >
                {{ "cancel" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>

<div id="deleteOtherModal" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h4 class="modal-title text-center w-100">
          {{ "areYouSure" | translate }}
        </h4>
      </div>
      <div class="modal-body text-center">
        <p class="mb-0">{{ "deleteRecord" | translate }}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "cancel" | translate }}
        </button>
        <button
          type="button"
          (click)="deleteOtherImage(deleteId, deletepath)"
          data-dismiss="modal"
          class="btn btn-danger"
        >
          {{ "delete" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
