<app-sidebar></app-sidebar>
<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-6">
        <h1 class="page-title">
          {{ "product.productList" | translate }}
        </h1>
      </div>
      <div class="col-6 text-right">
        <button class="btn btn-default" (click)="addNewProduct()" *ngIf="role==2">
          {{ "product.addNewProduct" | translate }}
        </button>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-9">
        <h1 class="page-title">
        
        </h1>
      </div>
      <div class="col-3 text-right">
        <div class="input-group">
          <select class="form-select form-control small-control" (change)="salonSelect(salonID.value)" #salonID  >
            <option value="" selected="selected"> {{'product.allsalon'|translate}}</option>
            <option value="{{salon.id}}" *ngFor="let salon of salon_list">{{salon.salonName | titlecase}}</option>
          </select>
         
        </div>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-12">
        <div class="table-responsive">
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="row-border hover table"
          >
            <thead>
              <tr>
                <th>{{ "product.productBrand" | translate }}</th>
                <th>{{ "product.productName" | translate }}</th>
                <th>{{ "product.productPrice" | translate }}</th>
                <th>{{ "product.productImage" | translate }}</th>
                <th class="text-right" width="130px" *ngIf="role==2">
                  {{ "action" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
               <tr *ngFor="let product of productList">
                <td>{{ product.product_brand | titlecase}}</td>
                <td>{{ product.product_name | titlecase }}</td>
                <td>{{ product.product_price }}</td>
                <td><img src="{{ baseURL }}{{ product.image_path_name }}" width="70px" height="70px"/>
                </td>                 
                <td class="text-right"  *ngIf="role==2">
                  <button class="btn edit-icon" (click)="editProduct(product.id)">
                    <i class="fas fa-pen"></i>
                  </button>
                  <button
                    class="btn btn-danger delete-icon mx-2"
                    data-toggle="modal"
                    data-target="#productModal"
                    (click)="showModal(product.id)"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </td>
              </tr> 
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- <div class="tab-box tab-box-vertical pt-3" role="tabpanel">
      <div class="row">
        <div class="col-sm-12">
          <div class="tab-content pt-3">
            <div role="tabpanel1" class="tab-pane tab-in active">
              <div class="row">
                <div
                  class="col-xl-2 col-md-3 col-sm-4 col-6"
                  *ngFor="let product of productList"
                >
                  <div class="tags-list-img mb-0">
                    <img
                      src="{{ baseURL }}{{ product.image_path_name }}"
                      (click)="editProduct(product.id)"
                    />
                    <div class="images-btn">
                      <button
                        data-toggle="modal"
                        data-target="#viewimageModal"
                        (click)="showProductModal(product.image_path_name)"
                      >
                        <i class="fal fa-eye"></i>
                      </button>
                      <button
                        data-toggle="modal"
                        data-target="#productModal"
                        (click)="showModal(product.id)"
                      >
                        <i class="fal fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                  <p class="videoTitle mb-4">{{ product.product_name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>
<div id="productModal" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h4 class="modal-title text-center w-100">
          {{ "areYouSure" | translate }}
        </h4>
      </div>
      <div class="modal-body text-center">
        <p class="mb-0">{{ "deleteRecord" | translate }}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "cancel" | translate }}
        </button>
        <button
          type="button"
          (click)="deleteProduct(deleteId)"
          data-dismiss="modal"
          class="btn btn-danger"
        >
          {{ "delete" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="viewimageModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <button
    type="button"
    class="close modal-close"
    data-dismiss="modal"
    aria-label="Close"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content video-content">
      <div class="modal-body p-0 text-center">
        <img
          src="{{ baseURL }}{{ image_path_name }}"
          class="img-fluid modal-cover"
        />
      </div>
    </div>
  </div>
</div>
