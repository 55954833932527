<app-sidebar></app-sidebar>
<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-6">
        <h1 class="page-title">
          {{'notification.notificationlist' | translate}}
        </h1>
      </div>
      <!-- <div class="col-6 text-right">
        <button class="btn btn-default" (click)="back()">
          {{ "backBtn" | translate }}
        </button>
      </div>  -->
      </div>
      <div class="row pt-3">
        <div class="col-12">
          <div class="table-responsive">
            <table
              datatable
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger"
              class="row-border hover table"
            >
              <thead>
                <tr >             
                  <th>{{'notification.title' | translate}}</th>   
                  <th>{{'notification.message' | translate}}</th> 
                  <th>{{'notification.customername' | translate}}</th> 
                  
                </tr>
              </thead>
              <tbody >
                  <tr *ngFor="let notification of notificationList">
                    <td>{{notification.data.title | translate}}</td>
                    <td>{{notification.data.massage | translate}}{{notification.data.name}}</td>
                    <td>{{notification.data.name}}</td>
                </tr>
              </tbody>
            </table>
        </div>
        </div>
        </div>
    </div>
  </div>

