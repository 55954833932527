<app-sidebar></app-sidebar>
<div class="body-padding-top">
    <div class="container-fluid">
      <div class="row pt-3">
        <div class="col-12">
          <h1 class="page-title">
            Tags
          </h1>
        </div> 
      </div>
      <div class="tab-box tab-box-vertical pt-3" role="tabpanel">
        <div class="row">
          <!-- <div class="col-sm-12">
            <ul class="nav nav-tabs nav-justified nav-tabs-dropdown main-tab" role="tablist">
              <li role="presentation">
                <a href="#celebrityfemale" aria-controls="celebrityfemale" role="tab" data-toggle="tab" class="active">Celebrity Female</a>
              </li>
              <li role="presentation">
                <a href="#celebritymale" aria-controls="celebritymale" role="tab" data-toggle="tab">Celebrity Male</a>
              </li>
              <li role="presentation">
                <a href="#peoplefemale" aria-controls="peoplefemale" role="tab" data-toggle="tab">People Female</a>
              </li>
              <li role="presentation">
                <a href="#peoplemale" aria-controls="peoplemale" role="tab" data-toggle="tab">People Male</a>
              </li>
              <li role="presentation">
                <a href="#designfemale" aria-controls="designfemale" role="tab" data-toggle="tab">Design Female</a>
              </li>
              <li role="presentation">
                <a href="#designmale" aria-controls="designmale" role="tab" data-toggle="tab">Design Male</a>
              </li>
            </ul>
            <div class="tab-content pt-3">
              <div role="tabpanel1" class="tab-pane tab-in active" id="celebrityfemale">
                 <div class="row">
                  <div class="col-sm-6">
                    <div class="tags-list-img"> 
                     
                      <img src="assets/images/img-1.png">
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <ul class="nav nav-tabs nav-justified nav-tabs-dropdown main-tab" role="tablist">
                      <li role="presentation">
                        <a href="#tags" aria-controls="tags" role="tab" data-toggle="tab" class="active">Tags</a>
                      </li>
                      <li role="presentation">
                        <a href="#filter" aria-controls="filter" role="tab" data-toggle="tab">Filter</a>
                      </li>
                    </ul>
                    <div class="tab-content pt-3">
                      <div role="tabpanel" class="tab-pane tab-in active" id="tags">
                        <div id="container" class="example example_markup">
                          <div class="old-tags">
                            <div class="old-tags-list">
                              test
                              <span>x</span>
                            </div>
                            <div class="old-tags-list">
                              test
                              <span>x</span>
                            </div>
                            <div class="old-tags-list">
                              test
                              <span>x</span>
                            </div>
                            <div class="old-tags-list">
                              test
                              <span>x</span>
                            </div>
                          </div>  
                          <div class="bs-example">
                            <input type="text" name="tags" value="pfTag1,pfTag2,pfTag3" data-role="tagsinput" />
                          </div>
                          <div class="tags-add-btn">
                            <button class="btn btn-default btn-secondary">Cancel</button>
                            <button class="btn btn-default">Save</button>
                          </div>
                        </div>
                      </div>
                      <div role="tabpanel" class="tab-pane" id="filter">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="old-tags">
                              <div class="old-tags-title">Hair Lenght</div>
                              <div class="old-tags-list">
                                Medium
                                <span>x</span>
                              </div>
                              <div class="old-tags-list">
                                Long
                                <span>x</span>
                              </div>
                              <div class="bs-example">
                                <input type="text" name="tags" value="Short" data-role="tagsinput" />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="old-tags">
                              <div class="old-tags-title">Hair Type</div>
                              <div class="old-tags-list">
                                Smooth
                                <span>x</span>
                              </div>
                              <div class="old-tags-list">
                                Wavy
                                <span>x</span>
                              </div>
                              <div class="bs-example">
                                <input type="text" name="tags" value="Curly" data-role="tagsinput" />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="old-tags">
                              <div class="old-tags-title">Hair Colour</div>
                              <div class="old-tags-list">
                                Light
                                <span>x</span>
                              </div>
                              <div class="bs-example">
                                <input type="text" name="tags" value="Dark" data-role="tagsinput" />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="old-tags">
                              <div class="old-tags-title">Age</div>
                              <div class="old-tags-list">
                                Adult
                                <span>x</span>
                              </div>
                              <div class="bs-example">
                                <input type="text" name="tags" value="Young" data-role="tagsinput" />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="old-tags">
                              <div class="old-tags-title">Kind of Photo</div>
                              <div class="old-tags-list">
                                Celebrity
                                <span>x</span>
                              </div>
                              <div class="bs-example">
                                <input type="text" name="tags" value="Design" data-role="tagsinput" />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="tags-add-btn">
                              <button class="btn btn-default btn-secondary">Cancel</button>
                              <button class="btn btn-default">Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div role="tabpanel" class="tab-pane" id="celebritymale">ovale female...</div>
              <div role="tabpanel" class="tab-pane" id="peoplefemale">square male...</div>
              <div role="tabpanel" class="tab-pane" id="peoplemale">square female...</div>
              <div role="tabpanel" class="tab-pane" id="designfemale">triangle male...</div>
              <div role="tabpanel" class="tab-pane" id="designmale">triangle female...</div> 
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>