<app-sidebar></app-sidebar>
<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-6">
        <h1 class="page-title">
          {{ "order.orderList" | translate }}
        </h1>
      </div>
      <!-- <div class="col-6 text-right">
        <button class="btn btn-default" (click)="back()">
          {{ "backBtn" | translate }}
        </button>
      </div>  -->
    </div>
    <div class="row pt-3">
      <div class="col-12">
        <div class="table-responsive">
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="row-border hover table"
          >
            <thead>
              <tr >             
                <th>{{ "order.name" | translate }}</th>   
                <th>{{ "order.surname" | translate }}</th>  
                <th>{{ "order.salonname" | translate }}</th>  
                <th>{{ "order.salonphone" | translate }}</th>  
                <th>{{ "order.orderstatus" | translate }}</th>  
                <th>{{ "order.dateadded" | translate }}</th>   
                <th>{{ "order.note" | translate }}</th>           
                <th class="text-right" width="130px"  *ngIf="role==2">
                  {{ "action" | translate }}
                </th>
              </tr>
            </thead>
            <tbody  *ngFor="let order of orderList;let i=index;"  >          
              <tr  *ngIf="order.order_detail.is_confirm==false"   style="border-bottom: 0vmax;" >             
                <td data-toggle="modal" 
                data-target="#editModalCenter"   (click)="editOrder(order.order_detail.id)">{{ order.name | titlecase }}</td>
                <td data-toggle="modal" 
                data-target="#editModalCenter"   (click)="editOrder(order.order_detail.id)">{{ order.surname | titlecase }}</td>
                <td data-toggle="modal" 
                data-target="#editModalCenter"   (click)="editOrder(order.order_detail.id)">{{ order.order_detail.salon_id?order.order_detail.salon_id.salonName:'' | titlecase }}</td>
                <td data-toggle="modal" 
                data-target="#editModalCenter"   (click)="editOrder(order.order_detail.id)">{{ order.order_detail.salon_id?order.order_detail.salon_id.salon_admin_phone:'' }}</td>
                <td data-toggle="modal" 
                data-target="#editModalCenter"   (click)="editOrder(order.order_detail.id)">{{"order.new" | translate }}</td>
                <td data-toggle="modal" 
                data-target="#editModalCenter"   (click)="editOrder(order.order_detail.id)">{{ order.order_detail.create_date |  date:'dd/MM/yyyy' }}</td>     
                <!-- <td>{{ order?.order_detail?.Face_Shape_id?.notes ? order?.order_detail?.Face_Shape_id?.notes :''  }}</td>            -->
                <td>
                  <button class="btn btn-default" data-toggle="modal" data-target="#noteModalCenter" *ngIf="order?.order_detail?.Face_Shape_id && order?.order_detail?.Face_Shape_id?.notes" (click)="showNotes(order?.order_detail?.Face_Shape_id?.notes)" style="z-index: 10000;">
                    {{'order.showNote' | translate}}
                  </button>
                </td>
                <td class="text-right"   *ngIf="role==2">
                  <!-- <button class="btn edit-icon"  *ngIf="order.order_detail.is_confirm==false"  
                  data-toggle="modal" 
                  data-target="#editModalCenter"   (click)="editOrder(order.order_detail.id)"
                 >
                    <i class="fas fa-pen"></i>
                  </button> -->
                  <button
                    class="btn btn-danger delete-icon mx-2"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    (click)="showModal(order.order_detail.id)"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </td>
              </tr>
              <tr  *ngIf="order.order_detail.is_confirm==true"  >             
                <td>{{ order.name | titlecase }}</td>
                <td>{{ order.surname | titlecase }}</td>
                <td>{{ order.order_detail.salon_id?order.order_detail.salon_id.salonName:'' | titlecase }}</td>
                <td>{{ order.order_detail.salon_id?order.order_detail.salon_id.salon_admin_phone:'' }}</td>
                <td >{{"order.work" | translate }}</td>
                <td>{{ order.order_detail.create_date |  date:'dd/MM/yyyy' }}</td>     
                <!-- <td>{{ order?.order_detail?.Face_Shape_id?.notes ? order?.order_detail?.Face_Shape_id?.notes :''  }}</td>            -->
                <td>
                  <button class="btn btn-default" data-toggle="modal" data-target="#noteModalCenter" *ngIf="order?.order_detail?.Face_Shape_id && order?.order_detail?.Face_Shape_id?.notes" (click)="showNotes(order?.order_detail?.Face_Shape_id?.notes)">
                    {{'order.showNote' | translate}}
                  </button>
                </td>
                <td class="text-right"   *ngIf="role==2">
                  <!-- <button class="btn edit-icon"  *ngIf="order.order_detail.is_confirm==false"  
                  data-toggle="modal" 
                  data-target="#editModalCenter"   (click)="editOrder(order.order_detail.id)"
                 >
                    <i class="fas fa-pen"></i>
                  </button> -->
                  <button
                    class="btn btn-danger delete-icon mx-2"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    (click)="showModal(order.order_detail.id)"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </td>
              </tr>           
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
 <div id="exampleModalCenter" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h4 class="modal-title text-center w-100">
          {{ "areYouSure" | translate }}
        </h4>
      </div>
      <div class="modal-body text-center">
        <p class="mb-0">{{ "deleteRecord" | translate }}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "cancel" | translate }}
        </button>
        <button
          type="button"
          (click)="deleteOrder(deleteId)"
          data-dismiss="modal"
          class="btn btn-danger"
        >
          {{ "delete" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div id="noteModalCenter" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h4 class="modal-title text-center w-100">
          {{ "order.note" | translate }}
        </h4>
      </div>
      <div class="modal-body text-center">
        <p class="mb-0">{{notes}}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "cancel" | translate }}
        </button>
        
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="editOrderFlag">
  <div id="editModalCenter" class="modal fade testing">
    <div class="modal-dialog modal-dialog-centered modal-confirm">
      <div class="modal-content">
        <div class="modal-header flex-column">
          <!-- <div class="icon-box">
            <i class="fas fa-times"></i>
          </div>						 -->
          <h5 class="modal-title w-100 font-weight-bold text-center">
            {{ "order.orderEdit" | translate }}
          </h5>         
           <!-- <button type="button"  aria-hidden="true" >Add Product</button> -->
        <!-- </div>  -->
        </div>
         <form
          autocomplete="off"
          novalidate
                  
        > 
          <div class="modal-body">
            <div class="row">
              <div
              class="col-12 col-sm-12 col-xl-9 d-flex align-items-center"
            >
              <label>
                {{ "customer.name" | translate }} - {{orderid?.Face_Shape_id?.customer_name}}
               
              </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-3">
              {{ "order.dateadded" | translate }} - {{orderid.create_date | date:"dd/MM/yyyy"}}
            
              </div>
              </div>
            <div class="row">
            <div class="table-responsive">
              <table id="example" class="table table-striped" style="width:100%; text-align: left;">
                <thead>
                    <tr>
                      <th>{{"order.name"|translate}}</th>
                      <th>{{"order.brand" |translate}}</th>
                      <th width="50px" >{{"order.qty"|translate}}</th>
                      <th >{{"order.price" | translate}}</th>
                      <th width="150px">{{"action" | translate}}</th>
                    </tr>
                </thead>
                <tbody >
                  <ng-container *ngFor="let product of editData let i=index;">
                  <tr *ngIf="showAddproductValid[i]==false">
                    <td>{{product.product_id?product.product_id.product_name:''}}
                      <input type="hidden" value="{{orderid.id}}" name="orderid{{i}}"/> 
                      <input type="hidden" value="{{product.product_id.id}}" name="id{{i}}"/> </td>
                    <td>{{product.product_id?product.product_id.product_brand:''}}</td>
                    <td> <input type="number"  width="50px !important;"  name="product_quantity{{i}}" [(ngModel)]="productMultipleArray[i].product_quantity" /></td>
                    <td>{{product.product_id?product.product_id.product_price:''}}</td>
                    <td>
                      <!-- <button class="btn edit-icon"  
                      data-toggle="modal"
                      data-target="#editModalCenter"   (click)="editOrder(product.product_id)">
                        <i class="fas fa-pen"></i>
                      </button> -->
                      <button
                        class="btn btn-danger delete-icon mx-2"
                        data-toggle="modal"
                        data-target="#productDeleteCenter"
                        (click)="deleteProduct(product.product_id.id,orderid.id)"
                      >
                        <i class="fas fa-times"></i>
                      </button>

                    </td>
                  </tr>
                  <tr *ngIf="showAddproductValid[i]==true">
                    <td>
                      <input type="hidden" value="{{orderid.id}}" name="orderid{{i}}"/> 
                      <input type="hidden" value="{{product.product_id.id}}" name="id{{i}}"/> 
                    <select name="product{{i}}" [(ngModel)]="productId[i]" (change)="selectProduct(i)" class="form-select form-control small-control">
                        <option>{{'product.selectProduct' | translate}}</option>
                        <option *ngFor="let productt of productList" [value]="productt.id">{{productt.product_name}}</option>
                      </select>
                    </td>
                    <td>{{product.product_id?product.product_id.product_brand:''}}</td>
                    <td> <input type="number"  width="50px !important;"  name="product_quantity{{i}}" [(ngModel)]="productMultipleArray[i].product_quantity" /></td>
                    <td>{{product.product_id?product.product_id.product_price:''}}</td>
                    <td>
                      <!-- <button class="btn edit-icon"  
                      data-toggle="modal"
                      data-target="#editModalCenter"   (click)="editOrder(product.product_id)">
                        <i class="fas fa-pen"></i>
                      </button> -->
                      <button
                        class="btn btn-danger delete-icon mx-2"
                        (click)="deleteProductDetails(product.product_id.id,orderid.id,'simpleRemove',i)"
                      >
                        <i class="fas fa-times"></i>
                      </button>

                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table> 
            </div>
          </div>           
          </div>
          <div class="modal-footer justify-content-center">
            <div>
              <button class="btn btn-default btn-secondary mr-2" (click)="addProduct();">{{"product.addNewProduct" | translate}}</button>
               <button
                class="btn btn-default submitbtn mr-2"
                data-dismiss="modal"
                type="button"
                (click)="submitOrderForm()"
                [disabled]="orderForm.invalid"
              >
                {{ "confirm" | translate }}
              </button> 
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-default btn-secondary"
              >
                {{ "cancel" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>


<div id="productDeleteCenter" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h4 class="modal-title text-center w-100">
          {{ "areYouSure" | translate }}
        </h4>
      </div>
      <div class="modal-body text-center">
        <p class="mb-0">{{ "deleteRecord" | translate }}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "cancel" | translate }}
        </button>
        <button
          type="button"
          (click)="deleteProductDetails(productid,editId)"
          data-dismiss="modal"
          class="btn btn-danger"
        >
          {{ "delete" | translate }}
        </button>
      </div>
    </div>
  </div>
</div> 