import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TagService } from '../tag.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { ViewChild } from '@angular/core';
@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.css']
})
export class TagListComponent implements OnInit {
  tagList:any;
  addTagFlag=false;
  errorDialog:any;
  somethingWrong:any;
  successDialog:any;
  submitted = false;
  @ViewChild(DataTableDirective,{static: false}) datatableElement!: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  deleteId:any;
  dtTrigger: Subject<any> = new Subject<any>();
  title = 'datatables';
  editId:any;
  editTagFlag=false;
  tagFormAdd = new FormGroup({});
  tagFormEdit = new FormGroup({});
  tagDataList:any=[];
  placeholder:any='';
  historyHeading:any='';
  not_found:any='';
  public keyword = 'tag_name';
  constructor( private formBuilder: FormBuilder,public router: Router,public tagService: TagService,  private translateService: TranslateService, public alertService: AlertService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.placeholder = this.translateService.instant('entertagname');
    this.historyHeading = this.translateService.instant('tag.recentlySelected');
    this.not_found = this.translateService.instant('tag.not_found');
   
    this.getTagList();
  }
  getTagList() {
    this.tagList = [];
    this.tagService.getTagList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.tagList = data.data;       
          for (var i = 0; i < this.tagList.length; i++) {
            this.tagDataList.push(this.tagList[i]['tag_name']);
          }
         //console.log(this.tagList);
         this.dtTrigger.next();
        } else {
          // this.alertService.showDangerAlert('Error', 'tag not exist.');
        }
        
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  back(){
    this.router.navigate(['viewtagList']);
  }
 
  show(id: any) {
    this.deleteId = id;
  }
  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    // Destroy the table first
    dtInstance.destroy();
    // Call the dtTrigger to rerender again
    this.getTagList();
    //this.dtTrigger.next();
      });
    }
  deleteTag(id: any) {
    this.tagService.delete(id).subscribe(
      (data) => {
       // this.dtTrigger.unsubscribe();
        this.addTagFlag = false;
        this.editTagFlag=false; 
        let DeleteMsg = this.translateService.instant('tag.tagdeleteMsg');
        this.alertService.showSuccessAlert(this.successDialog, DeleteMsg);
        
        setTimeout(() => {
          this.rerender();
        }, 1000);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  createform() {
    this.tagFormAdd = this.formBuilder.group({
      tag_name: [{ value: '', disabled: false }, Validators.required],
    });
  }
  editform() {
    this.tagFormEdit = this.formBuilder.group({
      tag_name: [{ value: '', disabled: false }, Validators.required],
    });
  }
  editTag(id: any,tagname:any) {
    this.editTagFlag=true;
    this.editId = id;   
    this.editform();
    this.formValidationEdit.tag_name.setValue(
      tagname
    );
  }
  get formValidation() {
    return this.tagFormAdd.controls;
  }
  get formValidationEdit() {
    return this.tagFormEdit.controls;
  }
  submitTagForm(){       
    if (this.tagFormAdd.valid) {   
      // console.log(this.tagForm.value);
      this.tagService.add(this.tagFormAdd.value).subscribe(
        (data) => {
          //this.dtTrigger.unsubscribe();
           if (data['is_error'] == true) {
            let tagexist = this.translateService.instant('productTag.tagExist');
            this.alertService.showDangerAlert(this.errorDialog, tagexist);
          } else {
            let tagAdded = this.translateService.instant(
              'productTag.tagAddedMsg'
            );
            this.alertService.showSuccessAlert(this.successDialog, tagAdded);             
          }
         // this.router.navigate(['viewtagList']);
          this.addTagFlag = false;
          this.editTagFlag=false; 
          setTimeout(() => {
            this.rerender();
          }, 1000);
        },
        (error: HttpErrorResponse) => {
          // console.log(error.name + ' ' + error.message);
          this.alertService.showDangerAlert(
            this.errorDialog,
            this.somethingWrong
          );
        }
      );
    }       
  }
  updateTagForm(){
    if (this.tagFormEdit.valid)
    {  
  this.tagService.update(this.editId,this.tagFormEdit.value).subscribe(
      (data) => {    
        //this.dtTrigger.unsubscribe();
        this.editTagFlag=false;  
        this.addTagFlag=false;  
       // this.router.navigate(['viewtagList']);
        let msg = this.translateService.instant('tag.tagupdate');     
        this.alertService.showSuccessAlert(this.successDialog, msg);
        setTimeout(() => {
          this.rerender();
        }, 1000);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
    }
}
 
  addNewTag() {    
    this.tagFormAdd.reset();
    this.createform();
    this.addTagFlag = true;
    
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
