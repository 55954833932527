import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import AppConstants from 'src/app/shared/commons/app.constants';
@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private httpClient: HttpClient) {}
  getImageList(): Observable<any> {
    return this.httpClient.get(
      AppConstants.SERVICE_BASE_URL + '/api/common/Image_List'
    );
  }
  getById(id: any) {
    return this.httpClient.get<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Image_List/' + id
    );
  }
  add(image: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/image_list/addimage_list',
      image
    );
  }
  update(id: any, image: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/image_list/updateimage_list/' + id,
      image
    );
  }
  delete(id: number) {
    return this.httpClient.delete<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/soft/Image_List/' + id
    );
  }
  searchByHairType(postData: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/image_list/getByFilter/Image_List/',
      postData
    );
  }
  getFaceShapeList() {
    return this.httpClient.get(
      AppConstants.SERVICE_BASE_URL + '/api/face_shape/getFaceShapeType'
    );
  }
}
