import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import AppConstants from 'src/app/shared/commons/app.constants';
@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(public httpClient: HttpClient) { }
  getCustomerList(): Observable<any>{ 
       return this.httpClient.get(
      AppConstants.SERVICE_BASE_URL + '/api/common/Face_Shape_Detection/'
    );
  }
  getById(id: any) {
    // return this.httpClient.get<any>(
    //   AppConstants.SERVICE_BASE_URL + '/api/common/Product_Tag/' + id
    // );
  }
  update(id: any, customer: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Face_Shape_Detection/' + id,
      customer
    );
  }
  delete(id: number) {
    return this.httpClient.delete<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/soft/Face_Shape_Detection/' + id
    );
  }
  getCustomerById(id:any){
    return this.httpClient.get<any>(AppConstants.SERVICE_BASE_URL + '/api/face_shape_detection/getAllCustomerDetailsByID/' + id);
  }
}
