import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { CustomerService } from '../customer/customer.service';
import AppConstants from 'src/app/shared/commons/app.constants';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css'],
  providers: [NgbCarouselConfig]
})
export class CustomerDetailComponent implements OnInit {
  slidess:any = 4;
  id:any;
  title:any='slick'
  customerDetails:any;
  baseUrl:any = AppConstants.SERVICE_BASE_URL;
  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false
  };

  constructor(public activatedRoute:ActivatedRoute,private customerService:CustomerService,public config: NgbCarouselConfig) {
    config.interval = 10000;
    config.showNavigationIndicators = false;
    config.keyboard = true;
    config.pauseOnHover = false;
    config.showNavigationArrows = true;
   }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if(this.id && this.id!='' && this.id!=undefined){
      this.getCustonerById(this.id)
    }
  }
  getCustonerById(id:any){
    console.log('dATA');
    this.customerService.getCustomerById(id).subscribe((res:any)=>{
      if(res['data']){
        this.customerDetails = res['data'];
        
       }
    })
  }
}
