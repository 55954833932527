import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AddEditSalonComponent } from './components/add-edit-salon/add-edit-salon.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { FaceshapeComponent } from './components/faceshape/faceshape.component';
import { TagsComponent } from './components/tags/tags.component';
import { TagListComponent } from './components/tags/tag-list/tag-list.component';
import { TrainerComponent } from './components/trainer/trainer.component';
import { ImagesComponent } from './components/images/images.component';
import { HealthComponent } from './components/health/health.component';
import { AddtagComponent } from './components/tags/addtag/addtag.component';
import { AddimageComponent } from './components/images/addimage/addimage.component';
import { AddtrainerComponent } from './components/trainer/addtrainer/addtrainer.component';
import { AddhealthComponent } from './components/health/addhealth/addhealth.component';
import { ProductComponent } from './components/product/product.component';
import { AddeditproductComponent } from './components/product/addeditproduct/addeditproduct.component';
import { ProductTagComponent } from './components/product-tag/product-tag.component';
import { ProductTagListComponent } from './components/product-tag/product-tag-list/product-tag-list.component';
import { CustomerComponent } from './components/customer/customer.component';
import { OrderComponent } from './components/order/order.component';
import { EditorderComponent } from './components/order/editorder/editorder.component';
import {NotificationListComponent} from './components/notification-list/notification-list.component'
import { CustomerDetailComponent } from './components/customer-detail/customer-detail.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { EffectComponent } from './components/effect/effect.component';
import { AddeffectComponent } from './components/effect/addeffect/addeffect.component';
const routes: Routes = [
  
  { path: '', component: SignInComponent },
  
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'addEditUser',
    component: AddEditSalonComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'faceShapeInfo',
    component: FaceshapeComponent,
    canActivate: [AuthGuard],
  },
  { path: 'taglist', component: TagsComponent, canActivate: [AuthGuard] },
  {
    path: 'viewtagList',
    component: TagListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'trainerList',
    component: TrainerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'effectList',
    component: EffectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'addEditTrainer',
    component: AddtrainerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'addEditEffect',
    component: AddeffectComponent,
    canActivate: [AuthGuard],
  },
  { path: 'imageList', component: ImagesComponent, canActivate: [AuthGuard] },
  {
    path: 'addEditImage',
    component: AddimageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'productList',
    component: ProductComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'productTagList',
    component: ProductTagComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'viewProductTagList',
    component: ProductTagListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'addEditProduct',
    component: AddeditproductComponent,
    canActivate: [AuthGuard],
  },
  { path: 'healthList', component: HealthComponent, canActivate: [AuthGuard] },
  { path: 'customerList', component: CustomerComponent, canActivate: [AuthGuard] },
  { path:  'orderList', component: OrderComponent, canActivate: [AuthGuard] },
  { path:  'editOrder', component: EditorderComponent, canActivate: [AuthGuard] },
  
  {
    path: 'addEditHealth',
    component: AddhealthComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "notificationList",
    component:NotificationListComponent,
    canActivate:[AuthGuard]
  },
  {
    path:"customer-detail/:id",
    component:CustomerDetailComponent
  },
  {
    path:"privacy-policy",
    component:PrivacyPolicyComponent
  },
  { path: '**', redirectTo: '/' },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
