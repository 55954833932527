import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import AppConstants from 'src/app/shared/commons/app.constants';
@Injectable({
  providedIn: 'root',
})
export class EffectService {
  constructor(private httpClient: HttpClient) {}

  getEffectList(): Observable<any> {
    return this.httpClient.get(
      AppConstants.SERVICE_BASE_URL + '/api/common/effects'
    );
  }
  getById(id: any) {
    return this.httpClient.get<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/effects/' + id
    );
  }
  add(effect: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL +
        '/api/effects/addEffects',
        effect
    );
  }
  update(id: any, effect: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL +
        '/api/effects/updateEffects/' +
        id,
        effect
    );
  }
  delete(id: number) {
    return this.httpClient.delete<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/soft/effects/' + id
    );
  }
}
