<app-sidebar></app-sidebar>
<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-6">
        <h1 class="page-title">
          {{ "customer.customerList" | translate }}
        </h1>
      </div>
      <!-- <div class="col-6 text-right">
        <button class="btn btn-default" (click)="back()">
          {{ "backBtn" | translate }}
        </button>
      </div>  -->
    </div>
    <div class="row pt-3">
      <div class="col-12">
        <div class="table-responsive">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover table">
            <thead>
              <tr>
                <th>{{ "customer.name" | translate }}</th>
                <th>{{ "customer.surname" | translate }}</th>
                <th>{{ "customer.salonname" | translate }}</th>
                <th>{{ "customer.salonphone" | translate }}</th>
                <th>{{ "customer.privacy" | translate }}</th>
                <th>{{ "customer.dateadded" | translate }}</th>
                <th class="text-right" width="130px" *ngIf="role == 2">
                  {{ "action" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let customer of customerList; let i = index">
                <td>{{ customer.name | titlecase }}</td>
                <td>{{ customer.surname | titlecase }}</td>
                <td>
                  {{
                  customer.customer_detail.salon_id
                  ? customer.customer_detail.salon_id.salonName
                  : ("" | titlecase)
                  }}
                </td>
                <td>
                  {{
                  customer.customer_detail.salon_id
                  ? customer.customer_detail.salon_id.salon_admin_phone
                  : ("" | titlecase)
                  }}
                </td>
                <td>
                  <div class="input-group images-radio-box">
                    <p *ngIf="customer.video_path_name !== ''">
                      <input type="radio" id="privacytrue{{i}}" name="privacy" data-toggle="modal"
                        data-target="#videopopup"
                        (click)="showuser(customer.customer_detail.create_date, customer.name , customer.surname , customer.video_path_name)" />
                      <label for="privacytrue{{i}}"></label>
                    </p>
                    <!-- <p *ngIf="!customer.customer_detail.isPrivacy">
                      <input
                        type="radio"
                        id="privacyfalse{{i}}"
                        name="privacy"
                        [checked]="false"                        
                      />
                      <label for="privacyfalse{{i}}"></label>
                    </p> -->
                  </div>
                </td>
                <td>
                  {{
                  customer.customer_detail.create_date | date: "dd/MM/yyyy"
                  }}
                </td>
                <td class="text-right" *ngIf="role == 2">
                  <button class="btn edit-icon" data-toggle="modal" data-target="#editModalCenter" (click)="
                      editCustomer(
                        customer.customer_detail.id,
                        customer.name,
                        customer.surname
                      )
                    ">
                    <i class="fas fa-pen"></i>
                  </button>
                  <button class="btn btn-danger delete-icon mx-2" data-toggle="modal" data-target="#exampleModalCenter"
                    (click)="showModal(customer.customer_detail.id)">
                    <i class="fas fa-times"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="exampleModalCenter" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h4 class="modal-title text-center w-100">
          {{ "areYouSure" | translate }}
        </h4>
      </div>
      <div class="modal-body text-center">
        <p class="mb-0">{{ "deleteRecord" | translate }}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "cancel" | translate }}
        </button>
        <button type="button" (click)="deleteCustomer(deleteId)" data-dismiss="modal" class="btn btn-danger">
          {{ "delete" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<div id="videopopup" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-body text-center">
        <p class="mb-0">{{ "privacyAcceptedon" | translate }} {{create_date | date: "dd/MM/yyyy"}} <br> {{name}}
          {{surname}}</p>
      </div>
      <div class="modal-footer justify-content-center">

        <button type="button" class="btn btn-default">
          <a href="{{baseURL}}{{path}}" download>{{ "download" | translate }}</a>
        </button>
        <button type="button" class="btn btn-default btn-secondary" data-dismiss="modal">
          {{ "close" | translate }}
        </button>
        <button type="button" data-toggle="modal" (click)="showVideo(path)" data-target="#videoModal"
          class="btn btn-default">
          {{ "open" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="editcustomerFlag">
  <div id="editModalCenter" class="modal fade">
    <div class="modal-dialog modal-dialog-centered modal-confirm">
      <div class="modal-content">
        <div class="modal-header flex-column">
          <!-- <div class="icon-box">
            <i class="fas fa-times"></i>
          </div>						 -->
          <h5 class="modal-title w-100 font-weight-bold text-center">
            {{ "customer.editcustomer" | translate }}
          </h5>
          <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        </div> -->
        </div>
        <form autocomplete="off" novalidate [formGroup]="customerForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
                <label>
                  {{ "customer.name" | translate }}
                </label>
              </div>
              <div class="col-12 col-sm-12 col-xl-9">
                <div class="form-group input-group">
                  <input type="text" formControlName="name" class="form-control small-control" maxlength="50"
                    placeholder="{{ 'customer.name' | translate }}" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
                <label>
                  {{ "customer.surname" | translate }}
                </label>
              </div>
              <div class="col-12 col-sm-12 col-xl-9">
                <div class="form-group input-group">
                  <input type="text" formControlName="surname" class="form-control small-control" maxlength="50"
                    placeholder="{{ 'customer.surname' | translate }}" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <div>
              <button class="btn btn-default submitbtn mr-2" data-dismiss="modal" type="button" (click)="submitForm()"
                [disabled]="customerForm.invalid">
                {{ "saveBtn" | translate }}
              </button>
              <button type="button" data-dismiss="modal" class="btn btn-default btn-secondary">
                {{ "cancel" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="videoFlag">
  <div class="modal fade" data-backdrop="false" id="videoModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content video-content">
        <div class="modal-body p-0">
          <button (click)="pauseVideo()" type="button" class="close modal-close" data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <video class="w-100" (playing)="onPlayingVideo($event)" controls autoplay *ngIf="videoPath">
            <source [src]="getVideoURL()" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  </div>
</ng-container>