import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { SalonService } from './salon.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  deleteId: any;
  dtTrigger: Subject<any> = new Subject<any>();
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  userList: any = [];
  userName: any = '';

  errorDialog: any;
  successDialog: any;
  somethingWrong: any;
  constructor(
    private translateService: TranslateService,
    public router: Router,
    public salonService: SalonService,
    public alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    //console.log(this.successDialog);
    this.userName = localStorage.getItem('user');
    localStorage.removeItem('edit_id');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };
    this.getAllUser();
  }

  //Add new User
  addNewUser() {
    this.router.navigate(['addEditUser']);
  }
  editSalon(id: any) {
    localStorage.removeItem('edit_id');
    localStorage.setItem('edit_id', id);
    this.router.navigate(['addEditUser']);
  }
  //delete user
  deleteSalon(id: any) {
    this.salonService.delete(id).subscribe(
      (data) => {
        this.dtTrigger.unsubscribe();
        let userDeleteMsg = this.translateService.instant('userdeleteMsg');
        this.alertService.showSuccessAlert(this.successDialog, userDeleteMsg);
        setTimeout(() => {
          this.getAllUser();
        }, 1000);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  // List of Users
  getAllUser() {
    this.salonService.getAllSaloons().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.userList = data.data;
          this.dtTrigger.next();
        } else {
          let usernotExist = this.translateService.instant('notexist');
          this.alertService.showDangerAlert(this.errorDialog, usernotExist);
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  show(id: any) {
    this.deleteId = id;
  }
}
