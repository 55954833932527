<div class="login-wrapper">
  <div class="loginBox">
    <div class="main-bg">
      <img src="assets/images/logo.png" class="login-logo" />
      <h6 class="login-title">Log in to Weeme! Studio</h6>
      <!-- <h6 class="login-title">Accedi a Weeme! Studio</h6>  -->
      <div class="user-icon">
        <i class="fas fa-user"></i>
      </div>
    </div>
    <div class="bottom-content mt-3">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <input
            type="text"
            formControlName="username"
            class="form-control"
            placeholder="Username"
            [ngClass]="{
              'is-invalid': submitted && formvalidation.username.errors
            }"
          />
          <div
            *ngIf="submitted && formvalidation.username.errors"
            class="invalid-feedback"
          >
            <div *ngIf="formvalidation.username.errors.required">
              Username is required
            </div>
          </div>
        </div>

        <div class="form-group">
          <input
            type="password"
            formControlName="password"
            class="form-control"
            placeholder="Password"
            [ngClass]="{
              'is-invalid': submitted && formvalidation.password.errors
            }"
          />
          <div
            *ngIf="submitted && formvalidation.password.errors"
            class="invalid-feedback"
          >
            <div *ngIf="formvalidation.password.errors.required">
              Password is required
            </div>
          </div>
        </div>
        <button type="submit" class="btn block-btn">Login</button>
      </form>
      <!-- <a href="#" class="text-link text-secondary mt-3 d-inline-block">
                Forget password?
              </a> -->
    </div>
  </div>
</div>
