import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import AppConstants from 'src/app/shared/commons/app.constants';
import { APP_BASE_HREF } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class HealthService {
  constructor(private httpClient: HttpClient) {}
  getSkinList(): Observable<any> {
    return this.httpClient.get(
      AppConstants.SERVICE_BASE_URL + '/api/common/Skin_List'
    );
  }
  add(skin: any) {
    return this.httpClient.put<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Skin_List',
      skin,
      { headers: AppConstants.getHeaders() }
    );
  }
  update(id: any, skin: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Skin_List/' + id,
      skin,
      { headers: AppConstants.getHeaders() }
    );
  }
  delete(id: number) {
    return this.httpClient.delete<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/soft/Skin_List/' + id
    );
  }
  addHair(hair: any) {
    return this.httpClient.put<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Hair_List',
      hair,
      { headers: AppConstants.getHeaders() }
    );
  }
  getHairList(): Observable<any> {
    return this.httpClient.get(
      AppConstants.SERVICE_BASE_URL + '/api/common/Hair_List'
    );
  }
  addHairProduct(product: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/product_list_hair_type_wise',
      product,
      { headers: AppConstants.getHeaders() }
    );
  }
  addSkinProduct(product: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/product_list_skin_wise',
      product
    );
  }
  getProductHairType(hair: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL +
        '/api/product_list_hair_type_wise/getProduct_List_Hair_Type_Wise',
        hair
    );
  }
  getProductSkinType(skin: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL +
        '/api/product_list_skin_wise/getProduct_List_Skin_Wise',
      skin
    );
  }
  getProductListByProduct(name:any)
  {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL +
        '/api/product/searchbyproductname',
        name
    );
   
  }
  getProductByLifstleOrHairWashFrequency(where:any){
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + 
      '/api/common/execute/conditions/Product',
      where);
  }
  addUpdateLifestyleOrHairwashFrequncy(data:any){
    return this.httpClient.post<any>(AppConstants.SERVICE_BASE_URL + '/api/product_list_hair_type_wise/add_life_style_and_hair_wash_frequency',data);
  }
}
