<app-sidebar></app-sidebar>
<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-12">
        <h1 class="page-title">
          <span *ngIf="isAddMode">{{ "product.addProduct" | translate }}</span>
          <span *ngIf="!isAddMode">{{
            "product.editProduct" | translate
            }}</span>
        </h1>
      </div>
    </div>
    <div class="tab-box tab-box-vertical pt-3" role="tabpanel">
      <form [formGroup]="productform" class="form" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-sm-12">
            <ul class="nav nav-tabs nav-justified nav-tabs-dropdown main-tab" role="tablist">
              <li role="presentation">
                <a href="#Health" (click)="currentHealth()" role="tab" data-toggle="tab"
                  [ngClass]="{ active: index == 1 }">{{ "health" | translate }}</a>
              </li>
              <li role="presentation">
                <a href="#beauty" (click)="currentBeauty()" role="tab" data-toggle="tab"
                  [ngClass]="{ active: index == 2 }">{{ "product.beauty" | translate }}</a>
              </li>
              <li role="presentation">
                <a href="#season" (click)="currentSeason()" role="tab" data-toggle="tab"
                  [ngClass]="{'active':index == 3}">{{"product.season" | translate}}</a>
              </li>
              <li role="presentation">
                <a href="#info" (click)="currentInfo()" role="tab" data-toggle="tab"
                  [ngClass]="{ active: index == 4 }">{{ "product.info" | translate }}</a>
              </li>
              <li role="presentation">
                <a href="#salon" (click)="currentsalon()" role="tab" data-toggle="tab"
                  [ngClass]="{ active: index == 5 }">{{ "product.salon" | translate }}</a>
              </li>
            </ul>
            <div class="row">
              <div class="col-xl-4 col-12 pt-4" style="margin-top: -9px">
                <p class="border-bottom font-weight-bold">
                  {{ "product.selectProduct" | translate }}
                  <!-- <span *ngIf="isAddMode"  class="custom-error-star">*</span> -->
                </p>
                <input formControlName="image_path_name" *ngIf="isAddMode" id="file" type="file"
                  class="form-control small-control" accept="image/x-png,image/jpeg" (change)="onFileChange($event)"
                  [ngClass]="{
                    'is-invalid':
                      submitted && formValidation.image_path_name.errors
                  }" />
                <div *ngIf="submitted && formValidation.image_path_name.errors" class="invalid-feedback">
                  <div *ngIf="formValidation.image_path_name.errors.required">
                    product image is required
                  </div>
                </div>
                <!-- <label for="file"  *ngIf="imageUrl">
                <img src="{{imageUrl}}" >
            </label>    -->

                <div class="tags-list-img mb-3 product-img-main" *ngIf="!isAddMode">
                  <input style="display: none" formControlName="image_path_name" id="file" type="file"
                    (change)="onFileChange($event)" />
                  <label for="file" *ngIf="imageUrl">
                    <img src="{{ imageUrl }}" />
                  </label>
                  <label for="file" *ngIf="!imageUrl">
                    <img src="{{ baseURL }}{{ imgurl }}" />
                  </label>
                  <!-- <img src="images/product.png" class="product-img"> -->
                </div>
                <div class="row">
                  <div class="col-6">
                    <label>
                      <input type="radio" name="treatment_type"  [checked]="formValidation.treatment_type.value == 0" formControlName="treatment_type" [value]="0" id="0">
                      <span>Treatment at Home</span>
                    </label>
                  </div>
                  <div class="col-6">
                    <label>
                      <input type="radio" name="treatment_type"  [checked]="formValidation.treatment_type.value == 1" formControlName="treatment_type" [value]="1" id="1">
                      <span>Treatment in Salon</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-xl-8 col-12">
                <div class="tab-content pt-3">
                  <div class="tab-pane tab-in" [ngClass]="{ active: index == 1 }" id="Health">
                    <div class="row">
                      <div class="col-12">
                        <div class="row m-0">
                          <div class="col-md-6 col-sm-6 col-12 mb-3 pl-sm-0 product-checkbox border-right m-border-0">
                            <p class="border-bottom font-weight-bold">
                              {{ "healthPro.typeHair" | translate }}
                            </p>
                            <div class="product-scroll">
                              <div class="checkbox-box flex-radio flex-checkbox" *ngFor="
                                  let health_hair of hairList;
                                  let h = index
                                ">
                                <p class="m-0 radio-title font-weight-normal">
                                  {{ 'hairList.'+health_hair.hair_name | translate }}
                                </p>
                                <div class="custom-control custom-checkbox">
                                  <input class="custom-control-input" type="checkbox" name="healthhaircheckbox"
                                    id="{{ health_hair.id }}" [checked]="
                                      typeOfHairArraychecked.includes(
                                        health_hair.id
                                      )
                                        ? true
                                        : false
                                    " (change)="
                                      typeOfHairChange(
                                        h,
                                        health_hair.id,
                                        $event,
                                        health_hair.hair_name
                                      )
                                    " />
                                  <label class="custom-control-label" for="{{ health_hair.id }}">
                                  </label>
                                </div>
                              </div>
                            </div>
                           
                            <!-- <span  *ngIf="!typeofhair_checked" class="custom-error">{{typeOfHairErrorMsg}}</span>  -->
                          </div>
                          <div class="col-md-6 col-sm-6 col-12 mb-3">
                            <p class="border-bottom font-weight-bold">
                              {{ "healthPro.skin" | translate }}
                            </p>
                            <div class="product-scroll" *ngIf="isAddMode">
                              <div class="checkbox-box flex-radio" *ngFor="let skin of skinList; let i = index">
                                <p class="m-0 radio-title">
                                  {{ 'skinList.'+skin.skin_name | translate }}
                                </p>
                                <div class="custom-control custom-radio" *ngFor="
                                    let skintypevalue of skintypevalue;
                                    let s = index
                                  ">
                                  <input type="checkbox" class="custom-control-input"
                                    name="{{ skin.skin_name }}-{{ i }}" id="{{ skintypevalue }}-{{ i }}" (change)="
                                      onChangeSkin(
                                        i,
                                        skin.id,
                                        $event,
                                        skintypevalue,
                                        skin.skin_name
                                      )
                                    " />
                                  <label class="custom-control-label" for="{{ skintypevalue }}-{{ i }}">
                                    <span>{{ skintypevalue }}</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="product-scroll" *ngIf="!isAddMode">
                              <div class="checkbox-box flex-radio" *ngFor="let skin of skinList; let i = index">
                                <p class="m-0 radio-title">
                                  {{ 'skinList.'+skin.skin_name | translate }}
                                </p>
                                <div class="custom-control custom-radio" *ngFor="
                                    let skintypevalue of skintypevalue;
                                    let s = index
                                  ">
                                  <input type="checkbox" class="custom-control-input"
                                    name="{{ skin.skin_name }}-{{ i }}" id="{{ skintypevalue }}-{{ i }}"
                                    value="{{ skin.id }}-{{ skintypevalue }}" (change)="
                                      onChangeSkin(
                                        i,
                                        skin.id,
                                        $event,
                                        skintypevalue,
                                        skin.skin_name
                                      )
                                    " [checked]="
                                      skinNewArray.includes(
                                        skin.id + '-' + skintypevalue
                                      )
                                        ? true
                                        : false
                                    " />
                                  <label class="custom-control-label" for="{{ skintypevalue }}-{{ i }}">
                                    <span>{{ skintypevalue }}</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <ul class="nav nav-tabs justify-content-end nav-tabs-dropdown main-tab" role="tablist"
                              style="padding-top: 50px">
                              <li role="presentation">
                                <button (click)="nextbeauty()" href="#beauty" class="btn-default" role="tab"
                                  data-toggle="tab">
                                  {{ "nextBtn" | translate }}
                                </button>
                              </li>
                            </ul>
                            <!-- <span  *ngIf="!skintype_checked" class="custom-error">{{typeOfSkinErrorMsg}}</span>   -->
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane tab-in" [ngClass]="{ active: index == 2 }" id="beauty">
                    <div class="row">
                      <div class="col-12">
                        <div class="row m-0">
                          <div class="col-md-6 col-sm-6 col-12 mb-3 pl-sm-0 product-checkbox border-right m-border-0">
                            <p class="border-bottom font-weight-bold">
                              {{ "product.selectTag" | translate }}
                            </p>
                            <div class="product-scroll">
                              <div id="container" class="example example_markup">
                                <div class="checkbox-box flex-radio flex-checkbox mt-0 mb-3"
                                  *ngFor="let tag of tagList; let i = index">
                                  <p class="m-0 radio-title font-weight-normal">
                                    {{ tag.product_tag_name }}
                                  </p>
                                  <div class="custom-control custom-checkbox">
                                    <input class="custom-control-input" type="checkbox" name="tagbox" id="{{ tag.id }}"
                                      value="{{ tag.id }}" [checked]="
                                        tagarray.includes(tag.id) ? true : false
                                      " (change)="
                                        onChangeTag(
                                          i,
                                          tag.id,
                                          $event,
                                          tag.product_tag_name
                                        )
                                      " />
                                    <label class="custom-control-label" for="{{ tag.id }}">
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <span  *ngIf="!tag_checked" class="custom-error">{{tagErrorMsg}}</span>   -->
                          </div>
                          <div class="col-md-6 col-sm-6 col-12 mb-3">
                            <p class="border-bottom font-weight-bold">
                              {{ "filter" | translate }}
                            </p>
                            <div class="row">
                              <div class="col-sm-12">
                                <div>
                                  <label>
                                    {{ "hairLength" | translate }}
                                  </label>
                                  <div id="container" class="example example_markup filter_checkbox row m-0">
                                    <div
                                      class="p-0 checkbox-box flex-radio flex-checkbox mt-0 mb-3 col-4 col-sm-6 col-xl-4"
                                      *ngFor="
                                        let length of hair_length;
                                        let i = index
                                      ">
                                      <p class="m-0 radio-title font-weight-normal mr-2">
                                        {{ 'hair_length.'+length.length | translate }}
                                      </p>
                                      <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" name="hair_length"
                                          id="{{ length.length }}" value="{{ length.id }}" [checked]="
                                            hairLengthArray.includes(length.id)
                                              ? true
                                              : false
                                          " (change)="
                                            onChangeHairLength(
                                              i,
                                              length.id,
                                              $event
                                            )
                                          " />
                                        <label class="custom-control-label" for="{{ length.length }}">
                                        </label>
                                      </div>
                                    </div>
                                    <!-- <span  *ngIf="!hairLength_checked" class="custom-error">{{hairLengthErrorMsg}}</span>  -->
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div>
                                  <label>
                                    {{ "hairType" | translate }}
                                  </label>
                                  <div id="container" class="example example_markup filter_checkbox row m-0">
                                    <div
                                      class="p-0 checkbox-box flex-radio flex-checkbox mt-0 mb-3 col-4 col-sm-6 col-xl-4"
                                      *ngFor="
                                        let type of hair_type;
                                        let j = index
                                      ">
                                      <p class="m-0 radio-title font-weight-normal mr-2">
                                        {{ 'hair_type.'+type.type | translate }}
                                      </p>
                                      <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" name="hair_type"
                                          id="{{ type.type }}" value="{{ type.id }}" [checked]="
                                            hairTypehArray.includes(type.id)
                                              ? true
                                              : false
                                          " (change)="
                                            onChangeHairType(j, type.id, $event)
                                          " />
                                        <label class="custom-control-label" for="{{ type.type }}">
                                        </label>
                                      </div>
                                    </div>
                                    <!-- <span  *ngIf="!hairType_checked" class="custom-error">{{hairTypeErrorMsg}}</span>  -->
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div>
                                  <label>
                                    {{ "haircolor" | translate }}
                                  </label>
                                  <div id="container" class="example example_markup filter_checkbox row m-0">
                                    <div
                                      class="p-0 checkbox-box flex-radio flex-checkbox mt-0 mb-3 col-4 col-sm-6 col-xl-4"
                                      *ngFor="
                                        let color of hair_color;
                                        let c = index
                                      ">
                                      <p class="m-0 radio-title font-weight-normal mr-2">
                                        {{ 'hair_color.'+color.color | translate }}
                                      </p>
                                      <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" name="hair_color"
                                          id="{{ color.color }}" value="{{ color.id }}" [checked]="
                                            hairColorArray.includes(color.id)
                                              ? true
                                              : false
                                          " (change)="
                                            onChangeHairColor(
                                              c,
                                              color.id,
                                              $event
                                            )
                                          " />
                                        <label class="custom-control-label" for="{{ color.color }}">
                                        </label>
                                      </div>
                                    </div>
                                    <!-- <span  *ngIf="!hairColor_checked" class="custom-error">{{hairColorErrorMsg}}</span>  -->
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div>
                                  <label>
                                    {{ "age" | translate }}
                                  </label>
                                  <div id="container" class="example example_markup filter_checkbox row m-0">
                                    <div
                                      class="p-0 checkbox-box flex-radio flex-checkbox mt-0 mb-3 col-4 col-sm-6 col-xl-4"
                                      *ngFor="let age of age; let a = index">
                                      <p class="m-0 radio-title font-weight-normal mr-2">
                                        {{ 'ageList.'+age.age | translate }}
                                      </p>
                                      <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" name="age"
                                          id="{{ age.age }}" value="{{ age.id }}" [checked]="
                                            ageArray.includes(age.id)
                                              ? true
                                              : false
                                          " (change)="
                                            onChangeAge(a, age.id, $event)
                                          " />
                                        <label class="custom-control-label" for="{{ age.age }}">
                                        </label>
                                      </div>
                                    </div>
                                    <!-- <span  *ngIf="!age_checked" class="custom-error">{{ageErrorMsg}}</span>  -->
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ul style="padding-top: 30px !important"
                              class="nav nav-tabs justify-content-end nav-tabs-dropdown main-tab" role="tablist">
                              <li role="presentation">
                                <button class="btn btn-default btn-secondary mr-2" (click)="prevbeauty()" role="tab"
                                  data-toggle="tab" href="#health">
                                  {{ "backBtn" | translate }}
                                </button>
                              </li>
                              <li role="presentation">
                                <button (click)="nextseason()" href="#season" class="btn-default" role="tab"
                                  data-toggle="tab">
                                  {{ "nextBtn" | translate }}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane tab-in" [ngClass]="{ active: index == 3 }" id="season">
                    <div class="row">
                      <div class="col-12">
                        <div class="row m-0">
                          <div class="col-md-12 col-sm-12 col-12 mb-3 pl-sm-0 product-checkbox border-right m-border-0">
                            <p class="border-bottom font-weight-bold">
                              {{ "product.typeofSeason" | translate }}
                            </p>
                            <div class="product-scroll">
                              <div class="checkbox-box flex-radio flex-checkbox" *ngFor="
                                let season of seasonList;
                                let h = index
                              ">
                                <p class="m-0 radio-title font-weight-normal">
                                  {{ 'seasonList.'+season.name | translate }}
                                </p>
                                <div class="custom-control custom-checkbox">
                                  <input class="custom-control-input" type="checkbox" name="seasoncheckbox{{h}}"
                                    id="season{{ season.id }}" [checked]="
                                    seasonListArray.includes(
                                      season.id
                                    )
                                      ? true
                                      : false
                                  " (change)="
                                  onChangeSeason(
                                      h,
                                      season.id,
                                      $event,
                                      season.name
                                    )
                                  " />
                                  <label class="custom-control-label" for="season{{ season.id }}">
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- <span  *ngIf="!typeofhair_checked" class="custom-error">{{typeOfHairErrorMsg}}</span>  -->
                            <ul class="nav nav-tabs justify-content-end nav-tabs-dropdown main-tab" role="tablist"
                              style="padding-top: 50px">
                              <button (click)="index=2" href="#beauty" class="btn-default btn-secondary mr-2" role="tab"
                                data-toggle="tab">
                                {{ "backBtn" | translate }}
                              </button>
                              <li role="presentation">
                                <button (click)="index=4" href="#info" class="btn-default" role="tab" data-toggle="tab">
                                  {{ "nextBtn" | translate }}
                                </button>

                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane tab-in" [ngClass]="{ active: index == 4 }" id="info">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
                            <label>
                              {{ "product.productName" | translate
                              }}<span class="custom-error-star">*</span>
                            </label>
                          </div>
                          <div class="col-12 col-sm-12 col-xl-9">
                            <div class="form-group input-group">
                              <input type="text" formControlName="product_name" class="form-control small-control"
                                placeholder=" {{
                                  'product.productName' | translate
                                }}" maxlength="50" [ngClass]="{
                                  'is-invalid':
                                    submitted &&
                                    formValidation.product_name.errors
                                }" />
                              <div *ngIf="
                                  submitted &&
                                  formValidation.product_name.errors
                                " class="invalid-feedback">
                                <div *ngIf="
                                    formValidation.product_name.errors.required
                                  ">
                                  {{ "product.productMsg" | translate }}
                                </div>
                                <!-- <div *ngIf="formValidation.product_name.errors.pattern"> Product Name should contain only alphabate (a-z)</div>  pattern="^[a-zA-Z_\- ]+$" -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
                            <label>
                              {{ "product.productBrand" | translate
                              }}<span class="custom-error-star">*</span>
                            </label>
                          </div>
                          <div class="col-12 col-sm-12 col-xl-9">
                            <div class="form-group input-group">
                              <input type="text" formControlName="product_brand" class="form-control small-control"
                                placeholder=" {{
                                  'product.productBrand' | translate
                                }}" maxlength="50" [ngClass]="{
                                  'is-invalid':
                                    submitted &&
                                    formValidation.product_brand.errors
                                }" />
                              <div *ngIf="
                                  submitted &&
                                  formValidation.product_brand.errors
                                " class="invalid-feedback">
                                <div *ngIf="
                                    formValidation.product_brand.errors.required
                                  ">
                                  Product Brand Name is required
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
                            <label>
                              {{ "product.productPrice" | translate
                              }}<span class="custom-error-star">*</span>
                            </label>
                          </div>
                          <div class="col-12 col-sm-12 col-xl-9">
                            <div class="form-group input-group">
                              <input type="text" formControlName="product_price" class="form-control small-control"
                                placeholder="{{
                                  'product.productPrice' | translate
                                }}" maxlength="10" [ngClass]="{
                                  'is-invalid':
                                    submitted &&
                                    formValidation.product_price.errors
                                }" />
                              <div *ngIf="
                                  submitted &&
                                  formValidation.product_price.errors
                                " class="invalid-feedback">
                                <div *ngIf="
                                    formValidation.product_price.errors.required
                                  "></div>
                                <div *ngIf="
                                    formValidation.product_price.errors.pattern
                                  ">
                                  {{ "product.pricedigit" | translate }}
                                </div>
                                <!--pattern="^[\+\d]?(?:[\d-.\s()]*)$" -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
                            <label>
                              {{ "product.description" | translate }}
                            </label>
                          </div>
                          <div class="col-12 col-sm-12 col-xl-9">
                            <div class="form-group input-group">
                              <textarea formControlName="product_description" class="form-control" placeholder=" {{
                                  'product.description' | translate
                                }}" rows="5"></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
                            <label>
                              {{ "product.dosage" | translate }}
                            </label>
                          </div>
                          <div class="col-12 col-sm-12 col-xl-9">
                            <div class="form-group input-group">
                              <input type="text" formControlName="product_dosage" class="form-control small-control"
                                placeholder="{{ 'product.dosage' | translate }}" maxlength="100" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul style="padding-top: 30px !important"
                      class="nav nav-tabs justify-content-end nav-tabs-dropdown main-tab" role="tablist">
                      <li role="presentation">
                        <button class="btn btn-default btn-secondary mr-2" (click)="index=3" role="tab"
                          data-toggle="tab" href="#season">
                          {{ "backBtn" | translate }}
                        </button>
                      </li>
                      <button (click)="index=5" href="#salon" class="btn-default" role="tab" data-toggle="tab">
                        {{ "nextBtn" | translate }}
                      </button>
                      <!-- <li role="presentation">
                        <button class="btn btn-default mr-2" type="submit">Save</button>
                      </li>
                      <li role="presentation">
                        <button class="btn btn-default btn-secondary" routerLink="/productList">Cancel</button>
                      </li> -->
                      <!-- <li role="presentation">
                        <button (click)="nextinfo()" href="#salon" class="btn-default" role="tab"
                          data-toggle="tab">Next</button>
                      </li> -->
                    </ul>
                  </div>
                  <div class="tab-pane tab-in" [ngClass]="{ active: index == 5 }" id="salon">
                    <div class="row">
                      <div class="col-12">
                        <div class="row m-0">
                          <div class="col-md-12 col-sm-12 col-12 mb-3 pl-sm-0 product-checkbox">
                            <div class="row m-0 border-bottom pb-2">
                              <div class="col-6 pl-0">
                                <p class="font-weight-bold mb-0 mt-2">
                                  {{ "product.salon" | translate }}
                                </p>
                              </div>
                              <div class="col-6 text-right pr-0">
                                <button type="button" class="btn btn-default" (click)="getslonList()"
                                  data-toggle="modal" data-target="#exampleModalCenter">
                                  {{ "product.assignSalon" | translate }}
                                </button>
                              </div>
                            </div>
                            <div class="product-scroll removeScroll">
                              <div class="checkbox-box flex-radio flex-checkbox" *ngFor="
                                  let saloon of salonDisplayArray;
                                  let s = index
                                ">
                                <p class="m-0 radio-title font-weight-normal">
                                  {{ saloon.name | titlecase }}
                                </p>
                                <div class="custom-control custom-checkbox">
                                  <input class="custom-control-input" type="checkbox" name="saloncheckbox"
                                    id="{{ saloon.id }}--{{ s }}" checked="true" disabled />
                                  <label class="custom-control-label" for="{{ saloon.id }}--{{ s }}">
                                  </label>
                                </div>
                              </div>
                            </div>
                            <ul class="nav nav-tabs justify-content-end nav-tabs-dropdown main-tab" role="tablist"
                              style="padding-top: 50px">
                              <li role="presentation">
                                <button class="btn btn-default btn-secondary mr-2" (click)="index=4" role="tab"
                                  data-toggle="tab" href="#info">
                                  {{ "backBtn" | translate }}
                                </button>
                              </li>
                              <li role="presentation">
                                <button class="btn btn-default mr-2" type="submit">
                                  {{ "saveBtn" | translate }}
                                </button>
                              </li>
                              <li role="presentation">
                                <button class="btn btn-default btn-secondary" routerLink="/productList">
                                  {{ "cancel" | translate }}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-container *ngIf="addsalonFlag">
  <div id="exampleModalCenter" class="modal fade">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row col-lg-10 p-0 mx-auto">
            <div class="col-12 col-sm-4 d-flex align-items-center">
              <label for="Search">{{
                "product.searchsalon" | translate
                }}</label>
            </div>
            <div class="col-12 col-sm-8">
              <div class="input-group search-group-form">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fas fa-search"></i>
                  </span>
                </div>
                <input type="text" class="form-control small-control" placeholder="{{ 'search' | translate }}"
                  aria-describedby="basic-addon1" #searchInput (change)="searchBySalon(searchInput.value)"
                  name="search" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body text-center">
          <div class="row col-lg-10 p-0 mx-auto">
            <div class="col-md-6 col-sm-6 col-12 pl-sm-0 product-checkbox mb-2"
              *ngFor="let salon of salonList; let i = index">
              <div class="checkbox-box flex-radio flex-checkbox mt-0">
                <p class="m-0 radio-title font-weight-normal">
                  {{ salon.salonName }}
                </p>
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" type="checkbox" name="{{ salon.id }}" id="{{ salon.id }}"
                    (change)="
                      onChangesalon(i, salon.id, $event, salon.salonName)
                    " [checked]="salonArray.includes(salon.id) ? true : false" />
                  <label class="custom-control-label" for="{{ salon.id }}">
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button class="btn btn-default" data-dismiss="modal" (click)="addSaloonList()" type="button">
            {{ "product.add" | translate }}
          </button>
          <button class="btn btn-default btn-secondary" data-dismiss="modal" type="button">
            {{ "cancel" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>