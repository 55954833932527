import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SalonService } from '../dashboard/salon.service';
import { AlertService } from 'src/app/shared/commons/alert.service';
import AppConstants from 'src/app/shared/commons/app.constants';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { valHooks } from 'jquery';
@Component({
  selector: 'app-add-edit-salon',
  templateUrl: './add-edit-salon.component.html',
  styleUrls: ['./add-edit-salon.component.css'],
})
export class AddEditSalonComponent implements OnInit {
  pipe = new DatePipe('en-US');
  successMsg: any;
  msg: any;
  submitted = false;
  index = 1;
  baseURL = AppConstants.SERVICE_BASE_URL;
  private image: any;
  imageUrl: any;
  imgurl: any;
  userId: any;
  data: any;
  editData: any;
  userdata: any;
  request: any = {};
  loginUserName: any = '';
  isAddMode: boolean = true;
  errorDialog: any;
  successDialog: any;
  somethingWrong: any;
  userDetail={
    "salon_id":"",
    "password": ""
  }
  confirmPass="";
  slaonAdminMail="";
  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    public router: Router,
    public SalonService: SalonService,
    public alertService: AlertService
  ) {}

  salonform = new FormGroup({});
  /*
   * validation form
   */
  createForm() {
    const zipregex = '^[0-9]{6}(?:-[0-9]{4})?$';
    const webReg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.salonform = this.formBuilder.group({
      salonName: ['', Validators.required],
      salon_admin_email: ['', Validators.required],
      password: [
        '',
        [this.isAddMode ? Validators.required : Validators.nullValidator],
      ],
      salon_admin_name: ['', Validators.required],
      salon_admin_phone: ['', Validators.required],
      userName: [''],
      salon_admin_surname: ['', Validators.required],
      salon_address: ['', Validators.required],
      salon_zipcode: ['', [Validators.required, Validators.pattern(zipregex)]],
      salon_city: ['', Validators.required],
      salon_region: ['', Validators.required],
      salon_vat: ['', Validators.required],
      salon_cf: ['', Validators.required],
      salon_pec: ['', Validators.required],
      salon_bank_account_owner: [''],
      salon_bank: [''],
      salon_bankaccountid: [''],
      salon_logo: [
        '',
        [this.isAddMode ? Validators.required : Validators.nullValidator],
      ],
      salon_facebookpage: [''],
      salon_instagramaccount: [''],
      salon_linkedinaccount: [''],
      salon_website: ['', Validators.pattern(webReg)],
      salon_incloudapikey: [''],
      starting_contract: ['', Validators.required],
      ending_contract: ['', Validators.required],
      salon_sms_sender:['',Validators.required],
      staffs:this.formBuilder.array([this.newStaff()])
      
    });
  }

 get staffs():FormArray{
    return this.salonform.get('staffs') as FormArray
  }

  newStaff():FormGroup{
    return this.formBuilder.group({
      _id:'',
      name:'',
      surname:'',
      phone_no:''
    })
  }
  addStaff(){
    this.staffs.push(this.newStaff())
  }
  removeStaff(i:number){
    this.staffs.removeAt(i)
  }
  get formValidation() {
    return this.salonform.controls;
  }
  ngOnInit(): void {
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.loginUserName = localStorage.getItem('user');
    this.loginUserName = this.loginUserName.replace(/^"(.*)"$/, '$1');
    this.userdata = localStorage.getItem('user');
    this.userId = localStorage.getItem('edit_id');
    if (this.userId != null) {
      this.isAddMode = false;
      this.editSalon(this.userId);
    }
    this.createForm();
  }

  /*
   * use for Edit salon detail
   */
  editSalon(id: any) {
    this.userDetail.salon_id = this.userId;
    this.SalonService.getById(id).subscribe(
      (data) => {
        this.editData = data;

        this.imgurl = data['data']['salon_logo_path_name'];
        this.formValidation.salonName.setValue(this.editData['data'].salonName);
        this.formValidation.salon_admin_name.setValue(
          this.editData['data'].salon_admin_name
        );
        this.formValidation.salon_admin_phone.setValue(
          this.editData['data'].salon_admin_phone
        );
        this.formValidation.salon_admin_email.setValue(
          this.editData['data'].salon_admin_email
        );
        this.slaonAdminMail = this.editData['data'].salon_admin_email;
        this.formValidation.salon_pec.setValue(this.editData['data'].salon_pec);
        this.formValidation.salon_bank_account_owner.setValue(
          this.editData['data'].salon_bank_account_owner
        );
        this.formValidation.salon_bank.setValue(
          this.editData['data'].salon_bank
        );
        this.formValidation.salon_bankaccountid.setValue(
          this.editData['data'].salon_bankaccountid
        );
        this.formValidation.salon_admin_surname.setValue(
          this.editData['data'].salon_admin_surname
        );
        this.formValidation.salon_facebookpage.setValue(
          this.editData['data'].salon_facebookpage
        );
        this.formValidation.salon_address.setValue(
          this.editData['data'].salon_address
        );
        this.formValidation.salon_instagramaccount.setValue(
          this.editData['data'].salon_instagramaccount
        );
        this.formValidation.salon_zipcode.setValue(
          this.editData['data'].salon_zipcode
        );
        this.formValidation.salon_linkedinaccount.setValue(
          this.editData['data'].salon_linkedinaccount
        );
        this.formValidation.salon_city.setValue(
          this.editData['data'].salon_city
        );
        this.formValidation.salon_website.setValue(
          this.editData['data'].salon_website
        );
        this.formValidation.salon_region.setValue(
          this.editData['data'].salon_region
        );
        this.formValidation.salon_incloudapikey.setValue(
          this.editData['data'].salon_incloudapikey
        );
        this.formValidation.salon_sms_sender.setValue(
          this.editData['data'].salon_sms_sender
        )
        if(this.editData['data'].staffs.length>0){
          for(let i=1;i<this.editData['data'].staffs.length;i++){
            
             this.staffs.push( this.newStaff());
             //delete this.editData['data'].staffs[i]._id;
            
          }
          this.staffs.setValue(this.editData['data'].staffs)
        }
        
        // console.log('staffs',this.formValidation.staffs.value);
        this.formValidation.salon_vat.setValue(this.editData['data'].salon_vat);
        let sdate = this.pipe.transform(
          this.editData['data'].starting_contract,
          'yyyy-MM-dd'
        );
        let edate = this.pipe.transform(
          this.editData['data'].ending_contract,
          'yyyy-MM-dd'
        );
        this.formValidation.starting_contract.setValue(sdate);
        this.formValidation.ending_contract.setValue(edate);
        this.formValidation.salon_cf.setValue(this.editData['data'].salon_cf);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        // this.alertService.showDangerAlert('Error', 'Something went wrong while adding an salon detail.');
      }
    );
  }

  onSubmit() {
    this.formValidation.userName.setValue(this.loginUserName);
    // console.log(this.salonform.controls['starting_contract'].value);
    let sDate = this.salonform.controls['starting_contract'].value;
    let eDate = this.salonform.controls['ending_contract'].value;
    if (eDate < sDate) {
      let startValidation = this.translateService.instant('startvalidation');
      this.alertService.showDangerAlert(this.errorDialog, startValidation);
      return;
    }

    let formData = new FormData();
    formData.append('salon_logo', this.image);
    this.request.userName = this.loginUserName;
    this.request.salonName = this.salonform.controls['salonName'].value;
    this.request.salon_pec = this.salonform.controls['salon_pec'].value;
    this.request.salon_admin_email = this.salonform.controls[
      'salon_admin_email'
    ].value;
    this.request.salon_bank_account_owner = this.salonform.controls[
      'salon_bank_account_owner'
    ].value;
    this.request.password = this.salonform.controls['password'].value;
    this.request.salon_bank = this.salonform.controls['salon_bank'].value;
    this.request.salon_admin_name = this.salonform.controls[
      'salon_admin_name'
    ].value;
    this.request.salon_bankaccountid = this.salonform.controls[
      'salon_bankaccountid'
    ].value;
    this.request.salon_admin_surname = this.salonform.controls[
      'salon_admin_surname'
    ].value;
    this.request.salon_admin_phone = this.salonform.controls[
      'salon_admin_phone'
    ].value;
    this.request.salon_facebookpage = this.salonform.controls[
      'salon_facebookpage'
    ].value;
    this.request.salon_address = this.salonform.controls['salon_address'].value;
    this.request.salon_instagramaccount = this.salonform.controls[
      'salon_instagramaccount'
    ].value;
    this.request.salon_zipcode = this.salonform.controls['salon_zipcode'].value;
    this.request.salon_linkedinaccount = this.salonform.controls[
      'salon_linkedinaccount'
    ].value;
    this.request.salon_sms_sender = this.salonform.controls[
      'salon_sms_sender'
    ].value;
    this.request.salon_city = this.salonform.controls['salon_city'].value;
    this.request.salon_website = this.salonform.controls['salon_website'].value;
    this.request.salon_region = this.salonform.controls['salon_region'].value;
    this.request.salon_incloudapikey = this.salonform.controls[
      'salon_incloudapikey'
    ].value;
    this.request.salon_vat = this.salonform.controls['salon_vat'].value;
    this.request.starting_contract = this.salonform.controls[
      'starting_contract'
    ].value;
    this.request.ending_contract = this.salonform.controls[
      'ending_contract'
    ].value;
    this.request.salon_cf = this.salonform.controls['salon_cf'].value;
    if(this.salonform.controls['staffs'].value.length>0 && this.isAddMode){
      for(let i=0;i<this.salonform.controls['staffs'].value.length;i++){
        delete this.salonform.controls['staffs'].value[i]._id;
      }
    }
    this.request.staffs = this.salonform.controls['staffs'].value;
    //console.log(JSON.stringify(this.request));
    formData.append('request', JSON.stringify(this.request));
    this.submitted = true;
    if (this.salonform.invalid) {
      return;
    }

    if (this.isAddMode) {
      this.createUser(formData);
    } else {
      this.updateUser(formData);
      if(this.index==1){
        this.index=2;
      }
      if(this.index==3){
        this.router.navigate(['dashboard']);
      }
    }
  }

  addSalone(){
    this.index = 2;
  }

  nextSalone(){
    this.submitted = true;
    if (this.salonform.invalid) {
      return;
    }
    this.index = 3;
  }

  saveCredential(f:any){
    if(f.invalid){
      return;
    }
    this.SalonService.changePassword(this.userDetail).subscribe((res)=>{
      if(res['is_error']==false){
        this.alertService.showSuccessAlert(this.successDialog,res['message']);
        this.index = 3;
      }else{
        this.alertService.showDangerAlert(this.errorDialog, res['message']);
      }
    },(error: HttpErrorResponse) => {
      // console.log(error.name + ' ' + error.message);
      this.alertService.showDangerAlert(
        this.errorDialog,
        this.somethingWrong
      );
    }
  );  
  }
  saveStaff(){
    
  }
  createUser(formData: any) {
    this.SalonService.add(formData).subscribe(
      (data) => {
        // console.log(data);
        if (data.is_error == false) {
          this.router.navigate(['dashboard']);
          let userdetailMsg = this.translateService.instant('userMsg');
          this.alertService.showSuccessAlert(this.successDialog, userdetailMsg);
        } else {
          this.alertService.showDangerAlert(this.errorDialog, data['message']);
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  updateUser(formData: any) {
    this.SalonService.update(this.userId, formData).subscribe(
      (data) => {
        // console.log(data);
       // this.router.navigate(['dashboard']);
        this.msg = this.translateService.instant('successuser');
        // console.log(this.msg);
        this.alertService.showSuccessAlert(this.successDialog, this.msg);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }

  clear() {
    this.router.navigate(['dashboard']);
    // this.salonform.reset();
  }

  onFileChange($event: any) {
    let imagevalidation = this.translateService.instant('imageValidation');
    const files = $event.target.files || $event.srcElement.files;
    const file = files[0];
    const type = this.checkExtension(file);
    if (file && type != -1) {
      this.image = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        this.imageUrl = event.target.result;
      };
    } else {
      this.alertService.showDangerAlert(this.errorDialog, imagevalidation);
    }
  }

  checkExtension(file: any) {
    let mimeType = ['image/png', 'image/jpeg', 'image/jpg'];
    return mimeType.indexOf(file.type);
  }
}
function date_format(arg0: string, arg1: any) {
  throw new Error('Function not implemented.');
}

function strtotime(starting_contract: any): any {
  throw new Error('Function not implemented.');
}
