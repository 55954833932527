<app-sidebar></app-sidebar>
<div class="body-padding-top">
    <div class="container-fluid">
        <div class="row pt-3">
            <div class="col-6">
              <h1 class="page-title">
             {{'image.imagelist'|translate}}
              </h1>
            </div>
            <div class="col-6 text-right">
                <button class="btn btn-default" (click)="addImage()" >{{'image.addNewImage' |translate}}</button>
            </div>
          </div>
      <div class="tab-box tab-box-vertical pt-3" role="tabpanel">
        <!-- <button class="btn btn-default">Add New Image</button> -->
        <div class="row">
          <div class="col-sm-12">
            <ul class="nav nav-tabs nav-justified nav-tabs-dropdown main-tab" role="tablist">
              <li role="presentation">
                <a href="#celebrityfemale" aria-controls="celebrityfemale" role="tab" data-toggle="tab" class="active">{{'tag.celebrityfemale'|translate}}</a>
              </li>
              <li role="presentation">
                <a href="#celebritymale" aria-controls="celebritymale" role="tab" data-toggle="tab">{{'tag.celebritymale'|translate}}</a>
              </li>
              <li role="presentation">
                <a href="#peoplefemale" aria-controls="peoplefemale" role="tab" data-toggle="tab">{{'tag.peoplefemale'|translate}}</a>
              </li>
              <li role="presentation">
                <a href="#peoplemale" aria-controls="peoplemale" role="tab" data-toggle="tab">{{'tag.peoplemale'|translate}}</a>
              </li>
              <li role="presentation">
                <a href="#designfemale" aria-controls="designfemale" role="tab" data-toggle="tab">{{'tag.designfemale'|translate}}</a>
              </li>
              <li role="presentation">
                <a href="#designmale" aria-controls="designmale" role="tab" data-toggle="tab">{{'tag.designmale'|translate}}</a>
              </li>
            </ul> 
            <div class="tab-content pt-3">
              <div role="tabpanel1" class="tab-pane tab-in active" id="celebrityfemale">
                <div class="row">
                  <div class="col-xl-2 col-md-3 col-sm-4 col-6" *ngFor="let image of celebrityfemaleList">
                  <div class="tags-list-img">
                      <img src="{{baseURL}}{{image.image_path_name}}"  (click)="editImage(image.id)">
                      <div class="images-btn">
                        <button data-toggle="modal"  data-target="#viewimageModal" (click)="showImageModal(image.image_path_name)">
                          <i class="fal fa-eye"></i>
                        </button>
                        <button  data-toggle="modal" data-target="#imageModal" (click)="showModal(image.id)">
                          <i class="fal fa-trash-alt"></i>
                        </button>
                      </div>
                  </div> 
                  </div>                
                </div>
              </div>
              <div role="tabpanel" class="tab-pane" id="celebritymale">
                <div class="row">
                  <div class="col-xl-2 col-md-3 col-sm-4 col-6" *ngFor="let image of celebritymaleList">
                   <div class="tags-list-img">
                      <img src="{{baseURL}}{{image.image_path_name}}" (click)="editImage(image.id)">
                      <div class="images-btn">
                        <button data-toggle="modal"  data-target="#viewimageModal" (click)="showImageModal(image.image_path_name)">
                          <i class="fal fa-eye"></i>
                        </button>
                        <button  data-toggle="modal" data-target="#imageModal" (click)="showModal(image.id)">
                          <i class="fal fa-trash-alt"></i>
                        </button>
                      </div>
                  </div>        
                  </div>                
                </div>
              </div>
              <div role="tabpanel" class="tab-pane" id="peoplefemale">
                <div class="row">
                  <div class="col-xl-2 col-md-3 col-sm-4 col-6" *ngFor="let image of peoplefemaleList">
                    <div class="tags-list-img">
                      <img src="{{baseURL}}{{image.image_path_name}}" (click)="editImage(image.id)">
                      <div class="images-btn">
                        <button data-toggle="modal"  data-target="#viewimageModal" (click)="showImageModal(image.image_path_name)">
                          <i class="fal fa-eye"></i>
                        </button>
                        <button  data-toggle="modal" data-target="#imageModal" (click)="showModal(image.id)">
                          <i class="fal fa-trash-alt"></i>
                        </button>
                      </div>
                  </div> 
                  </div>
                                 
                </div>
              </div>
              <div role="tabpanel" class="tab-pane" id="peoplemale">
                <div class="row">
                  <div class="col-xl-2 col-md-3 col-sm-4 col-6" *ngFor="let image of peoplemaleList">
                   <div class="tags-list-img">
                      <img src="{{baseURL}}{{image.image_path_name}}" (click)="editImage(image.id)">
                      <div class="images-btn">
                        <button data-toggle="modal"  data-target="#viewimageModal" (click)="showImageModal(image.image_path_name)">
                          <i class="fal fa-eye"></i>
                        </button>
                        <button  data-toggle="modal" data-target="#imageModal" (click)="showModal(image.id)">
                          <i class="fal fa-trash-alt"></i>
                        </button>
                      </div>
                  </div>                                
                  </div>                
                </div>
              </div>
              <div role="tabpanel" class="tab-pane" id="designfemale">
                <div class="row">
                  <div class="col-xl-2 col-md-3 col-sm-4 col-6" *ngFor="let image of designfemaleList">
                   
                    <div class="tags-list-img">
                      <img src="{{baseURL}}{{image.image_path_name}}" (click)="editImage(image.id)">
                      <div class="images-btn">
                        <button data-toggle="modal"  data-target="#viewimageModal" (click)="showImageModal(image.image_path_name)">
                          <i class="fal fa-eye"></i>
                        </button>
                        <button  data-toggle="modal" data-target="#imageModal" (click)="showModal(image.id)">
                          <i class="fal fa-trash-alt"></i>
                        </button>
                      </div>
                 
                  </div>                 
                  </div>                
                </div>
              </div>
              <div role="tabpanel" class="tab-pane" id="designmale">
                <div class="row">
                  <div class="col-xl-2 col-md-3 col-sm-4 col-6" *ngFor="let image of designmaleList">
                  <div class="tags-list-img">
                      <img src="{{baseURL}}{{image.image_path_name}}" (click)="editImage(image.id)">
                      <div class="images-btn">
                        <button data-toggle="modal"  data-target="#viewimageModal" (click)="showImageModal(image.image_path_name)">
                          <i class="fal fa-eye"></i>
                        </button>
                        <button  data-toggle="modal" data-target="#imageModal" (click)="showModal(image.id)">
                          <i class="fal fa-trash-alt"></i>
                        </button>                   
                  </div> 
                  </div>                 
                  </div>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div id="imageModal" class="modal fade">
    <div class="modal-dialog modal-dialog-centered modal-confirm">
      <div class="modal-content">
        <div class="modal-header flex-column">
          <h4 class="modal-title text-center w-100">
           {{'areYouSure'|translate}}
          </h4>
        </div>
        <div class="modal-body text-center">
          <p class="mb-0">{{'deleteRecord'|translate}}
            </p>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button"  class="btn btn-secondary" data-dismiss="modal">{{'cancel'|translate}}</button>
          <button type="button" (click)="imageDelete(imageDeleteId)" data-dismiss="modal" class="btn btn-danger">{{'delete'|translate}}</button>
        </div>
      </div>
    </div>
  </div> 

  <div class="modal fade" id="viewimageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <button type="button" class="close modal-close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content video-content transparent">
        <div class="modal-body p-0 text-center">
          <img src="{{baseURL}}{{image_path_name}}" class="img-fluid modal-cover"  />       
        </div>
      </div>
    </div>
  </div>