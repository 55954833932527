import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './shared/guard/auth.guard';
import { SalonService } from './components/dashboard/salon.service';
import { SidebarComponent } from './components/sidebar/sidebar/sidebar.component';
import { AddEditSalonComponent } from './components/add-edit-salon/add-edit-salon.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ToastNotificationsModule } from 'ngx-toast-notifications';
import { DataTablesModule } from 'angular-datatables';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FaceshapeComponent } from './components/faceshape/faceshape.component';
import { TagsComponent } from './components/tags/tags.component';
import { TrainerComponent } from './components/trainer/trainer.component';
import { ImagesComponent } from './components/images/images.component';
import { HealthComponent } from './components/health/health.component';
import { AddtagComponent } from './components/tags/addtag/addtag.component';
import { AddimageComponent } from './components/images/addimage/addimage.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AddtrainerComponent } from './components/trainer/addtrainer/addtrainer.component';
import { AddhealthComponent } from './components/health/addhealth/addhealth.component';
import { ProductComponent } from './components/product/product.component';
import { AddeditproductComponent } from './components/product/addeditproduct/addeditproduct.component';
import { ProductTagComponent } from './components/product-tag/product-tag.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TagListComponent } from './components/tags/tag-list/tag-list.component';
import { ProductTagListComponent } from './components/product-tag/product-tag-list/product-tag-list.component';
import { CustomerComponent } from './components/customer/customer.component';
import { OrderComponent } from './components/order/order.component';
import { EditorderComponent } from './components/order/editorder/editorder.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import {environment} from '../environments/environment';
import {MessagingService} from '../app/services/messaging.service';
import { ToastrModule } from 'ngx-toastr';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { CustomerDetailComponent } from './components/customer-detail/customer-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { EffectComponent } from './components/effect/effect.component';
import { AddeffectComponent } from './components/effect/addeffect/addeffect.component';

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};
@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    DashboardComponent,
    SidebarComponent,
    AddEditSalonComponent,
    FaceshapeComponent,
    TagsComponent,
    TrainerComponent,
    ImagesComponent,
    HealthComponent,
    AddtagComponent,
    AddimageComponent,
    AddtrainerComponent,
    AddhealthComponent,
    ProductComponent,
    AddeditproductComponent,
    ProductTagComponent,
    TagListComponent,
    ProductTagListComponent,
    CustomerComponent,
    OrderComponent,
    EditorderComponent,
    NotificationListComponent,
    CustomerDetailComponent,
    PrivacyPolicyComponent,
    EffectComponent,
    AddeffectComponent,
  ],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastNotificationsModule,
    DataTablesModule,
    SlickCarouselModule,
    AutocompleteLibModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ToastrModule.forRoot(),
    NgbModule,
    MatCarouselModule.forRoot()
    
  ],
  providers: [AuthService, AuthGuard, SalonService,MessagingService,AsyncPipe ],
  bootstrap: [AppComponent],
})
export class AppModule {}
