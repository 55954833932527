<header class="header">

  <div class="container-fluid d-flex justify-content-between align-items-center">
    <img src="assets/images/logo.png" class="header-logo" />

    <div class="d-flex align-items-center">
      <a href="javscript:void(0)" routerLink="/notificationList"
        style="margin-right:20px">{{'notification.shownotification' | translate}}</a>
      <button (click)="openSideBar()" class="navbar-toggler d-lg-none" type="button">
        <i class="fas fa-bars bar-icon"></i>
      </button>
      <div class="dropdown mr-2">
        <button class="btn btn-outline-secondary dropdown-toggle px-1 px-sm-2" type="button" id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span *ngIf="langFlag">
            <img src="assets/images/united-kingdom.png" class="mr-2" />
            <span class="d-none d-sm-inline-block"> English </span>
          </span>
          <span *ngIf="!langFlag">
            <img src="assets/images/italy.png" class="mr-2" />
            <span class="d-none d-sm-inline-block"> Italian </span>
          </span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" (click)="changeLang('en')">
            <img src="assets/images/united-kingdom.png" class="mr-2" />
            English
          </a>
          <a class="dropdown-item" (click)="changeLang('it')">
            <img src="assets/images/italy.png" class="mr-2" />
            Italian
          </a>
        </div>
      </div>
      <div class="dropdown">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="fas fa-user mr-2"></span>
          <span class="d-none d-sm-inline-block">{{ userName | titlecase }}</span>

        </button>
        <div class="dropdown-menu mr-3" aria-labelledby="dropdownMenuButton">
          <button class="dropdown-item" (click)="logout()">
            <span class="fas fa-sign-out-alt mr-2"></span>
            {{ "logout" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</header>
<nav class="navbar navbar-expand-md sidebar {{ toggleClass ? 'opensidebar' : '' }}">
  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav flex-column w-100">
      <li class="nav-item" *ngIf="role==2">
        <a class="nav-link" [ngClass]="{ active: index == 1 }" routerLink="/dashboard/">
          <i class="fas fa-calendar-alt mr-2"></i>
          {{ "salon_status" | translate }}
        </a>
      </li>
      <li class="nav-item" *ngIf="role==2">
        <a class="nav-link" [ngClass]="{ active: index == 2 }" routerLink="/faceShapeInfo/">
          <i class="fas fa-cogs mr-2"></i>
          {{ "face_shape_info" | translate }}
        </a>
      </li>
      <li class="nav-item" *ngIf="role==2">
        <a class="nav-link" [ngClass]="{ active: index == 3 }" routerLink="/viewtagList/">
          <i class="fas fa-tag mr-2"></i>
          {{ "tags" | translate }}
        </a>
      </li>
      <li class="nav-item" *ngIf="role==2">
        <a class="nav-link" [ngClass]="{ active: index == 8 }" routerLink="/productTagList/">
          <i class="fas fa-prescription-bottle-alt mr-2"></i>
          {{ "product_tag" | translate }}
        </a>
      </li>
      <li class="nav-item" *ngIf="role==2">
        <a class="nav-link" [ngClass]="{ active: index == 4 }" routerLink="/imageList/">
          <i class="fas fa-image mr-2"></i>
          {{ "images" | translate }}
        </a>
      </li>
      <li class="nav-item" *ngIf="role==2">
        <a class="nav-link" [ngClass]="{ active: index == 11 }" routerLink="/effectList/">
          <i class="fas fa-magic mr-2"></i>
          Effect
        </a>
      </li>
      <li class="nav-item" *ngIf="role==2">
        <a class="nav-link" [ngClass]="{ active: index == 5 }" routerLink="/trainerList/">
          <i class="fas fa-video mr-2"></i>
          {{ "execution_trainer" | translate }}
        </a>
      </li>
     
      <li class="nav-item" *ngIf="role==2">
        <a class="nav-link" [ngClass]="{ active: index == 7 }" routerLink="/healthList/">
          <i class="fas fa-user-md mr-2"></i>
          {{ "health" | translate }}
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ active: index == 6 }" routerLink="/productList/">
          <i class="fas fa-shopping-cart mr-2"></i>
          {{ "products" | translate }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ active: index ==9 }" routerLink="/customerList/">
          <i class="fas fa fa-users mr-2"></i>
          {{ "customerheading" | translate }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ active: index == 10 }" routerLink="/orderList/">
          <i class="fas fa-shopping-cart mr-2"></i>
          {{ "orderheading" | translate }}
        </a>
      </li>
    </ul>
  </div>
</nav>