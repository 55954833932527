import { Injectable } from '@angular/core';
import AppConstants from 'src/app/shared/commons/app.constants';
import { HttpClient} from '@angular/common/http';
// import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class FaceshapeService {

  constructor(public httpClient:HttpClient) { }
  
  getById(id:any) {   
    return this.httpClient.get<any>(AppConstants.SERVICE_BASE_URL + '/api/face_shape/getFace_type/'+id,
     { headers: AppConstants.getHeaders() });
  }

  add(faceShape:any) {      
    return this.httpClient.post<any>(AppConstants.SERVICE_BASE_URL + '/api/face_shape',
    faceShape);
  }
 
  deleteImage(id: any,path:any) {  
    return this.httpClient.post<any>(AppConstants.SERVICE_BASE_URL + '/api/face_shape/removeImages/' + id,
    path);
  }
 
  AddUpdateDescAndLink(id:any,content:any){
    return this.httpClient.post<any>(AppConstants.SERVICE_BASE_URL + '/api/face_shape/updateDescription_Link/' + id,
    content);
  }

}
