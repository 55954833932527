import { Injectable } from '@angular/core';
import { Toaster } from 'ngx-toast-notifications';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private toaster: Toaster,private toast:ToastrService) {}

  showSuccessAlert(title: string, text: string) {
    const type = 'success';
    this.toaster.open({
      text: text,
      caption: title,
      type: type,
      position: 'top-right'
    
    });
    
  }

  showToastNotification(title:string,text:string){
    this.toast.show(text,title).onTap.subscribe(x=>{
      window.location.href="./orderList"
    })
  }

  showDangerAlert(title: string, text: string) {
    const type = 'danger';
    this.toaster.open({
      text: text,     
      type: type,
      position: 'top-right',
    });
  }

  showWarningAlert(title: string, text: string) {
    const type = 'warning';
    this.toaster.open({
      text: text,   
      type: type,
      position: 'top-right',
    });
  }
  showInfoAlert(title: string, text: string) {
    const type = 'info';
    this.toaster.open({
      text: text, 
      caption:title,  
      type: type,
      position: 'top-right',
      autoClose:false
    });
  }
}
