<app-sidebar></app-sidebar>
<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-12">
        <h1 class="page-title">
          <span >{{
            "trainer.editExecution" | translate
          }}</span>
        </h1>
      </div>
    </div>
    
    <div class="row pt-3">
      <div class="col-12 col-xl-12">
        <!-- <form [formGroup]="trainerform" class="form" (ngSubmit)="onSubmit()">
          <div class="row mb-3">
            <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
              <label>
                {{ "trainer.videotitle" | translate }}
                <span class="custom-error-star">*</span>
              </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-9">
              <div class="form-group input-group">
                <input
                  type="text"
                  formControlName="title"
                  class="form-control small-control"
                  placeholder="  {{ 'trainer.videotitle' | translate }} "
                  maxlength="25"
                  [ngClass]="{
                    'is-invalid': submitted && formValidation.title.errors
                  }"
                />
                <div
                  *ngIf="submitted && formValidation.title.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="formValidation.title.errors.required">
                    {{ "trainer.titleReq" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>        
        </form> -->
      </div>
    </div>
  </div>
</div>
