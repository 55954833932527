import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addtag',
  templateUrl: './addtag.component.html',
  styleUrls: ['./addtag.component.css']
})
export class AddtagComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  } 
 
}
