<div class="customer-detail">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="image_detail">
                    <div class="row">
                        <div class="col-6 col-md-4 image">
                            <img *ngIf="customerDetails && customerDetails.customer && customerDetails.customer.customer_image_path_name" [src]="baseUrl+'/'+customerDetails.customer.customer_image_path_name" style="width: 100%;max-width:200px;margin-bottom: 20px;">
                        </div>
                        <div class="col-6 col-md-8 detail">
                            <img *ngIf="customerDetails && customerDetails.customer && customerDetails.customer.salon_id && customerDetails.customer.salon_id.salon_logo_path_name" [src]="baseUrl+customerDetails.customer.salon_id.salon_logo_path_name" style="max-width:150px; max-height: 100px; margin-bottom:20px;">
                            <!-- <p class="customer_info">Gender: <span *ngIf="customerDetails?.customer?.gender==1">Male</span><span *ngIf="customerDetails?.customer?.gender==2">FeMale</span></p> -->
                        </div>
                        <div class="col-md-4"></div>
                        <div class="col-md-8">
                            <p class="customer_info">Gentile {{customerDetails?.customer?.customer_name}} abbiamo il piacere di comunicarti le seguenti informazioni riguardanti la forma del tuo viso ed i nostri consigli di bellezza.</p>
                            <p class="customer_info">Forma del viso : {{customerDetails?.customer?.face_shape}}</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="important" style="width:100%;margin:20px 0;text-align:center">IMPORTANT</div> -->
                <div class="enhance-main">
                    <div class="enhance">
                        <div class="enhance_heading">
                            <i class="fas fa-heart"></i>
                            <span class="title">Da Preferire</span>
                        </div>
                        <ng-container *ngIf="customerDetails && customerDetails.customer && customerDetails.customer.to_enhance">
                            <p class="enhance_detail" *ngFor="let enhance of customerDetails.customer.to_enhance">
                                <i class="fa fa-check" aria-hidden="true"></i> <span class="enhance_text">{{enhance}}</span>
                            </p>
                        </ng-container>
                        
                        
                    </div>
                    <div class="enhance">
                        <div class="enhance_heading">
                            <i class="fas fa-heart"></i>
                            <span class="title">Da Evitare</span>
                        </div>
                        <ng-container *ngIf="customerDetails && customerDetails.customer && customerDetails.customer.to_avoid">
                            <p class="enhance_detail" *ngFor="let avoid of customerDetails.customer.to_avoid">
                                <i class="fas fa-times"></i> <span class="enhance_text">{{avoid}}</span>
                            </p>
                        </ng-container>
                    </div>
                </div>
                <p style="display:block;width:100%;margin:20px 0">Di seguito troverai una lista di accessori che esaltano la forma del tuo viso            </p>
                <div style="display:block;width:100%;margin:20px 0">
                <p style="width:100%">Occhiali</p>
                <div class="celebrity-slider" style="overflow-x: auto; max-width: 800px; width: 100%; display: flex;" *ngIf="customerDetails  && customerDetails.customer && customerDetails.customer.glass && customerDetails.customer.glass.length>0"> 
                    <!-- <ngx-slick-carousel class="carousel collection-items" #slickModal="slick-carousel" [config]="slideConfig" >
                        <div ngxSlickItem *ngFor="let slide of customerDetails.optionData.images" class="slide">
                            <img [src]="baseUrl+slide.image_path_name" alt="" width="100%">
                        </div>
                    </ngx-slick-carousel> 
                    <ngb-carousel>
                        <ng-template ngbSlide *ngFor="let slide of customerDetails.optionData.images;let i=index" id="{{i}}" >
                        <div class="picsum-img-wrapper" >
                            <img [src]="baseUrl+slide.image_path_name" alt="Random first slide" style="width:100%">
                        </div>
                        <div class="carousel-caption">
                            <h3>{{ image.title }}</h3>
                            <p>{{ image.short }}</p>
                        </div> 
                        </ng-template>
                    </ngb-carousel> -->
                    <!-- <mat-carousel
                            timings="250ms ease-in"
                            [autoplay]="true"
                            [interval]="6000"
                            color="accent"
                            maxWidth="auto"
                            [proportion]="25"
                            [slides]="slidess"
                            [loop]="true"
                            [hideArrows]="false"
                            [hideIndicators]="false"
                            [useKeyboard]="true"
                            [useMouseWheel]="false"
                            orientation="ltr"
                            >
                            <mat-carousel-slide
                                #matCarouselSlide
                                *ngFor="let slide of customerDetails.optionData.images; let i = index"
                                [image]="baseUrl+slide.image_path_name"
                                overlayColor="#00000040"
                                [hideOverlay]="false"
                            ></mat-carousel-slide>
                    </mat-carousel> -->
                    <img *ngFor="let slide of customerDetails.customer.glass" [src]="baseUrl+slide.glass_image_path"  alt="Random first slide" style="width:200px;;height:150px !important;object-fit:cover;">
                    
                </div>
                <!-- <p style="width:100%">Altro</p>
                <div class="celebrity-slider" style="overflow-x: auto; max-width: 800px; width: 100%; display: flex;" *ngIf="customerDetails  && customerDetails.customer && customerDetails.customer.other && customerDetails.customer.other.length>0"> -->
                    <!-- <ngx-slick-carousel class="carousel collection-items" #slickModal="slick-carousel" [config]="slideConfig" >
                        <div ngxSlickItem *ngFor="let slide of customerDetails.optionData.images" class="slide">
                            <img [src]="baseUrl+slide.image_path_name" alt="" width="100%">
                        </div>
                    </ngx-slick-carousel> 
                    <ngb-carousel>
                        <ng-template ngbSlide *ngFor="let slide of customerDetails.optionData.images;let i=index" id="{{i}}" >
                        <div class="picsum-img-wrapper" >
                            <img [src]="baseUrl+slide.image_path_name" alt="Random first slide" style="width:100%">
                        </div>
                        <div class="carousel-caption">
                            <h3>{{ image.title }}</h3>
                            <p>{{ image.short }}</p>
                        </div> 
                        </ng-template>
                    </ngb-carousel> -->
                    <!-- <mat-carousel
                            timings="250ms ease-in"
                            [autoplay]="true"
                            [interval]="6000"
                            color="accent"
                            maxWidth="auto"
                            [proportion]="25"
                            [slides]="slidess"
                            [loop]="true"
                            [hideArrows]="false"
                            [hideIndicators]="false"
                            [useKeyboard]="true"
                            [useMouseWheel]="false"
                            orientation="ltr"
                            >
                            <mat-carousel-slide
                                #matCarouselSlide
                                *ngFor="let slide of customerDetails.optionData.images; let i = index"
                                [image]="baseUrl+slide.image_path_name"
                                overlayColor="#00000040"
                                [hideOverlay]="false"
                            ></mat-carousel-slide>
                    </mat-carousel> -->
                    <!-- <img *ngFor="let slide of customerDetails.customer.other" [src]="baseUrl+slide.other_image_path"  alt="Random first slide" style="width:200px;height:150px !important;object-fit:cover;"> -->
                    
                <!-- </div> -->
            </div>
        </div>
        <p style="margin:10px 0;width:100% ; margin: 20px 20px;">
            Ti ricordiamo il nostro numero di telefono {{customerDetails?.customer?.salon_id?.salon_admin_phone}},siamo a disposizione per qualunque tua richiesta.
        </p>
        <p style="display:block;width:100%;text-align: center;">A presto!</p>
        <!-- <p style="width:100%">{{customerDetails?.customer?.operator_name}}</p> -->
        <img src="../../../assets/images/Logo_health_beauty.png" style="width:200px">
        </div>
    </div>
</div>
