<app-sidebar></app-sidebar>

<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-6">
        <h1 class="page-title">
          {{ "tag.tagList" | translate }}
        </h1>
      </div>
      <div class="col-6 text-right">
        <button
        class="btn btn-default mr-3"
        data-toggle="modal"
        data-target="#exampleModalCenter"
        (click)="addNewTag()"
      >
        {{ "tag.addNewTag" | translate }}
      </button>
        <button
          class="btn btn-default"
          data-toggle="modal"        
          (click)="tagListview()"
        >
       {{ "tag.view" | translate}} {{ "tag.tagList" | translate }}
        </button>
      </div>
    </div>

    <div class="tab-box tab-box-vertical pt-3" role="tabpanel">
      <div class="row">
        <div class="col-sm-9">
          <ul
            class="nav nav-tabs nav-justified nav-tabs-dropdown main-tab"
            role="tablist"
          >
            <li role="presentation">
              <a
                href="#celebrityfemale"
                aria-controls="celebrityfemale"
                role="tab"
                data-toggle="tab"
                class="active"
                >{{ "tag.celebrityfemale" | translate }}</a
              >
            </li>
            <li role="presentation">
              <a
                href="#celebritymale"
                aria-controls="celebritymale"
                role="tab"
                data-toggle="tab"
                >{{ "tag.celebritymale" | translate }}</a
              >
            </li>
            <li role="presentation">
              <a
                href="#peoplefemale"
                aria-controls="peoplefemale"
                role="tab"
                data-toggle="tab"
                >{{ "tag.peoplefemale" | translate }}</a
              >
            </li>
            <li role="presentation">
              <a
                href="#peoplemale"
                aria-controls="peoplemale"
                role="tab"
                data-toggle="tab"
                >{{ "tag.peoplemale" | translate }}</a
              >
            </li>
            <li role="presentation">
              <a
                href="#designfemale"
                aria-controls="designfemale"
                role="tab"
                data-toggle="tab"
                >{{ "tag.designfemale" | translate }}</a
              >
            </li>
            <li role="presentation">
              <a
                href="#designmale"
                aria-controls="designmale"
                role="tab"
                data-toggle="tab"
                >{{ "tag.designmale" | translate }}</a
              >
            </li>
          </ul>
          <div class="tab-content pt-3">
            <div
              role="tabpanel1"
              class="tab-pane tab-in active"
              id="celebrityfemale"
            >
              <div class="row">
                <div
                  class="col-xl-3 col-sm-4 col-6"
                  *ngFor="let image of celebrityfemaleList"
                >
                  <div class="tags-list-img">
                    <img
                      src="{{ baseURL }}{{ image.image_path_name }}"
                      (click)="editImage(image.id)"
                    />
                    <div class="images-btn">
                      <button
                        data-toggle="modal"
                        data-target="#viewimageModal"
                        (click)="showImageModal(image.image_path_name)"
                      >
                        <i class="fal fa-eye"></i>
                      </button>
                      <button
                        data-toggle="modal"
                        data-target="#imageModal"
                        (click)="showModal(image.id)"
                      >
                        <i class="fal fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane" id="celebritymale">
              <div class="row">
                <div
                  class="col-xl-3 col-sm-4 col-6"
                  *ngFor="let image of celebritymaleList"
                >
                  <div class="tags-list-img">
                    <img
                      src="{{ baseURL }}{{ image.image_path_name }}"
                      (click)="editImage(image.id)"
                    />
                    <div class="images-btn">
                      <button
                        data-toggle="modal"
                        data-target="#viewimageModal"
                        (click)="showImageModal(image.image_path_name)"
                      >
                        <i class="fal fa-eye"></i>
                      </button>
                      <button
                        data-toggle="modal"
                        data-target="#imageModal"
                        (click)="showModal(image.id)"
                      >
                        <i class="fal fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane" id="peoplefemale">
              <div class="row">
                <div
                  class="col-xl-3 col-sm-4 col-6"
                  *ngFor="let image of peoplefemaleList"
                >
                  <div class="tags-list-img">
                    <img
                      src="{{ baseURL }}{{ image.image_path_name }}"
                      (click)="editImage(image.id)"
                    />
                    <div class="images-btn">
                      <button
                        data-toggle="modal"
                        data-target="#viewimageModal"
                        (click)="showImageModal(image.image_path_name)"
                      >
                        <i class="fal fa-eye"></i>
                      </button>
                      <button
                        data-toggle="modal"
                        data-target="#imageModal"
                        (click)="showModal(image.id)"
                      >
                        <i class="fal fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane" id="peoplemale">
              <div class="row">
                <div
                  class="col-xl-3 col-sm-4 col-6"
                  *ngFor="let image of peoplemaleList"
                >
                  <div class="tags-list-img">
                    <img
                      src="{{ baseURL }}{{ image.image_path_name }}"
                      (click)="editImage(image.id)"
                    />
                    <div class="images-btn">
                      <button
                        data-toggle="modal"
                        data-target="#viewimageModal"
                        (click)="showImageModal(image.image_path_name)"
                      >
                        <i class="fal fa-eye"></i>
                      </button>
                      <button
                        data-toggle="modal"
                        data-target="#imageModal"
                        (click)="showModal(image.id)"
                      >
                        <i class="fal fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane" id="designfemale">
              <div class="row">
                <div
                  class="col-xl-3 col-sm-4 col-6"
                  *ngFor="let image of designfemaleList"
                >
                  <div class="tags-list-img">
                    <img
                      src="{{ baseURL }}{{ image.image_path_name }}"
                      (click)="editImage(image.id)"
                    />
                    <div class="images-btn">
                      <button
                        data-toggle="modal"
                        data-target="#viewimageModal"
                        (click)="showImageModal(image.image_path_name)"
                      >
                        <i class="fal fa-eye"></i>
                      </button>
                      <button
                        data-toggle="modal"
                        data-target="#imageModal"
                        (click)="showModal(image.id)"
                      >
                        <i class="fal fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane" id="designmale">
              <div class="row">
                <div
                  class="col-xl-3 col-sm-4 col-6"
                  *ngFor="let image of designmaleList"
                >
                  <div class="tags-list-img">
                    <img
                      src="{{ baseURL }}{{ image.image_path_name }}"
                      (click)="editImage(image.id)"
                    />
                    <div class="images-btn">
                      <button
                        data-toggle="modal"
                        data-target="#viewimageModal"
                        (click)="showImageModal(image.image_path_name)"
                      >
                        <i class="fal fa-eye"></i>
                      </button>
                      <button
                        data-toggle="modal"
                        data-target="#imageModal"
                        (click)="showModal(image.id)"
                      >
                        <i class="fal fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <ul
            class="nav nav-tabs nav-justified nav-tabs-dropdown main-tab"
            role="tablist"
          >
            <li role="presentation">
              <a
                href="#tags"
                aria-controls="tags"
                role="tab"
                data-toggle="tab"
                class="active"
                >{{ "tags" | translate }}</a
              >
            </li>
            <li role="presentation">
              <a
                href="#filter"
                aria-controls="filter"
                role="tab"
                data-toggle="tab"
                >{{ "filter" | translate }}</a
              >
            </li>
          </ul>
          <div class="tab-content pt-3">
            <div role="tabpanel" class="tab-pane tab-in active" id="tags">
              <div class="search-input">
                <label for="Search">{{ "search" | translate }}</label>
                <input
                  type="text"
                  placeholder="{{ 'search' | translate }}"
                  #searchInput
                  (change)="searchByTag(searchInput.value)"
                  name="search"
                />
              </div>
              <div class="checkbox_scroll mt-3">
                <div
                  class="checkbox-box mt-0 mb-3"
                  *ngFor="let tag of tagList; let i = index"
                >
                  <label class="container"
                    >{{ tag.tag_name }}
                    <input
                      type="checkbox"
                      id="{{ tag.id }}"
                      value="{{ tag.id }}"
                      (change)="onChange(i, tag.id, $event)"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane" id="filter">
              <div class="row">
                <div class="col-sm-6">
                  <div class="checkbox-box">
                    <div class="checkbox-box-title">
                      {{ "hairLength" | translate }}
                    </div>
                    <label
                      class="container"
                      *ngFor="let length of hair_length; let i = index"
                      >{{ length.length }}
                      <input
                        type="checkbox"
                        id="{{ length.id }}"
                        value="{{ length.id }}"
                        (change)="onChangeHairLength(i, length.id, $event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="checkbox-box">
                    <div class="checkbox-box-title">
                      {{ "hairType" | translate }}
                    </div>
                    <label
                      class="container"
                      *ngFor="let type of hair_type; let j = index"
                      >{{ type.type }}
                      <input
                        type="checkbox"
                        id="{{ type.id }}"
                        value="{{ type.id }}"
                        (change)="onChangeHairType(j, type.id, $event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="checkbox-box">
                    <div class="checkbox-box-title">
                      {{ "haircolor" | translate }}
                    </div>
                    <label
                      class="container"
                      *ngFor="let color of hair_color; let c = index"
                      >{{ color.color }}
                      <input
                        type="checkbox"
                        id="{{ color.id }}"
                        value="{{ color.id }}"
                        (change)="onChangeHairColor(c, color.id, $event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="checkbox-box">
                    <div class="checkbox-box-title">
                      {{ "age" | translate }}
                    </div>
                    <label
                      class="container"
                      *ngFor="let age of age; let a = index"
                      >{{ age.age }}
                      <input
                        type="checkbox"
                        id="{{ age.id }}"
                        value="{{ age.id }}"
                        (change)="onChangeAge(a, age.id, $event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="addTagFlag">
  <div id="exampleModalCenter" class="modal fade">
    <div class="modal-dialog modal-dialog-centered modal-confirm">
      <div class="modal-content">
        <div class="modal-header flex-column">
          <!-- <div class="icon-box">
            <i class="fas fa-times"></i>
          </div>						 -->
          <h5 class="modal-title w-100 font-weight-bold text-center">
            {{ "tag.addNewTag" | translate }}
          </h5>

          <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        </div> -->
        </div>
        <form
          autocomplete="off"
          novalidate
          [formGroup]="tagForm"
          (ngSubmit)="submitTagForm()"
        >
          <div class="modal-body">
            <div class="ng-autocomplete">
              <ng-autocomplete
                #ngAutoCompleteStatic
                [data]="tagDataList"
                formControlName="tag_name"
                [initialValue]=""
                [placeholder]="placeholder"
                [searchKeyword]="keyword"               
                [historyHeading]="historyHeading"
                [notFoundText]="not_found"
                [itemTemplate]="itemTemplateStatic"
                [notFoundTemplate]="notFoundTemplate"
              >
              </ng-autocomplete>

              <ng-template #itemTemplateStatic let-item>
                <a [innerHTML]="item"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <div>
              <button
                class="btn btn-default submitbtn mr-2"
                data-dismiss="modal"
                type="button"
                (click)="submitTagForm()"
                [disabled]="tagForm.invalid"
              >
                {{ "saveBtn" | translate }}
              </button>
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-default btn-secondary"
              >
                {{ "cancel" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>

<div id="imageModal" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h4 class="modal-title text-center w-100">
          {{ "areYouSure" | translate }}
        </h4>
      </div>
      <div class="modal-body text-center">
        <p class="mb-0">{{ "deleteRecord" | translate }}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "cancel" | translate }}
        </button>
        <button
          type="button"
          (click)="imageDelete(imageDeleteId)"
          data-dismiss="modal"
          class="btn btn-danger"
        >
          {{ "delete" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="viewimageModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <button
    type="button"
    class="close modal-close"
    data-dismiss="modal"
    aria-label="Close"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content video-content transparent">
      <div class="modal-body p-0 text-center">
        <img
          src="{{ baseURL }}{{ image_path_name }}"
          class="img-fluid modal-cover"
        />
      </div>
    </div>
  </div>
</div>
