<app-sidebar></app-sidebar>
<div class="body-padding-top">
    <div class="container-fluid">
        <div class="row pt-3">
            <div class="col-12">
                <h1 class="page-title">
                    <span *ngIf="isAddMode">{{"effect.addExecution" | translate}}</span>
                    <span *ngIf="!isAddMode">{{"effect.editExecution" | translate}}</span>
                </h1>
            </div>
        </div>
        <div>
            <div *ngIf="loading" class="loader-class">
                <img src="assets/images/loader.gif" width="80px" />
            </div>
        </div>
        <div class="row pt-3">
            <div class="col-12 col-xl-12">
                <form [formGroup]="effectform" class="form" (ngSubmit)="onSubmit()">
                    <div class="row mb-3">
                        <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
                            <label>{{ "effect.videotitle" | translate }}
                                <span class="custom-error-star">*</span>
                            </label>
                        </div>
                        <div class="col-12 col-sm-12 col-xl-9">
                            <div class="form-group input-group">
                                <input type="text" formControlName="title" class="form-control small-control"
                                    placeholder="  {{ 'effect.videotitle' | translate }} " maxlength="25" [ngClass]="{
                    'is-invalid': submitted && formValidation.title.errors}" />
                                <div *ngIf="submitted && formValidation.title.errors" class="invalid-feedback">
                                    <div *ngIf="formValidation.title.errors.required">
                                        {{ "effect.titleReq" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
                            <label>
                                {{ "effect.thumbnail" | translate }}
                                <span *ngIf="isAddMode" class="custom-error-star">*</span>
                            </label>
                        </div>
                        <div class="col-12 col-sm-12 col-xl-6">
                            <div class="input-group mb-2" *ngIf="isAddMode">
                                <input formControlName="image_thum_name" id="file1" type="file" class="form-control"
                                    (change)="onFileChangeThumb($event)" [ngClass]="{'is-invalid':submitted && formValidation.image_thum_name.errors}" />
                                <div *ngIf="submitted && formValidation.image_thum_name.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="formValidation.image_thum_name.errors.required">
                                        {{ "image.imageReq" | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4 col-xl-4 p-0 d-flex align-items-center" *ngIf="!isAddMode">
                                <input style="display: none" formControlName="image_thum_name" id="file1" type="file"
                                    (change)="onFileChangeThumb($event)" />
                                <label for="file1" class="glass-list mb-0" *ngIf="imageUrlThumb">
                                    <img src="{{ imageUrlThumb }}" class="glass-img" />
                                </label>
                                <label for="file1" class="glass-list mb-0" *ngIf="!imageUrlThumb">
                                    <img src="{{ baseURL }}{{ imgurlThumb }}" class="glass-img" />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
                            <label>
                                {{ "effect.video" | translate }}<span *ngIf="isAddMode" class="custom-error-star">*</span>
                            </label>
                        </div>
                        <div class="col-12 col-sm-12 col-xl-9">
                            <div class="input-group mb-2">
                                <input formControlName="image_path_name" id="file" type="file" class="form-control"
                                    accept="video/mp4,video/x-m4v,video/*" (change)="onFileChange($event)" [ngClass]="{
                    'is-invalid': submitted && formValidation.image_path_name.errors}" />
                                <div *ngIf="submitted && formValidation.image_path_name.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="formValidation.image_path_name.errors.required">
                                        {{ "effect.videoMsg" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-sm-12 col-xl-3">
                            <label>
                                {{ "hairLength" | translate }}
                            </label>
                        </div>
                        <div class="col-12 col-sm-12 col-xl-9">
                            <div class="input-group images-checkbox-box">
                                <div class="row w-100">
                                    <div class="col-6 col-sm-4 col-md-3 col-lg-3"
                                        *ngFor="let length of hair_length; let i = index">
                                        <label class="container">{{ length.length }}
                                            <input type="checkbox" id="{{ length.id }}" value="{{ length.id }}"
                                                [checked]="hairLengthArray.includes(length.id) ? true : false" (change)="onChangeHairLength(i, length.id, $event)" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-sm-12 col-xl-3">
                            <label>
                                {{ "hairType" | translate }}
                            </label>
                        </div>
                        <div class="col-12 col-sm-12 col-xl-9">
                            <div class="input-group images-checkbox-box">
                                <div class="row w-100">
                                    <div class="col-6 col-sm-4 col-md-3 col-lg-3"
                                        *ngFor="let type of hair_type; let j = index">
                                        <label class="container">{{ type.type }}
                                            <input type="checkbox" id="{{ type.id }}" value="{{ type.id }}" [checked]="
                          hairTypehArray.includes(type.id) ? true : false" (change)="onChangeHairType(j, type.id, $event)" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-sm-12 col-xl-3">
                            <label>
                                {{ "haircolor" | translate }}
                            </label>
                        </div>
                        <div class="col-12 col-sm-12 col-xl-9">
                            <div class="input-group images-checkbox-box">
                                <div class="row w-100">
                                    <div class="col-6 col-sm-4 col-md-3 col-lg-3"
                                        *ngFor="let color of hair_color; let c = index">
                                        <label class="container">{{ color.color }}
                                            <input type="checkbox" id="{{ color.id }}" value="{{ color.id }}" [checked]="
                          hairColorArray.includes(color.id) ? true : false" (change)="onChangeHairColor(c, color.id, $event)" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-sm-12 col-xl-3">
                            <label>
                                {{ "age" | translate }}
                            </label>
                        </div>
                        <div class="col-12 col-sm-12 col-xl-9">
                            <div class="input-group images-checkbox-box">
                                <div class="row w-100">
                                    <div class="col-6 col-sm-4 col-md-3 col-lg-3"
                                        *ngFor="let age of age; let a = index">
                                        <label class="container">{{ age.age }}
                                            <input type="checkbox" id="{{ age.id }}" value="{{ age.id }}"
                                                [checked]="ageArray.includes(age.id) ? true : false"
                                                (change)="onChangeAge(a, age.id, $event)" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-baseline">
                            <label>
                                {{ "image.selectTag" | translate }}
                            </label>
                        </div>
                        <div class="col-12 col-sm-12 col-xl-9">
                            <div class="input-group images-checkbox-box scroll-tag">
                                <div class="row w-100 mr-0">
                                    <div class="col-6 col-sm-4 col-md-3 col-lg-3"
                                        *ngFor="let tag of tagList; let i = index">
                                        <label class="container">{{ tag.tag_name }}
                                            <input type="checkbox" id="{{ tag.id }}" value="{{ tag.id }}"
                                                [checked]="tagarray.includes(tag.id) ? true : false"
                                                (change)="onChange(i, tag.id, $event)" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <span *ngIf="!tag_checked" class="custom-error">{{
                                    tagErrorMsg
                                    }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="text-right">
                        <button type="submit" class="btn btn-default mr-2">
                            {{ "saveBtn" | translate }}
                        </button>
                        <button type="button" class="btn btn-default btn-secondary" routerLink="/effectList">
                            {{ "cancel" | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>