import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ImageService } from './image.service';
import AppConstants from 'src/app/shared/commons/app.constants';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css'],
})
export class ImagesComponent implements OnInit {
  image_path_name: any = '';
  baseURL = AppConstants.SERVICE_BASE_URL;
  imageList: any = [];
  imageDeleteId: any = [];
  celebrityfemaleList: any = [];
  celebritymaleList: any = [];
  peoplefemaleList: any = [];
  peoplemaleList: any = [];
  designmaleList: any = [];
  designfemaleList: any = [];
  errorDialog: any;
  successDialog: any;
  somethingWrong: any;
  constructor(
    private translateService: TranslateService,
    public router: Router,
    public imageService: ImageService,
    public alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.getImageList();
  }
  addImage() {
    localStorage.removeItem('image_id');
    this.router.navigate(['addEditImage']);
  }
  getImageList() {
    this.celebrityfemaleList = [];
    this.celebritymaleList = [];
    this.peoplefemaleList = [];
    this.peoplemaleList = [];
    this.designfemaleList = [];
    this.designmaleList = [];

    this.imageService.getImageList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.imageList = data.data;
         //  console.log(this.imageList);
          for (var i = 0; i < this.imageList.length; i++) {
            if (
              this.imageList[i].category == 1 &&
              this.imageList[i].gender == 2
            )
              this.celebrityfemaleList.push(this.imageList[i]);
              
            else if (
              this.imageList[i].category == 1 &&
              this.imageList[i].gender == 1
            )
              this.celebritymaleList.push(this.imageList[i]);
            else if (
              this.imageList[i].category == 2 &&
              this.imageList[i].gender == 2
            )
              this.peoplefemaleList.push(this.imageList[i]);
            else if (
              this.imageList[i].category == 2 &&
              this.imageList[i].gender == 1
            )
              this.peoplemaleList.push(this.imageList[i]);
            else if (
              this.imageList[i].category == 3 &&
              this.imageList[i].gender == 2
            )
              this.designfemaleList.push(this.imageList[i]);
            else if (
              this.imageList[i].category == 3 &&
              this.imageList[i].gender == 1
            )
              this.designmaleList.push(this.imageList[i]);
          }
        } else {
          let imageNotExist = this.translateService.instant(
            'image.imageNotExist'
          );
          this.alertService.showDangerAlert(this.errorDialog, imageNotExist);
        }
      },
      
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
   }
  imageDelete(id: any) {
    this.imageService.delete(id).subscribe(
      (data) => {
        if (data.is_error == false) {
          let imageDelete = this.translateService.instant('imageDelete');
          this.alertService.showSuccessAlert(this.successDialog, imageDelete);
          setTimeout(() => {
            this.getImageList();
          }, 1000);
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  showModal(id: any) {
    this.imageDeleteId = id;
  }
  editImage(id: any) {
    localStorage.removeItem('image_id');
    localStorage.setItem('image_id', id);
    this.router.navigate(['addEditImage']);
  }
  showImageModal(path: any) {
    this.image_path_name = path;
  }
}
