import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { TagService } from './tag.service';
import { ImageService } from '../images/image.service';
import { HttpErrorResponse } from '@angular/common/http';
//import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import AppConstants from 'src/app/shared/commons/app.constants';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css'],
})
export class TagsComponent implements OnInit {
  tagList: any = [];
  errorDialog: any;
  successDialog: any;
  somethingWrong: any;
  addTagFlag: boolean = true;
  tagarray: any = [];
  hair_length_arry = [{ hair_length: [] }];
  hair_type_array = [{ hair_type: [] }];
  hair_color_array = [{ hair_color: [] }];
  age_array = [{ age: [] }];
  tagArrayList = [{ tags: [] }];
  imageListFilter: any = [];
  searchTagArray: any = [];
  image_path_name: any;
  imageDeleteId: any;
  baseURL = AppConstants.SERVICE_BASE_URL;
  hair_length = AppConstants.hair_length;
  hair_type = AppConstants.hair_type;
  hair_color = AppConstants.hair_color;
  age = AppConstants.age;
  celebrityfemaleList: any = [];
  celebritymaleList: any = [];
  peoplefemaleList: any = [];
  peoplemaleList: any = [];
  designfemaleList: any = [];
  imageList: any = [];
  seacrhImageList: any = [];
  tagDataList: any = [];
  designmaleList: any = [];
  tag_name = '';
  searchTag: any = { tag_name: '' };
  tagForm = new FormGroup({});
  placeholder: any = '';
  historyHeading: any = '';
  //public placeholder: string = 'Enter the Tag Name';
  public keyword = 'tag_name';
  not_found: any = '';
  //public historyHeading: string = 'Recently selected';
  hairLengthArray: any = [];
  hairTypehArray: any = [];
  hairColorArray: any = [];
  ageArray: any = [];
  postData: any = {};

  constructor(
    private translateService: TranslateService,
    public imageService: ImageService,
    public router: Router,
    public tagService: TagService,
    public alertService: AlertService,
    private formBuilder: FormBuilder
  ) {}
  createform() {
    this.tagForm = this.formBuilder.group({
      tag_name: [{ value: '', disabled: false }, Validators.required],
    });
  }
  ngOnInit(): void {
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.placeholder = this.translateService.instant('tag.entertagname');
    this.historyHeading = this.translateService.instant('tag.recentlySelected');
    this.not_found = this.translateService.instant('tag.not_found');
    this.addTagFlag = true;
    this.postData.hair_length = [];
    this.postData.hair_type = [];
    this.postData.hair_color = [];
    this.postData.age = [];
    this.getImageList();
    this.getTagList();
    this.createform();
  }

  getImageList() {
    this.imageService.getImageList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.imageList = data.data;
          // console.log(this.imageList);
          for (var i = 0; i < this.imageList.length; i++) {
            if (
              this.imageList[i].category == 1 &&
              this.imageList[i].gender == 1
            )
              this.celebrityfemaleList.push(this.imageList[i]);
            else if (
              this.imageList[i].category == 1 &&
              this.imageList[i].gender == 2
            )
              this.celebritymaleList.push(this.imageList[i]);
            else if (
              this.imageList[i].category == 2 &&
              this.imageList[i].gender == 1
            )
              this.peoplefemaleList.push(this.imageList[i]);
            else if (
              this.imageList[i].category == 2 &&
              this.imageList[i].gender == 2
            )
              this.peoplemaleList.push(this.imageList[i]);
            else if (
              this.imageList[i].category == 3 &&
              this.imageList[i].gender == 1
            )
              this.designfemaleList.push(this.imageList[i]);
            else if (
              this.imageList[i].category == 3 &&
              this.imageList[i].gender == 2
            )
              this.designmaleList.push(this.imageList[i]);
          }
        } else {
          this.alertService.showDangerAlert('Error', 'user not exist.');
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }

  getTagList() {
    this.tagService.getTagList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.tagList = data.data;
          for (var i = 0; i < this.tagList.length; i++) {
            this.tagDataList.push(this.tagList[i]['tag_name']);
          }
        } else {
          // this.alertService.showDangerAlert('Error', 'tag not exist.');
        }
      },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  // addNewTag(){
  //   this.router.navigate(['addtag']);
  // }
  
  addNewTag() {
    this.tagForm.reset();
    this.addTagFlag = true;
  }
  tagListview(){
      this.router.navigate(['viewtagList']);
  }
  submitTagForm() {
    if (this.tagForm.valid) {
      // console.log(this.tagForm.value);
      this.tagService.add(this.tagForm.value).subscribe(
        (data) => {
          if (data['is_error'] == true) {
            let tagexist = this.translateService.instant('productTag.tagExist');
            this.alertService.showDangerAlert(this.errorDialog, tagexist);
          } else {
            let tagAdded = this.translateService.instant(
              'productTag.tagAddedMsg'
            );
            this.alertService.showSuccessAlert(this.successDialog, tagAdded);
            this.addTagFlag = false;
            $('body').removeClass('modal-open');
          }
          this.getTagList();
        },
        (error: HttpErrorResponse) => {
          console.log(error.name + ' ' + error.message);
          this.alertService.showDangerAlert(
            this.errorDialog,
            this.somethingWrong
          );
        }
      );
    }
  }

  imageDelete(id: any) {
    this.imageService.delete(id).subscribe(
      (data) => {
        if (data.is_error == false) {
          let imagedelete = this.translateService.instant('imageDelete');
          this.alertService.showSuccessAlert(this.successDialog, imagedelete);
          setTimeout(() => {
            this.getImageList();
          }, 1000);
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  showModal(id: any) {
    this.imageDeleteId = id;
  }

  editImage(id: any) {
    localStorage.removeItem('image_id');
    localStorage.setItem('image_id', id);
    this.router.navigate(['addEditImage']);
  }
  showImageModal(path: any) {
    this.image_path_name = path;
  }
  searchByTag(name: any) {
    this.searchTag.tag_name = name;
    this.tagService.getTagListByTag(this.searchTag).subscribe(
      (data) => {
        if (data.is_error == false) {
          this.tagList = data.data;
        }
      },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }

  onChangeHairLength(index: number, _id: number, isChecked: any) {
    if (isChecked.target.checked) {
      this.hairLengthArray.push(_id);
    } else {
      this.hairLengthArray.splice(this.hairLengthArray.indexOf(_id), 1);
    }
    this.postData.hair_length = this.hairLengthArray;
    this.searchByHair();
  }

  onChangeHairType(index: number, _id: number, isChecked: any) {
    if (isChecked.target.checked) {
      this.hairTypehArray.push(_id);
    } else {
      this.hairTypehArray.splice(this.hairTypehArray.indexOf(_id), 1);
    }
    this.postData.hair_type = this.hairTypehArray;
    this.searchByHair();
  }

  onChangeAge(index: number, _id: number, isChecked: any) {
    if (isChecked.target.checked) {
      this.ageArray.push(_id);
    } else {
      this.ageArray.splice(this.ageArray.indexOf(_id), 1);
    }
    this.postData.age = this.ageArray;
    this.searchByHair();
  }
  onChangeHairColor(index: number, _id: number, isChecked: any) {
    if (isChecked.target.checked) {
      this.hairColorArray.push(_id);
    } else {
      this.hairColorArray.splice(this.hairColorArray.indexOf(_id), 1);
    }
    this.postData.hair_color = this.hairColorArray;
    this.searchByHair();
  }

  searchByHair() {
    this.hair_length_arry = this.imageList;
    let hair_type_array = [{ hair_type: [] }];
    let hair_color_array = [{ hair_color: [] }];
    let age_array = [{ age: [] }];
    if (
      this.postData['hair_length'].length > 0 ||
      this.postData['age'].length > 0 ||
      this.postData['hair_color'].length > 0 ||
      this.postData['hair_type'].length > 0
    ) {
      this.celebrityfemaleList = [];
      this.celebritymaleList = [];
      this.peoplefemaleList = [];
      this.peoplemaleList = [];
      this.designfemaleList = [];
      this.designmaleList = [];
      if (this.postData['hair_length'].length > 0) {
        for (var j = 0; j < this.postData['hair_length'].length; j++) {
          let result = this.hair_length_arry.filter((x) =>
            x.hair_length.find((a) => a === this.postData['hair_length'][j])
          );
          this.imageListFilter = result;
          //console.log(this.imageList);
        }
        hair_type_array = this.imageListFilter;
      } else {
        hair_type_array = this.imageList;
      }
      if (this.postData['hair_type'].length > 0) {
        for (var j = 0; j < this.postData['hair_type'].length; j++) {
          let hair_type_result = hair_type_array.filter((x) =>
            x.hair_type.find((a) => a === this.postData['hair_type'][j])
          );
          this.imageListFilter = hair_type_result;
        }
        hair_color_array = this.imageListFilter;
      }
      //  else{
      // 	this.imageList=hair_type_array;
      //    hair_color_array=this.imageList;
      //  }
      if (this.postData['hair_color'].length > 0) {
        for (var j = 0; j < this.postData['hair_color'].length; j++) {
          let hair_color_result = hair_color_array.filter((x) =>
            x.hair_color.find((a) => a === this.postData['hair_color'][j])
          );
          this.imageListFilter = hair_color_result;
        }
        age_array = this.imageListFilter;
      }
      //  else{
      // 	this.imageList=	hair_color_array
      //   age_array=this.imageList;
      //  }
      if (this.postData['age'].length > 0) {
        for (var j = 0; j < this.postData['age'].length; j++) {
          let age_result = age_array.filter((x) =>
            x.age.find((a) => a === this.postData['age'][j])
          );
          this.imageListFilter = age_result;
        }
      }
      // else{

      // }
    }
    if (
      this.postData['hair_length'].length == 0 &&
      this.postData['age'].length == 0 &&
      this.postData['hair_color'].length == 0 &&
      this.postData['hair_type'].length == 0
    ) {
      this.getImageList();
      return;
    }
    console.log(this.imageListFilter);
    for (var i = 0; i < this.imageListFilter.length; i++) {
      if (
        this.imageListFilter[i].category == 1 &&
        this.imageListFilter[i].gender == 1
      )
        this.celebrityfemaleList.push(this.imageListFilter[i]);
      else if (
        this.imageListFilter[i].category == 1 &&
        this.imageListFilter[i].gender == 2
      )
        this.celebritymaleList.push(this.imageListFilter[i]);
      else if (
        this.imageListFilter[i].category == 2 &&
        this.imageListFilter[i].gender == 1
      )
        this.peoplefemaleList.push(this.imageListFilter[i]);
      else if (
        this.imageListFilter[i].category == 2 &&
        this.imageListFilter[i].gender == 2
      )
        this.peoplemaleList.push(this.imageListFilter[i]);
      else if (
        this.imageListFilter[i].category == 3 &&
        this.imageListFilter[i].gender == 1
      )
        this.designfemaleList.push(this.imageListFilter[i]);
      else if (
        this.imageListFilter[i].category == 3 &&
        this.imageListFilter[i].gender == 2
      )
        this.designmaleList.push(this.imageListFilter[i]);
    }
  }

  onChange(index: number, _id: string, isChecked: any) {
    if (isChecked.target.checked) {
      this.tagarray.push(_id);
    } else {
      // if (this.tagarray.indexOf(index) == 0) {
      //   this.tagarray.splice(this.tagarray.indexOf(index), 1);
      // }
      // else {
      this.tagarray.splice(this.tagarray.indexOf(_id), 1);
    }
    //}
    //console.log(this.tagarray.length);
    this.searchByTagId();
  }
  searchByTagId() {
    if (this.tagarray.length > 0) {
      this.searchTagArray = this.imageList;
      let tagtest: any = [];
      let tempArray: any = [];
      this.celebrityfemaleList = [];
      this.celebritymaleList = [];
      this.peoplefemaleList = [];
      this.peoplemaleList = [];
      this.designfemaleList = [];
      this.designmaleList = [];
      //this.imageList=[];
      for (var i = 0; i < this.searchTagArray.length; i++) {
        tagtest = [];
        for (var j = 0; j < this.searchTagArray[i].tags.length; j++) {
          tagtest.push(this.searchTagArray[i].tags[j].id);
        }
        tempArray.push({ tags: tagtest, image: this.searchTagArray[i] });
      }
      this.tagArrayList = tempArray;
      for (var j = 0; j < this.tagarray.length; j++) {
        let result = this.tagArrayList.filter((x) =>
          x.tags.find((a) => a === this.tagarray[j])
        );
        this.imageListFilter = result;
      }
      // console.log(this.imageListFilter);
      for (var i = 0; i < this.imageListFilter.length; i++) {
        if (
          this.imageListFilter[i]['image'].category == 1 &&
          this.imageListFilter[i]['image'].gender == 1
        )
          this.celebrityfemaleList.push(this.imageListFilter[i]['image']);
        else if (
          this.imageListFilter[i]['image'].category == 1 &&
          this.imageListFilter[i]['image'].gender == 2
        )
          this.celebritymaleList.push(this.imageListFilter[i]['image']);
        else if (
          this.imageListFilter[i]['image'].category == 2 &&
          this.imageListFilter[i]['image'].gender == 1
        )
          this.peoplefemaleList.push(this.imageListFilter[i]['image']);
        else if (
          this.imageListFilter[i]['image'].category == 2 &&
          this.imageListFilter[i]['image'].gender == 2
        )
          this.peoplemaleList.push(this.imageListFilter[i]['image']);
        else if (
          this.imageListFilter[i]['image'].category == 3 &&
          this.imageListFilter[i]['image'].gender == 1
        )
          this.designfemaleList.push(this.imageListFilter[i]['image']);
        else if (
          this.imageListFilter[i]['image'].category == 3 &&
          this.imageListFilter[i]['image'].gender == 2
        )
          this.designmaleList.push(this.imageListFilter[i]['image']);
      }
    } else {
      this.getImageList();
      return;
    }
  }
}
