import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductService } from '../product.service';
import { TagService } from '../../tags/tag.service';
import { SalonService } from '../../dashboard/salon.service';
import { HealthService } from '../../health/health.service';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { Router } from '@angular/router';
import AppConstants from 'src/app/shared/commons/app.constants';
import { ProducttagService } from '../../product-tag/producttag.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-addeditproduct',
  templateUrl: './addeditproduct.component.html',
  styleUrls: ['./addeditproduct.component.css'],
})
export class AddeditproductComponent implements OnInit {
  errorDialog: any;
  successDialog: any;
  somethingWrong: any;
  salonTempArray: any = [];
  searchsalon: any = { name: '' };
  addsalonFlag: boolean = true;
  selectedItems: any = [];
  salonDisplayArray: any = [];
  dropdownSettings: IDropdownSettings = {};
  submitted = false;
  skinNewArray: any = [];
  baseURL = AppConstants.SERVICE_BASE_URL;
  skin_tone_temp: any = [];
  typeOfHairErrorMsg = '';
  seasonListArray: any = [];
  typeOfSkinErrorMsg = '';
  tagErrorMsg = '';
  hairTypeErrorMsg = '';
  hairLengthErrorMsg = '';
  hairColorErrorMsg = '';
  ageErrorMsg = '';
  typeOfSkinArray: any = [];
  typeOfHairArray: any = [];
  hairLengthArray: any = [];
  hairTypehArray: any = [];
  hairColorArray: any = [];
  ageArray: any = [];
  tagarray: any = [];
  salonArray: any = [];
  tagarrayChecked: any = [];
  private images: any = [];
  imgurl: any;
  editData: any;
  productId: any;
  imagedisplay: any;
  request: any = {};
  tagList: any;
  isAddMode: boolean = true;
  private image: any;
  imageUrl: any;
  salonList: any;
  skinList = AppConstants.skinList;
  hairList = AppConstants.HairList;
  skintypevalue = [1, 2, 3];
  skinArray: any = [];
  skinToneArray: any = [];
  hair_length = AppConstants.hair_length;
  hair_type = AppConstants.hair_type;
  hair_color = AppConstants.hair_color;
  age = AppConstants.age;
  seasonList = AppConstants.seasonList;
  index: any;
  typeOfHairArraychecked: any = [];
  constructor(
    private translateService: TranslateService,
    public ProducttagService: ProducttagService,
    public salonService: SalonService,
    private formBuilder: FormBuilder,
    public productService: ProductService,
    public alertService: AlertService,
    public router: Router,
    public tagService: TagService,
    public healthService: HealthService
  ) { }
  productform = new FormGroup({});

  ngOnInit(): void {
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.addsalonFlag = true;
    // this.dropdownSettings = {
    //   singleSelection: false,
    //   idField: 'id',
    //   textField: 'salonName',
    //   itemsShowLimit: 5,
    //   enableCheckAll: false,
    //   allowSearchFilter: true,
    // };
    this.getTagList();
    //  this.getslonList();
    this.index = 1;
    this.productId = localStorage.getItem('product_id');
    if (this.productId != null) {
      this.isAddMode = false;
      this.editproductForm();
      this.editProduct(this.productId);
    } else {
      this.productForm();
    }

    // this.getSkinList();
    // this.getHairList();
  }

  getslonList() {
    this.addsalonFlag = true;
    this.salonService.getAllSaloons().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.salonList = data.data;
          // console.log(this.salonList);
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }

  currentHealth() {
    this.index = 1;
  }

  currentBeauty() {
    this.index = 2;
  }

  currentsalon() {
    this.index = 5;
  }
  currentSeason() {
    this.index = 3;
  }
  currentInfo() {
    this.index = 4;
  }

  nextbeauty() {
    this.index = 2;
  }
  prevhealth() {
    this.index = 1;
  }
  nextinfo() {
    this.index = 4;
  }
  prevbeauty() {
    this.index = 1;
  }
  nextseason() {
    this.index = 3;
  }
  get formValidation() {
    return this.productform.controls;
  }
  onSubmit() {
    let formData = new FormData();
    formData.append('image_original_name', this.image);
    this.submitted = true;
    this.request.product_name = this.productform.controls['product_name'].value;
    this.request.product_brand = this.productform.controls[
      'product_brand'
    ].value;
    this.request.treatment_type = this.productform.controls['treatment_type'].value;
    this.request.product_price = this.productform.controls[
      'product_price'
    ].value;
    this.request.product_description = this.productform.controls[
      'product_description'
    ].value;
    this.request.product_dosage = this.productform.controls[
      'product_dosage'
    ].value;
    if (this.hairLengthArray.length == 0) this.request.hair_length = [];
    else this.request.hair_length = this.hairLengthArray;
    if (this.hairTypehArray.length == 0) this.request.hair_type = [];
    else this.request.hair_type = this.hairTypehArray;
    if (this.typeOfHairArray.length == 0) this.request.hair_list_id = [];
    else this.request.hair_list_id = this.typeOfHairArray;
    if (this.skinArray.length == 0) this.request.skin_list_id = [];
    else this.request.skin_list_id = this.skinArray;
    if (this.tagarray.length == 0) this.request.tag_id = [];
    else this.request.tag_id = this.tagarray;
    if (this.hairColorArray.length == 0) this.request.hair_color = [];
    else this.request.hair_color = this.hairColorArray;
    if (this.ageArray.length == 0) this.request.age = [];
    else this.request.age = this.ageArray;
    if (this.seasonListArray.length == 0) this.request.season = [];
    else this.request.session = this.seasonListArray;

    // if (this.productform.controls.salon_id.invalid) {
    //   $('.multiselect-dropdown .dropdown-btn').addClass('multi-select-error');
    // } else {
    //   $('.multiselect-dropdown .dropdown-btn').removeClass(
    //     'multi-select-error'
    //   );
    // }
    // if (this.productform.invalid) {
    //   return;
    // }

    // this.salonArray = this.productform.controls['salon_id'].value.map(
    //   (item: any) => item.id
    // );
    if (this.salonArray.length == 0) {
      let assignSalon = this.translateService.instant('product.salonAssignMsg');
      this.alertService.showDangerAlert('Error', assignSalon);
      return;
    }
    this.request.salon_id = this.salonArray;
    //console.log(JSON.stringify(this.request));
    formData.append('request', JSON.stringify(this.request));
    this.submitted = true;
    if (this.isAddMode) {
      this.createProduct(formData);
    } else {
      this.updateProduct(formData);
    }
  }

  createProduct(formData: any) {
    this.productService.add(formData).subscribe(
      (data) => {
        if (data['is_error'] == true) {
          let productExist = this.translateService.instant(
            'product.productExistMsg'
          );
          this.alertService.showSuccessAlert('Error', productExist);
        } else {
          this.router.navigate(['productList']);
          let productadded = this.translateService.instant(
            'product.productAddedMsg'
          );
          this.alertService.showSuccessAlert(this.successDialog, productadded);
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  editProduct(id: any) {
    this.selectedItems = [];
    this.productService.getById(id).subscribe(
      (data) => {
        this.editData = data['data'][0];
        this.imgurl = this.editData.image_path_name;
        this.formValidation.product_name.setValue(this.editData.product_name);
        this.formValidation.product_price.setValue(this.editData.product_price);
        this.formValidation.product_brand.setValue(this.editData.product_brand);
        this.formValidation.treatment_type.setValue(this.editData.treatment_type);
        this.formValidation.product_description.setValue(
          this.editData.product_description
        );
        this.formValidation.product_dosage.setValue(
          this.editData.product_dosage
        );

        this.tagarray = this.editData.tag_id;
        this.salonDisplayArray = [];
        this.salonArray = [];

        for (var i = 0; i < this.editData.salon_id.length; i++) {
          this.salonDisplayArray.push({
            id: this.editData.salon_id[i].id,
            name: this.editData.salon_id[i].salonName,
          });
          this.salonArray.push(this.editData.salon_id[i].id);
        }
        this.salonTempArray = this.salonDisplayArray;
        // setTimeout(() => {
        //   if (this.editData['data'].salon_id.length > 0) {
        //     this.editData['data'].salon_id.forEach((item: any) => {
        //       const filterItem: any = this.salonList.find((element: any) => {
        //         return element.id == item;
        //       });
        //       if (filterItem) {
        //         this.selectedItems.push(filterItem);
        //         let mapItems: any = this.selectedItems.map((item: any) => {
        //           return {
        //             salonName: item['salonName'],
        //             id: item['id'],
        //           };
        //         });
        //         this.selectedItems = mapItems;
        //       }
        //     });
        //   }
        // }, 1000);

        for (var i = 0; i < this.editData.hair_list_id.length; i++) {
          this.typeOfHairArraychecked.push(
            this.editData.hair_list_id[i].hair_type_id
          );

          this.typeOfHairArray.push({
            hair_type_name: this.editData.hair_list_id[i].hair_type_name,
            id: this.editData.hair_list_id[i].hair_type_id,
            hair_type_id: this.editData.hair_list_id[i].hair_type_id,
          });
        }
        for (let j = 0; j < this.editData.session.length; j++) {
          this.seasonListArray.push(this.editData.session[j]);
        }
        this.skinArray = [];
        this.skinToneArray = [];
        for (var i = 0; i < this.editData.skin_list_id.length; i++) {
          this.skinNewArray.push(
            this.editData.skin_list_id[i].skin_type_id +
            '-' +
            this.editData.skin_list_id[i].skin_tone_type
          );
          this.skinArray.push({
            id: this.editData.skin_list_id[i].skin_type_id,
            skin_type_id: this.editData.skin_list_id[i].skin_type_id,
            skin_type_name: this.editData.skin_list_id[i].skin_type_name,
            skin_tone_type: this.editData.skin_list_id[i].skin_tone_type,
          });
          this.skinToneArray.push(this.editData.skin_list_id[i].skin_type_id);
        }

        this.hairLengthArray = this.editData.hair_length;
        this.hairTypehArray = this.editData.hair_type;
        this.hairColorArray = this.editData.hair_color;
        this.ageArray = this.editData.age;
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        // this.alertService.showDangerAlert('Error', 'Something went wrong while adding an salon detail.');
      }
    );
  }
  updateProduct(formData: any) {
    this.productService.update(this.productId, formData).subscribe(
      (data) => {
        this.router.navigate(['productList']);
        let productUpdate = this.translateService.instant(
          'product.productUpdateMsg'
        );
        this.alertService.showSuccessAlert(this.successDialog, productUpdate);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  productForm() {
    this.productform = this.formBuilder.group({
      image_path_name: ['', Validators.required], //image_original_name
      fileSource: [''],
      product_name: ['', Validators.required],
      product_brand: ['', Validators.required],
      product_price: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(.[0-9]{1,9})?$'
          ),
        ],
      ],
      product_dosage: [''],
      product_description: [''],
      treatment_type: [],
      type_of_hair: [''], //hair_list_id
      skin: [''], //skin_list_id
      tags: [''], //tag_id
      hair_length: [''],
      hair_type: [''],
      hair_color: [''],
      age: [''],
      salon_id: ['', Validators.required],
      skin_tone_type: [''],
      session: [''],
    });
  }

  editproductForm() {
    this.productform = this.formBuilder.group({
      image_path_name: [''], //image_original_name
      fileSource: [''],
      product_name: ['', Validators.required],
      product_brand: ['', Validators.required],
      product_price: ['', Validators.required],
      product_dosage: [''],
      product_description: [''],
      treatment_type: [],
      type_of_hair: [''], //hair_list_id
      skin: [''], //skin_list_id
      tags: [''], //tag_id
      hair_length: [''],
      hair_type: [''],
      hair_color: [''],
      age: [''],
      salon_id: [[], Validators.required],
      session: [''],
    });
  }
  onChangeSeason(index: number, _id: number, isChecked: any, season: any) {
    if (isChecked.target.checked) {
      this.seasonListArray.push(_id);
    } else {
      this.seasonListArray.splice(this.seasonListArray.indexOf(_id), 1);
    }
    // console.log(this.seasonListArray);
    this.formValidation.session.setValue(this.seasonListArray);
  }

  typeOfHairChange(
    index: number,
    _id: any,
    isChecked: any,
    hair_type_name: any
  ) {
    let hair_length_arry = [{ id: '' }];
    if (isChecked.target.checked) {
      this.typeOfHairArray.push({
        id: _id,
        hair_type_id: _id,
        hair_type_name: hair_type_name,
      });
    } else {
      hair_length_arry = this.typeOfHairArray;
      hair_length_arry.forEach((value, index) => {
        if (value.id == _id) hair_length_arry.splice(index, 1);
      });
      this.typeOfHairArray = hair_length_arry;
    }
    // console.log(this.typeOfHairArray);
    this.formValidation.type_of_hair.setValue(this.typeOfHairArray);
  }

  onChangeHairColor(index: number, _id: number, isChecked: any) {
    if (isChecked.target.checked) {
      this.hairColorArray.push(_id);
    } else {
      this.hairColorArray.splice(this.hairColorArray.indexOf(_id), 1);
    }
    this.formValidation.hair_color.setValue(this.hairColorArray);
    // console.log(this.formValidation.hair_color);
  }

  onChangeHairType(index: number, _id: number, isChecked: any) {
    if (isChecked.target.checked) {
      this.hairTypehArray.push(_id);
    } else {
      this.hairTypehArray.splice(this.hairTypehArray.indexOf(_id), 1);
    }
    // console.log(this.hairTypehArray);
    this.formValidation.hair_type.setValue(this.hairTypehArray);
  }
  onChangeAge(index: number, _id: number, isChecked: any) {
    if (isChecked.target.checked) {
      this.ageArray.push(_id);
    } else {
      this.ageArray.splice(this.ageArray.indexOf(_id), 1);
    }
    this.formValidation.age.setValue(this.ageArray);
  }

  onChangeHairLength(index: number, _id: number, isChecked: any) {
    if (isChecked.target.checked) {
      this.hairLengthArray.push(_id);
    } else {
      this.hairLengthArray.splice(this.hairLengthArray.indexOf(_id), 1);
    }
    this.formValidation.hair_length.setValue(this.hairLengthArray);
  }

  onChangeTag(index: number, _id: string, isChecked: any, tagname: any) {
    if (isChecked.target.checked) {
      this.tagarray.push(_id);
    } else {
      this.tagarray.splice(this.tagarray.indexOf(_id), 1);
    }
    // console.log(this.tagarray);
    this.formValidation.tags.setValue(this.tagarray);
  }

  onChangeSkin(
    index: number,
    _id: any,
    isChecked: any,
    skin_tone_type: any,
    skin_type_name: any
  ) {
    let hair_length_arry = [{ id: '', 'skin_tone_type': null }];
    // console.log(this.skin_tone_temp);
    if (isChecked.target.checked) {
      if (this.skinToneArray.includes(_id)) {
        hair_length_arry = this.skinArray;
        hair_length_arry.forEach((value, index) => {
          if (value.id == _id && value['skin_tone_type'] == skin_tone_type) hair_length_arry.splice(index, 1);
        });
        this.skinArray = hair_length_arry;
        this.skinArray.push({
          id: _id,
          skin_type_id: _id,
          skin_type_name: skin_type_name,
          skin_tone_type: skin_tone_type,
        });
      } else {
        this.skinArray.push({
          id: _id,
          skin_type_id: _id,
          skin_type_name: skin_type_name,
          skin_tone_type: skin_tone_type,
        });
        this.skinToneArray.push(_id);
      }
    } else {
      hair_length_arry = this.skinArray;
      hair_length_arry.forEach((value, index) => {
        if (value.id == _id && value['skin_tone_type'] == skin_tone_type) hair_length_arry.splice(index, 1);
      });
      this.skinArray = hair_length_arry;
    }

    //console.log(this.skinToneArray);
    // console.log(this.skinArray);
    this.formValidation.skin.setValue(this.skinArray);
  }
  // getSkinList() {
  //   this.healthService.getSkinList().subscribe(
  //     (data) => {
  //       if (data.is_error == false) {
  //         this.skinList = data.data;
  //       } else {
  //         this.alertService.showDangerAlert('Error', 'Skin name not exist.');
  //       }
  //     },
  //     (error: HttpErrorResponse) => {
  //       console.log(error.name + ' ' + error.message);
  //       this.alertService.showDangerAlert(
  //         'Error',
  //         'Something went wrong while loading skin list.'
  //       );
  //     }
  //   );
  // }

  // getHairList(){
  //   this.healthService.getHairList().subscribe((data) => {
  //     if (data.is_error == false) {
  //         this.hairList =data.data;
  //          }
  //     else
  //     {
  //      this.alertService.showDangerAlert('Error', 'Hair type not exist.');
  //     }
  // }, (error: HttpErrorResponse) => {
  //    console.log(error.name + ' ' + error.message);
  //    this.alertService.showDangerAlert('Error', 'Something went wrong while loading hair list.');
  // });
  // }
  getTagList() {
    this.ProducttagService.getTagList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.tagList = data.data;
        } else {
          //  this.alertService.showDangerAlert('Error', 'tag not exist.');
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  onFileChange($event: any) {
    const files = $event.target.files || $event.srcElement.files;
    const file = files[0];
    const type = this.checkExtension(file);
    if (file && type != -1) {
      this.image = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        this.imageUrl = event.target.result;
      };
    } else {
      let imageValidation = this.translateService.instant('imageValidation');
      this.alertService.showDangerAlert(this.errorDialog, imageValidation);
    }
  }
  checkExtension(file: any) {
    let mimeType = ['image/png', 'image/jpeg', 'image/jpg'];
    return mimeType.indexOf(file.type);
  }
  addSaloonList() {
    this.salonDisplayArray = [];
    this.salonDisplayArray = this.salonTempArray;
    this.formValidation.salon_id.setValue(this.salonArray);
    this.addsalonFlag = false;
  }
  onChangesalon(index: any, _id: any, isChecked: any, name: any) {
    this.salonDisplayArray = [];
    let salon_array = [{ id: '' }];
    if (isChecked.target.checked) {
      this.salonArray.push(_id);
      this.salonTempArray.push({
        id: _id,
        name: name,
      });
    } else {
      this.salonArray.splice(this.salonArray.indexOf(_id), 1);
      salon_array = this.salonTempArray;
      salon_array.forEach((value, index) => {
        if (value.id == _id) salon_array.splice(index, 1);
      });
      this.salonTempArray = salon_array;
    }
    // console.log(this.salonTempArray);
    // console.log(this.salonArray);
  }

  // onItemSelect(item: any) {
  //   $('.multiselect-dropdown .dropdown-btn').removeClass('multi-select-error');
  // }

  // onItemDeSelect(item: any) {
  //   if (this.formValidation.salon_id.errors !== null) {
  //     $('.multiselect-dropdown .dropdown-btn').addClass('multi-select-error');
  //   }
  //}

  searchBySalon(name: any) {
    this.searchsalon.salonName = name;
    this.productService.getSalonListByName(this.searchsalon).subscribe(
      (data) => {
        if (data.is_error == false) {
          // console.log(data.data);
          this.salonList = data.data;
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
}
