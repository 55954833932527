import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { Router } from '@angular/router';
import { CustomerService  } from '../customer/customer.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import AppConstants from 'src/app/shared/commons/app.constants';
import { DataTableDirective } from 'angular-datatables';
import * as $ from 'jquery'
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {
  customerList:any=[];
  currentPlayingVideo: any;
  editId:any=[];
  videoFlag=false;
  errorDialog:any;
  somethingWrong:any;
  successDialog:any;
  deleteId:any;
  @ViewChild(DataTableDirective,{static: false}) datatableElement!: DataTableDirective;
  dtOptions: DataTables.Settings = {}; 
  dtTrigger: Subject<any> = new Subject<any>();
  title = 'datatables';
  customerForm = new FormGroup({});
  editcustomerFlag=false;
  customerDetail:any={};
  videoPath:any='';
  baseURL = AppConstants.SERVICE_BASE_URL;
  role:any='';
  create_date:any;
  name : any;
  surname : any;
  path : any;
 
  constructor(private formBuilder: FormBuilder,public customerService: CustomerService,public router: Router,private translateService: TranslateService, public alertService: AlertService) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      order:[]
    };
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.getAllCustomer();
    
  }
  createform() {
    this.customerForm = this.formBuilder.group({
      name: [{ value: '', disabled: false }, Validators.required],
      surname: [''],
    });
  }
  get formValidation() {
    return this.customerForm.controls;
  }
  getAllCustomer()
  { this.customerList=[];
    this.customerService.getCustomerList().subscribe(
      (data) => {
      //  console.log(data);
        if (data['is_error'] == false) {      
          for(var i=0;i<data['data'].length;i++)
          {
            var str = data['data'][i]['customer_name'];
             var splits = str.split(" ");  
             this.customerList.push({'name':splits[0],'video_path_name':data['data'][i].video_path_name,'surname':splits[1],'customer_detail':data['data'][i]})

          }
          
          console.log(this.customerList);
          this.dtTrigger.next();
         // this.rerender();      
        } 
      },
      (error: HttpErrorResponse) => {
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  // ngAfterViewInit(): void {
  //   this.dtOptions = {
  //     pagingType: 'full_numbers',
  //     pageLength: 10,
  //     processing: true,
  //   };
  //   this.dtTrigger.next();
  // }

editCustomer(id :any,name:any,surname:any){
  this.createform();
  this.editId = id;
  this.editcustomerFlag=true;  
  this.formValidation.name.setValue(name);
  this.formValidation.surname.setValue(surname);
  }
  showModal(id:any)
  {
    this.deleteId = id;
  }
  showuser(create_date:any,name:any,surname:any, path:any)
  {
    this.create_date = create_date;
    this.name = name;
    this.surname = surname;    
    this.path = path;
    console.log('path',this.path);
    
  }
  deleteCustomer(id:any)
  {
    this.customerService.delete(id).subscribe(
      (data) => {
       // this.dtTrigger.unsubscribe();
        let DeleteMsg = this.translateService.instant('customer.customerdeleteMsg');
        this.alertService.showSuccessAlert(this.successDialog, DeleteMsg);
        setTimeout(() => {
          this.rerender();
        }, 1000);
      },
      (error: HttpErrorResponse) => {
      //  console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );

  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  submitForm(){
   // this.submitted = true;   
    if (this.customerForm.invalid) {
      return;
    } 
    var str3 = ''; 
    var str1 = this.formValidation['name'].value;
    if(this.formValidation['surname'].value) 
    {
      var str2 =this.formValidation['surname'].value; 
       str3 = str1.concat(" ",str2.toString()); 
       //str3=str1;
    }else{
       str3=str1;
    } 
    this.customerDetail.customer_name=str3;   
    this.customerService.update(this.editId,this.customerDetail).subscribe(
      (data) => {     
        // this.dtTrigger.unsubscribe();
        // this.dtTrigger = new Subject();     
        // this.dtOptions = {
        //   pagingType: 'full_numbers',
        //   pageLength: 10,
        //   processing: true,
        // };
          
        let msg = this.translateService.instant('customer.customerupdateMsg');     
        this.alertService.showSuccessAlert(this.successDialog, msg);
        setTimeout(() => {
          this.rerender();
        }, 1000);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    // Destroy the table first
    dtInstance.destroy();
    // Call the dtTrigger to rerender again
    this.getAllCustomer();
    //this.dtTrigger.next();
      });
    }
    showVideo(path: any) {
      this.videoPath = '';
      if(path){
      this.videoFlag = true;
      setTimeout(() => {
        this.videoPath = path;
      });
    }else{
      this.videoFlag = false;
    }
    }
  onPlayingVideo(event: any) {
    this.currentPlayingVideo = event.target;
  }

  pauseVideo() {
    this.currentPlayingVideo.pause();
  }
  getVideoURL() {
    return this.baseURL + this.videoPath;
  }
}