import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { Router } from '@angular/router';
import { OrderService  } from '../../order/order.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-editorder',
  templateUrl: './editorder.component.html',
  styleUrls: ['./editorder.component.css']
})
export class EditorderComponent implements OnInit {



orderId:any='';

  constructor() { }

  ngOnInit(): void {
 

}


}
