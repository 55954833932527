import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { ProductService } from '../product/product.service';
import AppConstants from 'src/app/shared/commons/app.constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HealthService } from '../health/health.service';
import { TranslateService } from '@ngx-translate/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css'],
})
export class HealthComponent implements OnInit {
  skin_product = false;
  lifestyle_product = false;
  hairwashfrequency_product = false;
  lifehairwashpostdata = { 'product_id': [], 'life_style': null, 'hair_wash_frequency': null }
  lifehair_product = false;
  postlifestyleData: any = {};
  posthairwashfreqData: any = {};
  searchProduct: any = {};
  hair_product = false;
  product_checked = false;
  postData: any = { "hair_data": [] };
  hari_type_id: any = { "hari_type_id": [] }
  hairProductarray: any = [];
  skinProductarray: any = [[]];
  ProductIdArray: any = [];
  ProductArray: any = [];
  productList: any;
  submitted = false;
  submittedHairForm = false;
  skinList = AppConstants.skinList;
  hairList = AppConstants.HairList;
  lifestyleList = AppConstants.lifestyleList;
  hairwashfrequencyList = AppConstants.hairwashfrequencyList;
  skinPostData: any = { "skin_data": [] };
  skinForm = new FormGroup({});
  hairForm = new FormGroup({});
  baseURL = AppConstants.SERVICE_BASE_URL;
  skintypevalue: any = [1, 2, 3];
  errorMessageProduct = '';
  errorMessageSkin = '';
  errorMessageHair = '';
  errorDialog: any;
  successDialog: any;
  somethingWrong: any;
  skindata: any = [[]]
  constructor(
    private translateService: TranslateService,
    public router: Router,
    public productService: ProductService,
    public alertService: AlertService,
    private formBuilder: FormBuilder,
    public healthService: HealthService
  ) {
  }

  ngOnInit(): void {
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.createSkinform();
    this.createHairform();
    this.getAllProduct();
    for (let i = 0; i < this.skinList.length; i++) {
      //this.skinPostData["skin_data"].push('');
      this.skindata[i] = [];
      // this.skinProductarray.push([])
      for (let j = 0; j < this.skintypevalue.length; j++) {
        this.skindata[i].push([]);
      }
    }
    for (let j = 0; j < this.hairList.length; j++) {
      this.postData["hair_data"].push('');
    }
    // this.getSkinList();
    //this.getHairList();
  }
  /*
Skin form
*/
  createSkinform() {
    this.skinForm = this.formBuilder.group({
      skin_name: ['', Validators.required],
    });
  }

  submitSkinForm() {
    this.submitted = true;
    if (this.skinForm.invalid) {
      return;
    }
    this.healthService.add(this.skinForm.value).subscribe(
      (data) => {
        if (data['is_error'] == true) {
          let skinExist = this.translateService.instant('healthPro.skinExist');
          this.alertService.showDangerAlert(this.errorDialog, skinExist);
        } else {
          let skinAdd = this.translateService.instant('healthPro.skinAdded');
          this.alertService.showSuccessAlert(this.successDialog, skinAdd);
        }
        this.skinList = AppConstants.skinList;
        //this.getSkinList();
        this.getAllProduct();
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }

  getSkinList() {
    this.healthService.getSkinList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.skinList = data.data;
        } else {
          let skinnotExist = this.translateService.instant(
            'healthPro.skinnotExist'
          );
          this.alertService.showDangerAlert(this.errorDialog, skinnotExist);
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  get formValidation() {
    return this.skinForm.controls;
  }

  onChangeSkin(
    index: number,
    _id: number,
    isChecked: any,
    number: any,
    skin_name: any,
    innerIndex: number
  ) {
    this.hair_product = false;
    this.skin_product = true;
    this.lifehair_product = false;
    this.ProductIdArray = [];
    if (isChecked.target.checked) {
      // this.skinProductarray[index][innerIndex]=_id;
      //this.skinPostData["skin_data"][index]={skin_type_id:_id,skin_tone_type:number,skin_type_name:skin_name};
      this.skindata[index][innerIndex] = { skin_type_id: _id, skin_tone_type: number, skin_type_name: skin_name };
      // console.log('skindata',this.skindata)
    } else {

      this.skindata[index][innerIndex] = []
      //this.skindata[index].push([]);

      // console.log('skindatas',this.skindata)
    }
    // else{
    //   this.skinProductarray[index].splice(innerIndex,1)
    // }
    // else {
    //   if (this.skinPostData["value"].indexOf(index) == 0) {
    //     this.skinPostData["value"].splice(this.skinPostData.value.indexOf(index), 1);
    //     this.skinProductarray.splice(index,1);
    //   }

    //   else {
    //     //this.skinProductarray.splice(this.skinProductarray.indexOf(index) - 1, 1);
    //     this.skinPostData["value"].splice(this.skinPostData.value.indexOf(index)-1, 1);
    //    // this.skinProductarray.splice(index,1);
    //   }
    // }
    // console.log(this.skinProductarray);
    //  console.log(this.skinPostData["skin_data"]);
    //let skindata = this.skinPostData["skin_data"].filter((el:any)=>{return el});
    this.skinPostData['skin_data'] = []
    this.skindata.forEach((res: any, i: number) => {
      if (res) {
        res.forEach((res1: any) => {
          if (res1 && res1.skin_type_id) {
            this.skinPostData['skin_data'].push(res1)
          }
        })
      }

    })
    let skin_data = {
      "skin_data": this.skinPostData['skin_data']
    }
    // this.skinPostData['skin_type_id'] = _id;
    // this.skinPostData['skin_tone_type'] = number;
    // this.skinPostData['skin_type_name'] = skin_name;
    this.healthService.getProductSkinType(skin_data).subscribe(
      (data) => {
        if (data['data'].length != 0) {
          if (data['is_error'] == false) {
            for (var i = 0; i < data['data'].length; i++) {
              this.product_checked = true;
              this.ProductIdArray.push(data['data'][i].id);
            }
          }
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }

  /*
Hair form
*/
  createHairform() {
    this.hairForm = this.formBuilder.group({
      hair_name: ['', Validators.required],
    });
  }

  submitHairForm() {
    this.submittedHairForm = true;
    if (this.hairForm.invalid) {
      return;
    }
    this.healthService.addHair(this.hairForm.value).subscribe(
      (data) => {
        if (data['is_error'] == true) {
          this.alertService.showDangerAlert(
            'Error',
            'Hair type already exist.'
          );
        } else {
          this.alertService.showSuccessAlert(
            'success',
            'Hair type added successfully.'
          );
        }

        //this.getHairList();
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          'Error',
          'Something went wrong while adding hair Type.'
        );
      }
    );
  }

  getHairList() {
    this.healthService.getHairList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.hairList = data.data;
        } else {
          let hairNotExist = this.translateService.instant('hairnotExist');
          this.alertService.showDangerAlert(this.errorDialog, hairNotExist);
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  get hairFormValidation() {
    return this.hairForm.controls;
  }

  onChangeHair(index: number, _id: number, isChecked: any, hairname: any) {
    this.skin_product = false;
    this.hair_product = true;
    this.lifehair_product = false;
    this.ProductIdArray = [];
    this.hari_type_id = { 'hair_type_id': [] }
    if (isChecked.target.checked) {
      this.postData['hair_data'][index] = { 'hair_type_id': _id, 'hair_type_name': hairname }
      // this.postData['hair_type_id'] = _id;
      // this.postData['hair_type_name'] = hairname;
      this.postData['hair_data'].forEach((res: any) => {
        if (res) {
          this.hari_type_id['hair_type_id'].push(res["hair_type_id"])
        }
      })

    } else {
      this.postData['hair_data'][index] = '';
      //this.postData['hair_data'].push('');
      this.postData['hair_data'].forEach((res: any) => {
        if (res) {
          this.hari_type_id['hair_type_id'].push(res["hair_type_id"])
        }
      })
    }


    this.healthService.getProductHairType(this.hari_type_id).subscribe(
      (data) => {
        if (data['data'] && data['data'].length != 0) {
          if (data['is_error'] == false) {
            // console.log(data['data'][0].product_id);
            for (var i = 0; i < data['data'].length; i++) {
              this.product_checked = true;
              this.ProductIdArray.push(data['data'][i].id);
            }
            //console.log(this.ProductIdArray);
          }
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
    //this.formValidation.tags.setValue(this.hairProductarray);
  }

 
  onChangeLifestyle(index: number, _id: number, isChecked: any, lifestylename: any) {
    this.lifestyleList.forEach(val => {
      if (val.id == _id) val.isSelected = !val.isSelected;
      else {
        val.isSelected = false;
      }
    });
    this.lifestyle_product = true;
    this.hairwashfrequency_product = false;
    this.skin_product = false;
    this.hair_product = false;
    this.lifehair_product = true;
    this.ProductIdArray = [];
    this.postlifestyleData['life_style'] = _id
    let where = {
      "where": { "life_style": _id, "hair_wash_frequency": this.posthairwashfreqData['hair_wash_frequency'] }
    }
    this.healthService.getProductByLifstleOrHairWashFrequency(where).subscribe(
      (data) => {
        if (data['data'].length != 0) {
          if (data['is_error'] == false) {
            // console.log(data['data'][0].product_id);
            for (var i = 0; i < data['data'].length; i++) {
              this.product_checked = true;
              this.ProductIdArray.push(data['data'][i].id);
            }
            //console.log(this.ProductIdArray);
          }
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );

  }

  onChangeHairWashFrequency(index: number, _id: number, isChecked: any, hairfrequencyname: any) {
    this.lifestyle_product = false;
    this.hairwashfrequency_product = true;
    this.skin_product = false;
    this.hair_product = false;
    this.lifehair_product = true;
    this.ProductIdArray = [];
    this.posthairwashfreqData['hair_wash_frequency'] = _id;
    let where = {
      "where": { "hair_wash_frequency": _id, "life_style": this.postlifestyleData['life_style'] }
    }
    this.healthService.getProductByLifstleOrHairWashFrequency(where).subscribe(
      (data) => {
        if (data['data'].length != 0) {
          if (data['is_error'] == false) {
            // console.log(data['data'][0].product_id);
            for (var i = 0; i < data['data'].length; i++) {
              this.product_checked = true;
              this.ProductIdArray.push(data['data'][i].id);
            }
            //console.log(this.ProductIdArray);
          }
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }

  getAllProduct() {
    this.productService.getProductList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.productList = data.data;
        } else {
          // this.alertService.showDangerAlert(this.errorDialog, 'Product not exist.');
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  formRefresh() {
    this.hairList = [];
    this.lifestyleList = [];
    this.hairwashfrequencyList = []
    this.skintypevalue = [];
    this.ProductIdArray = [];
    this.product_checked = false;
    this.skin_product = false;
    this.hair_product = false;
    this.lifehair_product = false;;
    this.skinPostData = { "skin_data": [] };
    this.postData = { 'hair_data': [] }
    this.skindata = [[]];


    setTimeout(() => {
      this.skintypevalue = [1, 2, 3];
      this.hairList = AppConstants.HairList;
      this.lifestyleList = AppConstants.lifestyleList;
      this.lifestyleList.map((item: any) => {
        return item['isSelected'] = false;
      });
      // this.lifestyleList = this.lifestyleList;
      this.hairwashfrequencyList = AppConstants.hairwashfrequencyList;
      for (let j = 0; j < this.hairList.length; j++) {
        this.postData["hair_data"].push('');
      }
      for (let i = 0; i < this.skinList.length; i++) {
        //this.skinPostData["skin_data"].push('');
        this.skindata[i] = [];
        // this.skinProductarray.push([])
        for (let j = 0; j < this.skintypevalue.length; j++) {
          this.skindata[i].push([]);
        }
      }
    }, 100);

    this.formValidation.skin_name.setValue('');
  }
  onSubmitHirSkinPro() {
    //  console.log(this.postData);
    if (!this.product_checked) {
      this.errorMessageProduct = this.translateService.instant(
        'healthPro.selectOneProduct'
      );
      return;
    }
    if (this.skin_product) {
      this.skinPostData['skin_data'] = []
      this.skindata.forEach((res: any, i: number) => {
        if (res) {
          res.forEach((res1: any) => {
            if (res1 && res1.skin_type_id) {
              this.skinPostData['skin_data'].push(res1)
            }
          })
        }

      })
      let skindata = this.skinPostData["skin_data"].filter((el: any) => { return el })
      this.skinPostData["skin_data"] = skindata;
      this.skinPostData['product_id'] = this.ProductIdArray;
      this.healthService.addSkinProduct(this.skinPostData).subscribe(
        (data) => {
          let skinMsg = this.translateService.instant(
            'healthPro.skinAssignProduct'
          );
          this.alertService.showSuccessAlert(this.successDialog, skinMsg);
          setTimeout(() => {
            this.formRefresh();
          }, 1000);
        },
        (error: HttpErrorResponse) => {
          // console.log(error.name + ' ' + error.message);
          this.alertService.showDangerAlert(
            this.errorDialog,
            this.somethingWrong
          );
        }
      );
    } else if (this.hair_product) {
      this.postData.hair_data = this.postData.hair_data.filter((el: any) => { return el })
      this.postData['product_id'] = this.ProductIdArray;
      this.healthService.addHairProduct(this.postData).subscribe(
        (data) => {
          let hairAssign = this.translateService.instant(
            'healthPro.hairAssignProduct'
          );
          this.alertService.showSuccessAlert(this.successDialog, hairAssign);
          setTimeout(() => {
            this.formRefresh();
          }, 1000);
        },
        (error: HttpErrorResponse) => {
          // console.log(error.name + ' ' + error.message);
          this.alertService.showDangerAlert(
            this.errorDialog,
            this.somethingWrong
          );
        }
      );
    } else if (this.lifehair_product) {

      this.lifehairwashpostdata.life_style = this.postlifestyleData['life_style'];
      this.lifehairwashpostdata.hair_wash_frequency = this.posthairwashfreqData['hair_wash_frequency']
      this.lifehairwashpostdata['product_id'] = this.ProductIdArray;
      this.healthService.addUpdateLifestyleOrHairwashFrequncy(this.lifehairwashpostdata).subscribe((data: any) => {
        let lifeAssign = this.translateService.instant(
          'healthPro.lifestayleAssignProduct'
        );
        this.alertService.showSuccessAlert(this.successDialog, lifeAssign);
        setTimeout(() => {
          this.formRefresh();
        }, 1000);
      },
        (error: HttpErrorResponse) => {
          // console.log(error.name + ' ' + error.message);
          this.alertService.showDangerAlert(
            this.errorDialog,
            this.somethingWrong
          );
        });
    }
    //   else if(this.hairwashfrequency_product){
    //     this.posthairwashfreqData['product_id'] = this.ProductIdArray;
    //     this.healthService.addUpdateLifestyleOrHairwashFrequncy(this.posthairwashfreqData).subscribe((data:any)=>{
    //       let hariwashAssign = this.translateService.instant(
    //         'healthPro.hairwashfrequncyAssignProduct'
    //       );
    //       this.alertService.showSuccessAlert(this.successDialog, hariwashAssign);
    //       setTimeout(() => {
    //         this.formRefresh();
    //       }, 1000);
    //     },
    //     (error: HttpErrorResponse) => {
    //       console.log(error.name + ' ' + error.message);
    //       this.alertService.showDangerAlert(
    //         this.errorDialog,
    //         this.somethingWrong
    //       );
    //   });
    // }
    else {
      let hairSkin = this.translateService.instant('healthPro.hairSkinMsg');
      this.errorMessageSkin = hairSkin;
    }
  }
  onChangeProduct(index: number, _id: string, isChecked: any) {
    this.product_checked = true;
    if (isChecked.target.checked) {
      this.ProductIdArray.push(_id);
    } else {
      this.ProductIdArray.splice(this.ProductIdArray.indexOf(_id), 1);
    }
  }

  searchByProduct(name: any) {
    this.searchProduct.product_name = name;
    // console.log(name);
    this.healthService.getProductListByProduct(this.searchProduct).subscribe(
      (data) => {
        //  console.log(data.is_error);
        if (data.is_error == false) {
          this.productList = data.data;
        }
        //console.log( this.tagList)
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
}
