import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireAuth } from '@angular/fire/auth';
import {AuthService} from '../services/auth.service'
import { BehaviorSubject, Subject } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage:Subject<any> = new Subject<any>();
  constructor(private angularFireAuth: AngularFireAuth,private angularFireMessaging: AngularFireMessaging,private authService:AuthService) {
  this.angularFireMessaging.messages.subscribe(
  (_messaging:any) => {
  _messaging.onMessage = _messaging.onMessage.bind(_messaging);
  _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
  // console.log('token',_messaging.onTokenRefresh)
  }
  )
  }
  requestPermission() {
  let id= localStorage.getItem('userId')
  this.angularFireMessaging.requestToken.subscribe(
  (token:any) => {
  // console.log('in',token);
  let notiFytoken = {"notificationTokens":token}
  this.authService.addPushNotificationdeviceToken(notiFytoken,id).subscribe((res:any)=>{
    if(res['is_error']==false){
      // console.log('updatetoken',res)
    }
  })
  },
  (err) => {
  // console.error('Unable to get permission to notify.', err);
  }
  );
  }
  receiveMessage() {
  this.angularFireMessaging.messages.subscribe(
  (payload:any) => {
  // console.log("new message received. ", payload);
  this.currentMessage.next(payload);
  })
  }
  }
