import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ProducttagService } from '../../product-tag/producttag.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { ViewChild } from '@angular/core';
@Component({
  selector: 'app-product-tag-list',
  templateUrl: './product-tag-list.component.html',
  styleUrls: ['./product-tag-list.component.css'],
})
export class ProductTagListComponent implements OnInit {
  tagList: any;
  errorDialog: any;
  somethingWrong: any;
  successDialog: any;
  submitted = false;
  @ViewChild(DataTableDirective,{static: false}) datatableElement!: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  deleteId: any;
  dtTrigger: Subject<any> = new Subject<any>();
  title = 'datatables';
  editId: any;
  editTagFlag = false;
  tagForm = new FormGroup({});
  constructor(
    private productTagService: ProducttagService,
    private formBuilder: FormBuilder,
    public router: Router,
    private translateService: TranslateService,
    public alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.getTagList();
  }

  getTagList() {
    this.tagList = [];
    this.productTagService.getTagList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.tagList = data.data;
          this.dtTrigger.next();
        }
      },
      (error: HttpErrorResponse) => {
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  back() {
    this.router.navigate(['productTagList']);
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  show(id: any) {
    this.deleteId = id;
  }
  deleteTag(id: any) {
    this.productTagService.delete(id).subscribe(
      (data) => {
        //this.dtTrigger.unsubscribe();
        let DeleteMsg = this.translateService.instant('tag.tagdeleteMsg');
        this.alertService.showSuccessAlert(this.successDialog, DeleteMsg);
        setTimeout(() => {
          this.rerender();
        }, 1000);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    // Destroy the table first
    dtInstance.destroy();
    // Call the dtTrigger to rerender again
    this.getTagList();
    //this.dtTrigger.next();
      });
    }
  createform() {
    this.tagForm = this.formBuilder.group({
      product_tag_name: [{ value: '', disabled: false }, Validators.required],
    });
  }
  editTag(id: any, tagname: any) {
    this.editTagFlag = true;
    this.editId = id;
    this.createform();
    this.formValidation.product_tag_name.setValue(tagname);
  }
  get formValidation() {
    return this.tagForm.controls;
  }
  submitTagForm() {
    this.submitted = true;
    if (this.tagForm.invalid) {
      return;
    }
    this.productTagService.update(this.editId, this.tagForm.value).subscribe(
      (data) => {
        this.router.navigate(['productTagList']);
        let msg = this.translateService.instant('tag.tagupdate');
        this.alertService.showSuccessAlert(this.successDialog, msg);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
}
