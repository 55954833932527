import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import AppConstants from 'src/app/shared/commons/app.constants';
@Injectable({
  providedIn: 'root',
})
export class ProducttagService {
  constructor(public httpClient: HttpClient) {}
  getTagList(): Observable<any> {
    return this.httpClient.get(
      AppConstants.SERVICE_BASE_URL + '/api/common/Product_Tag'
    );
  }
  getById(id: any) {
    return this.httpClient.get<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Product_Tag/' + id
    );
  }
  add(product: any) {
    return this.httpClient.put<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Product_Tag',
      product
    );
  }
  update(id: any, product: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Product_Tag/' + id,
      product
    );
  }
  delete(id: number) {
    return this.httpClient.delete<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/soft/Product_Tag/' + id
    );
  }
  getProductByTag(tag: any) {
    return this.httpClient.get<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Product_Tag/' + tag
    );
  }
  getProductByHair(hair: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL +
        '/api/product_tag/getProductByFilterValue',
      hair
    );
  }
  addTagProduct(tag: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/product_tag',
      tag
    );
  }
  addHairProduct(hair: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL + '/api/product_tag/assignProductToFilters',
      hair
    );
  }
}
