import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import AppConstants from 'src/app/shared/commons/app.constants';

@Injectable({
  providedIn: 'root'
})
export class NotificationlistService {

  constructor(public httpClient: HttpClient) { }
  getNotificationList(){
    return this.httpClient.get(AppConstants.SERVICE_BASE_URL+'/api/common/Notification');
  }
}
