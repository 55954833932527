<app-sidebar></app-sidebar>
<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <!-- <div class="col-12">
        <h1 class="page-title">
          {{ "productTag.producttag" | translate }}
        </h1>
      </div> -->
     
        <div class="col-6">
          <h1 class="page-title">
            {{ "productTag.producttag" | translate }}
          </h1>
        </div>
        <div class="col-6 text-right">
          
          <button
            class="btn btn-default"
            data-toggle="modal"        
            (click)="productTagListview()" 
          >
        {{ "productTag.viewproducttagList" | translate }}
          </button>
        </div>
     
    </div>
    <div class="tab-box tab-box-vertical pt-3" role="tabpanel">
      <!-- <form class="form" (submit)="onSubmitHirSkinPro()">  viewProductTagList-->
      <div>
        <div class="row">
          <div class="col-12 col-sm-7 pr-sm-2 position-relative">
            <ul
              class="nav nav-tabs nav-justified nav-tabs-dropdown main-tab"
              role="tablist"
            >
              <li role="presentation">
                <a
                  href="#tags"
                  aria-controls="tags"
                  role="tab"
                  data-toggle="tab"
                  class="active"
                  >{{ "tags" | translate }}</a
                >
              </li>
              <li role="presentation">
                <a
                  href="#filter"
                  aria-controls="filter"
                  role="tab"
                  data-toggle="tab"
                  >{{ "filter" | translate }}</a
                >
              </li>
            </ul>
            <div class="tab-content pt-3 pr-4">
              <div role="tabpanel" class="tab-pane tab-in active" id="tags">
                <div class="text-right pb-3">
                  <button
                    class="btn btn-default"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    (click)="addNewTag()"
                  >
                    {{ "tag.addNewTag" | translate }}
                  </button>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="product-scroll">
                      <div
                        class="checkbox-box flex-radio flex-checkbox-lg mt-0 mb-3"
                        *ngFor="let tag of tagList; let j = index"
                      >
                        <p class="m-0" style="width: 300px; font-size: 14px">
                          {{ tag.product_tag_name | titlecase }}
                        </p>
                        <div class="custom-control custom-radio">
                          <input
                            class="custom-control-input"
                            type="radio"
                            value="{{ tag.id }}"
                            name="tag"
                            id="{{ tag.id }}"
                            (change)="
                              onChangeProductTag(
                                j,
                                tag.id,
                                $event,
                                tag.product_tag_name
                              )
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="{{ tag.id }}"
                          >
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="text-right">
                <button  type="submit"  class="btn btn-default mr-2">Save</button>
                <button type="button" class="btn btn-default btn-secondary" >Cancel</button>
              </div> -->
              </div>
              <div role="tabpanel" class="tab-pane" id="filter">
                <!-- <div class="text-right pb-3"><button class="btn btn-default"  data-toggle="modal" data-target="#HairModalCenter" >Add New Hair Type</button>
              </div> -->
                <div class="row">
                  <div class="col-12">
                    <div class="product-scroll">
                      <div
                        class="checkbox-box flex-radio flex-checkbox-lg mt-0 mb-3"
                        *ngFor="
                          let productHairTag of productTagFilter;
                          let j = index
                        "
                      >
                        <p class="m-0" style="width: 300px; font-size: 14px">
                          {{ 'productTagFilterList.'+productHairTag.name | translate }}
                        </p>
                        <div class="custom-control custom-radio">
                          <input
                            class="custom-control-input"
                            type="radio"
                            value="{{ productHairTag.id }}"
                            name="productHairTag"
                            id="{{ productHairTag.id }}"
                            (change)="
                              onChangeProductHair(
                                j,
                                productHairTag.id,
                                $event,
                                productHairTag.field,
                                productHairTag.value
                              )
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="{{ productHairTag.id }}"
                          >
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="text-right">
                <button  type="submit"  class="btn btn-default mr-2">Save</button>
                <button type="button" class="btn btn-default btn-secondary" >Cancel</button>
              </div> -->
              </div>

              <span
                *ngIf="!tag_product && !hair_product"
                class="custom-error"
                >{{ errorMessageTag }}</span
              >
            </div>
          </div>
          <div class="col-12 col-sm-5 pt-5 mt-3 pl-sm-0">
            <div class="border-left pl-sm-3 m-border-0">
              <!-- <h5 class="mb-2 border-bottom">
                {{ "productTag.products" | translate }}
              </h5> -->
              <div class="row pb-2 mb-2 border-bottom">
                <div class="col-12 col-sm-5 ">
                <h5 class="">
                  {{ "products" | translate }}
                </h5> 
              </div>
              <div class="col-12 col-sm-7  pl-sm-0">
                <div class="search-input">              
                  <input
                    type="text"
                    placeholder="{{ 'search' | translate }}"
                    name="search"
                    #searchInput
                    (change)="searchByProduct(searchInput.value)"
                  />
                </div>
            </div>
            </div>
              <div class="product-scroll">
                <div
                  class="checkbox-box flex-radio flex-checkbox mt-0 mb-3"
                  *ngFor="let product of productList; let k = index"
                >
                  <p class="m-0 radio-title">
                    {{ product.product_name | titlecase }}
                  </p>
                  <div class="custom-control custom-checkbox">
                    <input
                      class="custom-control-input"
                      type="checkbox"
                      name="hairproductcheckbox"
                      id="{{ product.id }}"
                      value="{{ product.id }}"
                      [checked]="
                        ProductIdArray.includes(product.id) ? true : false
                      "
                      (change)="onChangeProduct(k, product.id, $event)"
                    />
                    <label class="custom-control-label" for="{{ product.id }}">
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <span *ngIf="!product_checked" class="custom-error">{{
              errorMessageProduct
            }}</span>
            <div class="text-right pt-3">
              <button
                type="button"
                (click)="onSubmitTagHairPro()"
                class="btn btn-default mr-2"
              >
                {{ "saveBtn" | translate }}
              </button>
              <!-- <button type="button" class="btn btn-default btn-secondary" >Cancel</button> -->
            </div>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>
  </div>
</div>
<ng-container *ngIf="addNewTagFlag">
  <div id="exampleModalCenter" class="modal fade">
    <div class="modal-dialog modal-dialog-centered modal-confirm">
      <div class="modal-content">
        <div class="modal-header flex-column">
          <!-- <div class="icon-box">
          <i class="fas fa-times"></i>
        </div>						 -->
          <h5 class="modal-title w-100 font-weight-bold text-center">
            {{ "tag.addNewTag" | translate }}
          </h5>
          <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
      </div> -->
        </div>
        <form
          autocomplete="off"
          novalidate
          [formGroup]="tagForm"
          (ngSubmit)="submitTagForm()"
        >
          <div class="modal-body">
            <div class="ng-autocomplete">
              <ng-autocomplete
                #ngAutoCompleteStatic
                [data]="tagDataList"
                formControlName="product_tag_name"
                [initialValue]=""
                [placeholder]="placeholder"
                [searchKeyword]="keyword"               
                [historyHeading]="historyHeading"
                [notFoundText]="not_found"
                [itemTemplate]="itemTemplateStatic"
                [notFoundTemplate]="notFoundTemplate"
              >
              </ng-autocomplete>
              <ng-template #itemTemplateStatic let-item>
                <a [innerHTML]="item"></a>
              </ng-template>
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <div>
              <button
                class="btn btn-default submitbtn mr-2"
                data-dismiss="modal"
                (click)="submitTagForm()"
                type="button"
                [disabled]="tagForm.invalid"
              >
                {{ "saveBtn" | translate }}
              </button>
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-default btn-secondary"
              >
                {{ "cancel" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
<!-- <div id="HairModalCenter" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h5 class="modal-title w-100 font-weight-bold text-center" > Add New Hair Type </h5>
      </div>
      <form autocomplete="off" novalidate [formGroup]="hairForm" (ngSubmit)="submitHairForm()">
      <div class="modal-body">
        <div class="row ">
          <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
            <label>
         Hair Type
            </label>
          </div>
          <div class="col-12 col-sm-12 col-xl-9">
            <div class="form-group input-group">
              <input type="text"  class="form-control small-control" formControlName="hair_name"  maxlength="25" pattern="^[a-zA-Z_\- ]+$"   placeholder="Hair Type" [ngClass]="{ 'is-invalid': submittedHairForm && hairFormValidation.hair_name.errors }" />
             <div *ngIf="submittedHairForm && hairFormValidation.hair_name.errors" class="invalid-feedback" >
                  <div *ngIf="hairFormValidation.hair_name.errors.required">Hair Type is required</div>
                  <div *ngIf="hairFormValidation.hair_name.errors.pattern"> Hair Type should contain only alphabate (a-z)</div>
              </div>
          </div>
          </div>
        </div>
          </div>
          <div class="modal-footer justify-content-center">
            <div>
              <button class="btn btn-default submitbtn mr-2"  type="submit">Submit</button>
              <button type="button" data-dismiss="modal" class="btn btn-default btn-secondary" >Cancel</button>
            </div>
          </div>
        </form>
    </div>
  </div>
</div>  -->
