<app-sidebar></app-sidebar>
<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-12">
        <h1 class="page-title">
          {{ "health" | translate }}
        </h1>
      </div>
    </div>
    <div class="tab-box tab-box-vertical pt-3" role="tabpanel">
      <!-- <form class="form" (submit)="onSubmitHirSkinPro()"> -->
      <div>
        <div class="row">
          <div class="col-12 col-sm-7 pr-sm-2 position-relative">
            <ul
              class="nav nav-tabs nav-justified nav-tabs-dropdown main-tab"
              role="tablist"
            >
              <li role="presentation">
                <a
                  href="#tags"
                  aria-controls="tags"
                  role="tab"
                  data-toggle="tab"
                  class="active"
                  >{{ "healthPro.skin" | translate }}</a
                >
              </li>
              <li role="presentation">
                <a
                  href="#filter"
                  aria-controls="filter"
                  role="tab"
                  data-toggle="tab"
                  >{{ "healthPro.typeHair" | translate }}</a
                >
              </li>
              <li role="presentation">
                <a
                  href="#serve"
                  aria-controls="serve"
                  role="tab"
                  data-toggle="tab"
                  >{{ "healthPro.serve" | translate }}</a
                >
              </li>
            </ul>
            <div class="tab-content pt-3 pr-4">
              <div role="tabpanel" class="tab-pane tab-in active" id="tags">
                <!-- <div class="text-right pb-3">
              <button class="btn btn-default"  data-toggle="modal" data-target="#skinModalCenter" >Add New Skin</button>
              </div> -->
                <div class="row">
                  <div class="col-12">
                    <div class="product-scroll">
                      <div
                        class="checkbox-box flex-radio flex-radio-lg mt-0 mb-3"
                        *ngFor="let skin of skinList; let i = index"
                      >
                        <p class="m-0 radio-title">
                          {{ 'skinList.'+skin.skin_name | translate }}
                        </p>
                        <div
                          class="custom-control custom-radio"
                          *ngFor="
                            let skintypevalue of skintypevalue;
                            let s = index
                          "
                        >
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            name="skintype{{ i }}"
                            value="{{ skintypevalue }}"
                            id="{{ skintypevalue }}-{{ i }}"
                            (change)="
                              onChangeSkin(
                                i,
                                skin.id,
                                $event,
                                skintypevalue,
                                skin.skin_name,s
                              )
                            "
                          />
                          <label
                            class="custom-control-label input-tab"
                            for="{{ skintypevalue }}-{{ i }}"
                          >
                            <span>{{ skintypevalue }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="text-right">
                <button  type="submit"  class="btn btn-default mr-2">Save</button>
                <button type="button" class="btn btn-default btn-secondary" >Cancel</button>
              </div> -->
              </div>
              <div role="tabpanel" class="tab-pane" id="filter">
                <!-- <div class="text-right pb-3"><button class="btn btn-default"  data-toggle="modal" data-target="#HairModalCenter" >Add New Hair Type</button>
              </div> -->
                <div class="row">
                  <div class="col-12">
                    <div class="product-scroll">
                      <div
                        class="checkbox-box flex-radio flex-checkbox-lg mt-0 mb-3"
                        *ngFor="let hair of hairList; let j = index"
                      >
                        <p class="m-0" style="width: 300px; font-size: 14px">
                          {{'hairList.'+ hair.hair_name | translate }}
                        </p>
                        <div class="custom-control custom-checkbox">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            value="{{ hair.id }}"
                            name="hairtyperadio{{j}}"
                            id="{{ hair.id }}"
                            (change)="
                              onChangeHair(j, hair.id, $event, hair.hair_name)
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="{{ hair.id }}"
                          >
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="text-right">
                <button  type="submit"  class="btn btn-default mr-2">Save</button>
                <button type="button" class="btn btn-default btn-secondary" >Cancel</button>
              </div> -->
              </div>
              <div role="tabpanel" class="tab-pane" id="serve">
                <!-- <div class="text-right pb-3"><button class="btn btn-default"  data-toggle="modal" data-target="#HairModalCenter" >Add New Hair Type</button>
              </div> -->
                <div class="row">
                  <div class="col-12">
                    <div class="product-scroll">
                      <h1>{{'healthPro.lifestyle' | translate }}</h1>
                      <div
                        class="checkbox-box flex-radio flex-checkbox-lg mt-0 mb-3"
                        *ngFor="let lifestyle of lifestyleList; let j = index"
                      >
                        <p class="m-0" style="width: 300px; font-size: 14px">
                          {{ 'lifestyleList.'+lifestyle.name | translate }}
                        </p>
                        <div class="custom-control custom-radio">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            value="{{ lifestyle.id }}"
                            name="lifestyleradio{{j}}"
                            id="lifestyle-{{ lifestyle.id }}"
                            (change)="
                            onChangeLifestyle(j, lifestyle.id, $event, lifestyle.name)"
                            [checked]="lifestyle.isSelected"
                          />
                          <label
                            class="custom-control-label"
                            for="lifestyle-{{ lifestyle.id }}"
                          >
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="product-scroll">
                      <h1>{{'healthPro.hairwashFrequency' | translate }}</h1>
                      <div
                        class="checkbox-box flex-radio flex-checkbox-lg mt-0 mb-3"
                        *ngFor="let hairwash of hairwashfrequencyList; let j = index"
                      >
                        <p class="m-0" style="width: 300px; font-size: 14px">
                          {{ 'hairwashfrequencyList.'+hairwash.name | translate }}
                        </p>
                        <div class="custom-control custom-radio">
                          <input
                            class="custom-control-input"
                            type="radio"
                            value="{{ hairwash.id }}"
                            name="hairwashradio"
                            id="hairwahs-{{ hairwash.id }}"
                            (change)="
                            onChangeHairWashFrequency(j, hairwash.id, $event, hairwash.name)
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="hairwahs-{{ hairwash.id }}"
                          >
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="text-right">
                <button  type="submit"  class="btn btn-default mr-2">Save</button>
                <button type="button" class="btn btn-default btn-secondary" >Cancel</button>
              </div> -->
              </div>
              <span
                *ngIf="!skin_product && !hair_product"
                class="custom-error"
                >{{ errorMessageSkin }}</span
              >
            </div>
          </div>
          <div class="col-12 col-sm-5 pt-5 mt-3 pl-sm-0">
            <div class="border-left pl-sm-3 m-border-0">
             
             <div class="row pb-2 mb-2 border-bottom">
              <div class="col-12 col-sm-5   pl-sm-0">
              <h5 class="">
                {{ "products" | translate }}
              </h5> 
            </div>
            <div class="col-12 col-sm-7  pl-sm-0">
              <div class="search-input">              
                <input
                  type="text"
                  placeholder="{{ 'search' | translate }}"
                  name="search"
                  #searchInput
                  (change)="searchByProduct(searchInput.value)"
                />
              </div>
          </div>
          </div>
             
              <div class="product-scroll">
                <div
                  class="checkbox-box flex-radio flex-checkbox mt-0 mb-3"
                  *ngFor="let product of productList; let k = index"
                >
                  <p class="m-0 radio-title">
                    {{ product.product_name | titlecase }}
                  </p>
                  <div class="custom-control custom-checkbox">
                    <input
                      class="custom-control-input"
                      type="checkbox"
                      id="{{ product.id }}"
                      value="{{ product.id }}"
                      [checked]="
                        ProductIdArray.includes(product.id) ? true : false
                      "
                      (change)="onChangeProduct(k, product.id, $event)"
                    />
                    <label class="custom-control-label" for="{{ product.id }}">
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <span *ngIf="!product_checked" class="custom-error">{{
              errorMessageProduct
            }}</span>
            <div class="text-right pt-3">
              <button
                type="button"
                (click)="onSubmitHirSkinPro()"
                class="btn btn-default mr-2"
              >
                {{ "saveBtn" | translate }}
              </button>
              <!-- <button type="button" class="btn btn-default btn-secondary" >Cancel</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="skinModalCenter" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h5 class="modal-title w-100 font-weight-bold text-center">
          Add New Skin
        </h5>
      </div>
      <form
        autocomplete="off"
        novalidate
        [formGroup]="skinForm"
        (ngSubmit)="submitSkinForm()"
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
              <label> Skin Name </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-9">
              <div class="form-group input-group">
                <input
                  type="text"
                  class="form-control small-control"
                  formControlName="skin_name"
                  maxlength="25"
                  pattern="^[a-zA-Z_\- ]+$"
                  placeholder="Skin Name"
                  [ngClass]="{
                    'is-invalid': submitted && formValidation.skin_name.errors
                  }"
                />
                <div
                  *ngIf="submitted && formValidation.skin_name.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="formValidation.skin_name.errors.required">
                    Skin Name is required
                  </div>
                  <div *ngIf="formValidation.skin_name.errors.pattern">
                    Skin Name should contain only alphabate (a-z)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <div>
            <button class="btn btn-default submitbtn mr-2" type="submit">
              Submit
            </button>
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-default btn-secondary"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div id="HairModalCenter" class="modal fade">
  <div class="modal-dialog modal-dialog-centered modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h5 class="modal-title w-100 font-weight-bold text-center">
          Add New Hair Type
        </h5>
      </div>
      <form
        autocomplete="off"
        novalidate
        [formGroup]="hairForm"
        (ngSubmit)="submitHairForm()"
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
              <label> Hair Type </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-9">
              <div class="form-group input-group">
                <input
                  type="text"
                  class="form-control small-control"
                  formControlName="hair_name"
                  maxlength="25"
                  pattern="^[a-zA-Z_\- ]+$"
                  placeholder="Hair Type"
                  [ngClass]="{
                    'is-invalid':
                      submittedHairForm && hairFormValidation.hair_name.errors
                  }"
                />
                <div
                  *ngIf="
                    submittedHairForm && hairFormValidation.hair_name.errors
                  "
                  class="invalid-feedback"
                >
                  <div *ngIf="hairFormValidation.hair_name.errors.required">
                    Hair Type is required
                  </div>
                  <div *ngIf="hairFormValidation.hair_name.errors.pattern">
                    Hair Type should contain only alphabate (a-z)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <div>
            <button class="btn btn-default submitbtn mr-2" type="submit">
              Submit
            </button>
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-default btn-secondary"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>