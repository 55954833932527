import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import AppConstants from 'src/app/shared/commons/app.constants';
@Injectable({
  providedIn: 'root',
})
export class TrainerService {
  constructor(private httpClient: HttpClient) {}

  getTrainerList(): Observable<any> {
    return this.httpClient.get(
      AppConstants.SERVICE_BASE_URL + '/api/common/Execution_Trainer'
    );
  }
  getById(id: any) {
    return this.httpClient.get<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/Execution_Trainer/' + id
    );
  }
  add(trainer: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL +
        '/api/execution_trainer/addexecution_trainer',
      trainer
    );
  }
  update(id: any, trainer: any) {
    return this.httpClient.post<any>(
      AppConstants.SERVICE_BASE_URL +
        '/api/execution_trainer/updateexecution_trainer/' +
        id,
      trainer
    );
  }
  delete(id: number) {
    return this.httpClient.delete<any>(
      AppConstants.SERVICE_BASE_URL + '/api/common/soft/Execution_Trainer/' + id
    );
  }
}
