import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { Router } from '@angular/router';
import { NotificationlistService  } from '../notification-list/notificationlist.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {
  dtOptions: DataTables.Settings = {}; 
  dtTrigger: Subject<any> = new Subject<any>();
  title = 'datatables';
  role:any='';
  notificationList:any=[]
  errorDialog: any;
  somethingWrong: any;
  successDialog: any;
  constructor(public notificationService: NotificationlistService,public router: Router,private translateService: TranslateService, public alertService: AlertService) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
   this.getNotificationList();
  }
  getNotificationList(){
    this.notificationService.getNotificationList().subscribe((res:any)=>{
      if(res['data'] && res['is_error']==false){
        this.notificationList = res["data"];
        this.dtTrigger.next();
      }
    })
  }

}
