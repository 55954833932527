import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { EffectService } from '../effect/effect.service';
import { AlertService } from 'src/app/shared/commons/alert.service';
import { TagService } from '../tags/tag.service';
import { Router } from '@angular/router';
import AppConstants from 'src/app/shared/commons/app.constants';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-effect',
  templateUrl: './effect.component.html',
  styleUrls: ['./effect.component.css']
})
export class EffectComponent implements OnInit {
  currentPlayingVideo: any;
  errorDialog: any;
  successDialog: any;
  somethingWrong: any;
  baseURL = AppConstants.SERVICE_BASE_URL;
  hair_length = AppConstants.hair_length;
  hair_type = AppConstants.hair_type;
  hair_color = AppConstants.hair_color;
  age = AppConstants.age;
  effectList: any = [];
  tagarray: any = [];
  tagArrayList = [{ tags: [] }];
  effect: any = [];
  searchTagArray: any = [];
  deleteId = '';
  tagList: any = [];
  videoPath: any;
  searchTagEffectList: any = [];
  searchTag: any = { tag_name: '' };
  hairLengthArray: any = [];
  hairTypehArray: any = [];
  hairColorArray: any = [];
  ageArray: any = [];
  postData: any = {};
  hair_length_arry = [{ hair_length: [] }];
  constructor(
    private translateService: TranslateService,
    public effectService: EffectService,
    public alertService: AlertService,
    public tagService: TagService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.postData.hair_length = [];
    this.postData.hair_type = [];
    this.postData.hair_color = [];
    this.postData.age = [];
    this.getEffectList();
    this.getTagList();
  }
  getEffectList() {
    this.effectService.getEffectList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.searchTagEffectList = data.data;
          this.effectList = data.data;
        } else {
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  show(id: any) {
    this.deleteId = id;
  }
  deletetrainer(id: any) {
    this.effectService.delete(id).subscribe(
      (data) => {
        let trainerDelete = this.translateService.instant(
          'effect.trainerDelete'
        );
        this.alertService.showSuccessAlert(this.successDialog, trainerDelete);
        setTimeout(() => {
          this.getEffectList();
        }, 5000);
      },
      (error: HttpErrorResponse) => {
       this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  getTagList() {
    this.tagService.getTagList().subscribe(
      (data) => {
        if (data.is_error == false) {
          this.tagList = data.data;
        } else {
         }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.name + ' ' + error.message);
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }
  addEffect() {
    localStorage.removeItem('effect_id');
    this.router.navigate(['addEditEffect']);
  }
  editeffect(id: any) {
    localStorage.removeItem('effect_id');
    localStorage.setItem('effect_id', id);
    this.router.navigate(['addEditEffect']);
  }

  showVideo(path: any) {
    this.videoPath = '';
    setTimeout(() => {
      this.videoPath = path;
    });
  }

  getVideoURL() {
    return this.baseURL + this.videoPath;
  }
  searchByTag(name: any) {
    this.searchTag.tag_name = name;
    this.tagService.getTagListByTag(this.searchTag).subscribe(
      (data) => {
        if (data.is_error == false) {
          this.tagList = data.data;
        }
      },
      (error: HttpErrorResponse) => {
        this.alertService.showDangerAlert(
          this.errorDialog,
          this.somethingWrong
        );
      }
    );
  }

  onChange(index: number, _id: string, isChecked: any) {
    if (isChecked.target.checked) {
      this.tagarray.push(_id);
    } else {
      this.tagarray.splice(this.tagarray.indexOf(_id), 1);
    }
    this.searchByTagId();
  }
  searchByTagId() {
    if (this.tagarray.length > 0) {
      this.searchTagArray = this.searchTagEffectList;
      let tagtest: any = [];
      let tempArray: any = [];
      this.effectList = [];
      this.effect = []
      for (var i = 0; i < this.searchTagArray.length; i++) {
        tagtest = [];
        for (var j = 0; j < this.searchTagArray[i].tags.length; j++) {
          tagtest.push(this.searchTagArray[i].tags[j].id);
        }
        tempArray.push({ tags: tagtest, image: this.searchTagArray[i] });
      }
      this.tagArrayList = tempArray;
   
      let effect: any[] = []
      this.tagArrayList.forEach((val:any)=>{
        this.tagarray.forEach((val1:any)=>{
          if(val['tags'].includes(val1)){
            effect.push(val)
          }
        })
      })
      this.effect = effect.filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i);
      for (var i = 0; i < this.effect.length; i++) {
        this.effectList[i] = this.effect[i]['image'];
      }
    } else {
      this.getEffectList();
      return;
    }
  }
  onChangeHairLength(index: number, _id: number, isChecked: any) {
    if (isChecked.target.checked) {
      this.hairLengthArray.push(_id);
    } else {  
      this.hairLengthArray.splice(this.hairLengthArray.indexOf(_id), 1);
   }
    this.postData.hair_length = this.hairLengthArray;
     this.searchByHair();
  }

  onChangeHairType(index: number, _id: number, isChecked: any) {
    if (isChecked.target.checked) {
      this.hairTypehArray.push(_id);
    } else {
      this.hairTypehArray.splice(this.hairTypehArray.indexOf(_id), 1);
     }
     this.postData.hair_type = this.hairTypehArray;
    this.searchByHair();
  }

  onChangeAge(index: number, _id: number, isChecked: any) {
    //this.hairLength_checked=true;
    if (isChecked.target.checked) {
      this.ageArray.push(_id);
    } else {
    
      this.ageArray.splice(this.ageArray.indexOf(_id), 1);
      //}
    }
    this.postData.age = this.ageArray;
    this.searchByHair();
  }
  onChangeHairColor(index: number, _id: number, isChecked: any) {
    //this.hairColor_checked=true;
    if (isChecked.target.checked) {
      this.hairColorArray.push(_id);
    } else {
     this.hairColorArray.splice(this.hairColorArray.indexOf(_id), 1);
     }
    this.postData.hair_color = this.hairColorArray;
    this.searchByHair();
  }

  searchByHair() {
    this.hair_length_arry = this.searchTagEffectList;
   let concatedArray = [];
    let hair_type_arr:any[] = [];
    let hair_length_arr:any[] = [];
    let hair_color_arr:any[] = [];
    let age_arr:any[] = [];
    let hair_type_array = [{ hair_type: [] }];
    let hair_color_array = [{ hair_color: [] }];
    let age_array = [{ age: [] }];
    if (
      this.postData['hair_length'].length > 0 ||
      this.postData['age'].length > 0 ||
      this.postData['hair_color'].length > 0 ||
      this.postData['hair_type'].length > 0
    ) {
      this.effectList = [];
      if (this.postData['hair_length'].length > 0) {
        this.hair_length_arry.forEach((val:any)=>{
          this.postData['hair_length'].forEach((val1:any) =>{
            if(val['hair_length'].includes(val1)){
              hair_type_arr.push(val);
            }
          })
        })
      } else {
      }
      if (this.postData['hair_type'].length > 0) {
        this.hair_length_arry.forEach((val:any)=>{
          this.postData['hair_type'].forEach((val1:any) =>{
            if(val['hair_type'].includes(val1)){
              hair_length_arr.push(val);
            }
          })
        })
       } else {
        }

      if (this.postData['hair_color'].length > 0) {
        this.hair_length_arry.forEach((val:any)=>{
          this.postData['hair_color'].forEach((val1:any) =>{
            if(val['hair_color'].includes(val1)){
              hair_color_arr.push(val);
            }
          })
        })
      } else {
       }
      if (this.postData['age'].length > 0) {
        this.hair_length_arry.forEach((val:any)=>{
          this.postData['age'].forEach((val1:any) =>{
            if(val['age'].includes(val1)){
              age_arr.push(val);
            }
          })
        })
       };
      concatedArray = [...hair_type_arr,...hair_length_arr,...hair_color_arr,...age_arr];
      this.effectList = concatedArray.filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i)
    }
    if (
      this.postData['hair_length'].length == 0 &&
      this.postData['age'].length == 0 &&
      this.postData['hair_color'].length == 0 &&
      this.postData['hair_type'].length == 0
    ) {
      this.getEffectList();
      return;
    }
  }

  resetFilter(){
    this.errorDialog = this.translateService.instant('error');
    this.somethingWrong = this.translateService.instant('something');
    this.successDialog = this.translateService.instant('success');
    this.postData.hair_length = [];
    this.postData.hair_type = [];
    this.postData.hair_color = [];
    this.postData.age = [];
    this.hair_length = [];
    this.hair_type = [];
    this.hair_color = [];
    this.age = [];
    this.hairLengthArray = [];
    this.hairTypehArray = [];
    this.hairColorArray = [];
    this.ageArray = [];
    this.tagarray = [];
    setTimeout(()=>{
      this.hair_length = AppConstants.hair_length;
      this.hair_type = AppConstants.hair_type;
      this.hair_color = AppConstants.hair_color;
      this.age = AppConstants.age;
    },100)
   
    this.getEffectList();
    this.getTagList();
  }

  onPlayingVideo(event: any) {
    this.currentPlayingVideo = event.target;
  }

  pauseVideo() {
    this.currentPlayingVideo.pause();
  }
}