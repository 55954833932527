// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
//  baseURL: 'http://212.71.253.181:5030'
 baseURL: 'https://weemeapi.sctestingsite.com',
 firebase:{
  // apiKey: "AAAAMKE-f6w:APA91bHVi6VPvyrLqaimCoh54XWOMFUM3Xna8o-eMniASBu8tgUhKo5stj0NERY4f6mZcT0lW27Pt0s3YbBA9sE8ET1FIALHA0XWcuMhHXR6VuQwukX6fH5rhyuNF_VXt-7TxqIzx5WB",
  // projectId: "weeme-86cab",
  // messagingSenderId: "208863657900",
  // appId:"1:208863657900:web:09414ceda52f7c96540409",
  // databaseURL: "https://weeme-86cab-default-rtdb.firebaseio.com"
  apiKey: "AIzaSyA36zfV5zVnJPCvLN75Dl5sYOqqXik3mho",
  authDomain: "weeme-86cab.firebaseapp.com",
  databaseURL: "https://weeme-86cab-default-rtdb.firebaseio.com",
  projectId: "weeme-86cab",
  storageBucket: "weeme-86cab.appspot.com",
  messagingSenderId: "208863657900",
  appId: "1:208863657900:web:09414ceda52f7c96540409",
  measurementId: "G-CTWHXSKPQQ"
 }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
