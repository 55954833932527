<app-sidebar></app-sidebar>
<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-12">
        <h1 class="page-title">
          <span *ngIf="isAddMode">{{ "addNewSalon" | translate }}</span>
          <span *ngIf="!isAddMode">{{ "editSalon" | translate }}</span>
        </h1>
      </div>
    </div>
    
    <form [formGroup]="salonform" class="form" >
    <div class="tab-box pt-3" role="tabpanel">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="tab-content">
            <div role="tabpanel1" class="tab-pane tab-in active" id="ovalemale">
              <ul class="nav nav-tabs nav-justified nav-tabs-dropdown" role="tablist1">
                <li role="presentation1">
                  <a href="#toenanche" aria-controls="toenanche" role="tab" data-toggle="tab" [ngClass]="{active:index==1}" (click)="index=1">{{'salonedetail' | translate}}</a>
                </li>
                <li role="presentation1" *ngIf="!isAddMode">
                  <a href="#toavoid" aria-controls="toavoid" role="tab" data-toggle="tab" [ngClass]="{active:index==2}" (click)="index=2">{{'credential' | translate}}</a>
                </li>
                <li role="presentation1">
                  <a href="#accessories" aria-controls="accessories" role="tab" data-toggle="tab" [ngClass]="{active:index==3}" (click)="index=3">Staff</a>
                </li>
              </ul>
              <div class="tab-content">
                <div role="tabpanel1" class="tab-pane active" id="toenanche" [ngClass]="{ active: index == 1 }">
                  <div class="row ">
                    <div class="col-12 col-xl-12">
                      
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "salonName" | translate }}
                                  <span class="custom-error-star text-danger">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salonName"
                                    class="form-control small-control"
                                    placeholder="{{ 'salonName' | translate }}"
                                    maxlength="25"
                                    pattern="^[a-zA-Z_\- ]+$"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salonName.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="submitted && formValidation.salonName.errors"
                                    class="invalid-feedback"
                                  >
                                    <div *ngIf="formValidation.salonName.errors.required">
                                      {{ "nameRequired" | translate }}
                                    </div>
                                    <div *ngIf="formValidation.salonName.errors.pattern">
                                      {{ "nameAlphabate" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "salonPEC" | translate }}
                                  <span class="custom-error-star text-danger">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_pec"
                                    class="form-control small-control"
                                    placeholder="Salon PEC"
                                    maxlength="50"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salon_pec.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="submitted && formValidation.salon_pec.errors"
                                    class="invalid-feedback"
                                  >
                                    <div *ngIf="formValidation.salon_pec.errors.required">
                                      {{ "salonPECRequired" | translate }}
                                    </div>
                                    <!-- <div *ngIf="formValidation.salonName.errors.pattern"> Name should contain only alphabate (a-z)</div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
              
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "salonAdminEmail" | translate }}
                                  <span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="email"
                                    formControlName="salon_admin_email"
                                    class="form-control small-control"
                                    placeholder=" {{ 'salonAdminEmail' | translate }}"
                                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salon_admin_email.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="
                                      submitted && formValidation.salon_admin_email.errors
                                    "
                                    class="invalid-feedback"
                                  >
                                    <div
                                      *ngIf="formValidation.salon_admin_email.errors.required"
                                    >
                                      {{ "emailRequired" | translate }}
                                    </div>
                                    <div
                                      *ngIf="formValidation.salon_admin_email.errors.pattern"
                                    >
                                      {{ "validEmailAddress" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "bankAccountOwner" | translate }}
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_bank_account_owner"
                                    class="form-control small-control"
                                    placeholder=" {{ 'bankAccountOwner' | translate }}"
                                    maxlength="50"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "salonAdminPwd" | translate }}
                                  <span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="password"
                                    formControlName="password"
                                    class="form-control small-control"
                                    minlength="6"
                                    maxlength="12"
                                    placeholder="{{ 'salonAdminPwd' | translate }}"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.password.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="submitted && formValidation.password.errors"
                                    class="invalid-feedback"
                                  >
                                    <div *ngIf="formValidation.password.errors.required">
                                      {{ "pwdreq" | translate }}
                                    </div>
                                    <div *ngIf="formValidation.password.errors.minlength">
                                      {{ "pwdLength" | translate }}
                                    </div>
                                    <div *ngIf="formValidation.password.errors.maxlength">
                                      {{ "pwdLength" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "salonBank" | translate }}
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_bank"
                                    class="form-control small-control"
                                    placeholder="{{ 'salonBank' | translate }} "
                                    maxlength="50"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "salonAdminName" | translate }}
                                  <span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_admin_name"
                                    class="form-control small-control"
                                    placeholder=" {{ 'salonAdminName' | translate }}"
                                    maxlength="25"
                                    pattern="^[a-zA-Z_\- ]+$"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salon_admin_name.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="
                                      submitted && formValidation.salon_admin_name.errors
                                    "
                                    class="invalid-feedback"
                                  >
                                    <div
                                      *ngIf="formValidation.salon_admin_name.errors.required"
                                    >
                                      {{ "adminReq" | translate }}
                                    </div>
                                    <div
                                      *ngIf="formValidation.salon_admin_name.errors.pattern"
                                    >
                                      {{ "adminAlphabet" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "salonBankAccId" | translate }}
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_bankaccountid"
                                    class="form-control small-control"
                                    placeholder="{{ 'salonBankAccId' | translate }} "
                                    maxlength="50"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "surname" | translate
                                  }}<span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_admin_surname"
                                    class="form-control small-control"
                                    placeholder=" {{ 'surname' | translate }}"
                                    maxlength="25"
                                    pattern="^[a-zA-Z_\- ]+$"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salon_admin_surname.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="
                                      submitted && formValidation.salon_admin_surname.errors
                                    "
                                    class="invalid-feedback"
                                  >
                                    <div
                                      *ngIf="
                                        formValidation.salon_admin_surname.errors.required
                                      "
                                    >
                                      {{ "surnameReq" | translate }}
                                    </div>
                                    <div
                                      *ngIf="
                                        formValidation.salon_admin_surname.errors.pattern
                                      "
                                    >
                                      {{ "surnameAlphabate" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "salonLogo" | translate }}
                                  <span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7" *ngIf="isAddMode">
                                <div class="form-group input-group">
                                  <input
                                    formControlName="salon_logo"
                                    id="file"
                                    type="file"
                                    class="form-control"
                                    (change)="onFileChange($event)"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salon_logo.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="submitted && formValidation.salon_logo.errors"
                                    class="invalid-feedback"
                                  >
                                    <div *ngIf="formValidation.salon_logo.errors.required">
                                      {{ "logoReq" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-12 col-sm-12 col-xl-7"
                                style="padding-bottom: 5px"
                                *ngIf="!isAddMode"
                              >
                                <input
                                  style="display: none"
                                  formControlName="salon_logo"
                                  id="file"
                                  type="file"
                                  (change)="onFileChange($event)"
                                />
                                <label for="file" class="glass-list mb-0" *ngIf="imageUrl">
                                  <img src="{{ imageUrl }}" class="glass-img" />
                                </label>
                                <label for="file" class="glass-list mb-0" *ngIf="!imageUrl">
                                  <img src="{{ baseURL }}{{ imgurl }}" class="glass-img" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "phone" | translate }}
                                  <span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_admin_phone"
                                    class="form-control small-control"
                                    placeholder="{{ 'phone' | translate }}"
                                    minlength="10"
                                    maxlength="12"
                                    pattern="^[\+\d]?(?:[\d-.\s()]*)$"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salon_admin_phone.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="
                                      submitted && formValidation.salon_admin_phone.errors
                                    "
                                    class="invalid-feedback"
                                  >
                                    <div
                                      *ngIf="formValidation.salon_admin_phone.errors.required"
                                    >
                                      {{ "phoneReq" | translate }}
                                    </div>
                                    <div
                                      *ngIf="
                                        formValidation.salon_admin_phone.errors.minlength
                                      "
                                    >
                                      {{ "phoneContains" | translate }}
                                    </div>
                                    <div
                                      *ngIf="formValidation.salon_admin_phone.errors.pattern"
                                    >
                                      {{ "phoneDigit" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "facebook" | translate }}
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_facebookpage"
                                    class="form-control small-control"
                                    placeholder="{{ 'facebook' | translate }}"
                                    maxlength="150"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
              
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div class="col-12 col-sm-12 col-xl-5">
                                <label>
                                  {{ "salonAddress" | translate }}
                                  <span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <textarea
                                    class="form-control"
                                    formControlName="salon_address"
                                    rows="3"
                                    placeholder="Salon Address"
                                    id="salon_address"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salon_address.errors
                                    }"
                                  ></textarea>
              
                                  <div
                                    *ngIf="submitted && formValidation.salon_address.errors"
                                    class="invalid-feedback"
                                  >
                                    <div *ngIf="formValidation.salon_address.errors.required">
                                      {{ "addreq" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "instagram" | translate }}
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_instagramaccount"
                                    class="form-control small-control"
                                    placeholder="{{ 'instagram' | translate }}"
                                    maxlength="150"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
              
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "zipcode" | translate }}
                                  <span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_zipcode"
                                    class="form-control small-control"
                                    placeholder=" {{ 'zipcode' | translate }}"
                                    maxlength="11"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salon_zipcode.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="submitted && formValidation.salon_zipcode.errors"
                                    class="invalid-feedback"
                                  >
                                    <div *ngIf="formValidation.salon_zipcode.errors.required">
                                      {{ "zipreq" | translate }}
                                    </div>
                                    <div *ngIf="formValidation.salon_zipcode.errors.pattern">
                                      {{ "zippattern" | translate }}
                                    </div>
                                    <!-- pattern="^[\+\d]?(?:[\d-.\s()]*)$"   -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "linkedin" | translate }}
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_linkedinaccount"
                                    class="form-control small-control"
                                    placeholder="{{ 'linkedin' | translate }}"
                                    maxlength="150"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
              
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "city" | translate
                                  }}<span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_city"
                                    class="form-control small-control"
                                    placeholder="{{ 'city' | translate }}"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salon_city.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="submitted && formValidation.salon_city.errors"
                                    class="invalid-feedback"
                                  >
                                    <div *ngIf="formValidation.salon_city.errors.required">
                                      {{ "cityreq" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "website" | translate }}
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_website"
                                    class="form-control small-control"
                                    placeholder="{{ 'website' | translate }}"
                                    maxlength="150"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salon_website.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="submitted && formValidation.salon_website.errors"
                                    class="invalid-feedback"
                                  >
                                    <div *ngIf="formValidation.salon_website.errors.pattern">
                                      {{ "websitePattern" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "region" | translate }}
                                  <span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_region"
                                    class="form-control small-control"
                                    placeholder=" {{ 'region' | translate }}"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salon_region.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="submitted && formValidation.salon_region.errors"
                                    class="invalid-feedback"
                                  >
                                    <div *ngIf="formValidation.salon_region.errors.required">
                                      {{ "regionreq" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "cloudAPIKey" | translate }}
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_incloudapikey"
                                    class="form-control small-control"
                                    placeholder="{{ 'cloudAPIKey' | translate }}"
                                    maxlength="150"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
              
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "vat" | translate }}
                                  <span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_vat"
                                    class="form-control small-control"
                                    placeholder=" {{ 'vat' | translate }}"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salon_vat.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="submitted && formValidation.salon_vat.errors"
                                    class="invalid-feedback"
                                  >
                                    <div *ngIf="formValidation.salon_vat.errors.required">
                                      {{ "vatreq" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "startingContract" | translate
                                  }}<span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="date"
                                    class="form-control small-control"
                                    formControlName="starting_contract"
                                    placeholder="{{ 'startingContract' | translate }}"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.starting_contract.errors
                                    }"
                                  />
                                  <!-- | <input type="text" formControlName="salon_incloudapikey" class="form-control small-control" placeholder="Salon Clouud API Key " maxlength="150"  /> -->
                                  <div
                                    *ngIf="
                                      submitted && formValidation.starting_contract.errors
                                    "
                                    class="invalid-feedback"
                                  >
                                    <div
                                      *ngIf="formValidation.starting_contract.errors.required"
                                    >
                                      {{ "startReq" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
              
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "cf" | translate }}
                                  <span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_cf"
                                    class="form-control small-control"
                                    placeholder=" {{ 'cf' | translate }} "
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salon_cf.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="submitted && formValidation.salon_cf.errors"
                                    class="invalid-feedback"
                                  >
                                    <div *ngIf="formValidation.salon_cf.errors.required">
                                      {{ "cfreq" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "endcontract" | translate }}
                                  <span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="date"
                                    class="form-control small-control"
                                    formControlName="ending_contract"
                                    placeholder="{{ 'endcontract' | translate }}"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.ending_contract.errors
                                    }"
                                  />
                                  <div
                                    *ngIf="submitted && formValidation.ending_contract.errors"
                                    class="invalid-feedback"
                                  >
                                    <div
                                      *ngIf="formValidation.ending_contract.errors.required"
                                    >
                                      {{ "endcontreq" | translate }}
                                    </div>
                                  </div>
                                  <!--  <input type="text" formControlName="ending_contract" class="form-control small-control" placeholder="Salon Clouud API Key " maxlength="150"  /> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-12">
                            <div class="row">
                              <div
                                class="col-12 col-sm-12 col-xl-5 d-flex align-items-center"
                              >
                                <label>
                                  {{ "salon_sms_sender" | translate }}
                                  <span class="custom-error-star">*</span>
                                </label>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-7">
                                <div class="form-group input-group">
                                  <input
                                    type="text"
                                    formControlName="salon_sms_sender"
                                    class="form-control small-control"
                                    placeholder=" {{ 'salon_sms_sender' | translate }}"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted && formValidation.salon_sms_sender
                                    }"
                                  />
                                  <div
                                    *ngIf="submitted && formValidation.salon_sms_sender.errors"
                                    class="invalid-feedback"
                                  >
                                    <div >
                                      {{ "salon_sms_sender_req" | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                        <div class="text-right">
                          <button type="submit" class="btn btn-default mr-2" *ngIf="!isAddMode" (click)="onSubmit()">
                            {{ "saveBtn" | translate }}
                          </button>
                          <button
                            type="button"
                            (click)="clear()"
                            class="btn btn-default btn-secondary" *ngIf="!isAddMode"
                          >
                            {{ "cancel" | translate }}
                          </button>
                          
                          <a href="javascript:void(0)" aria-controls="accessories" role="tab" data-toggle="tab" class="btn btn-default" *ngIf="isAddMode"  (click)="nextSalone()">{{'nextBtn' | translate}}</a>
                          
                          <!-- <li role="presentation1" *ngIf="!isAddMode">
                          <a href="#toavoid" aria-controls="toavoid" (click)="index=2" role="tab" data-toggle="tab" class="btn btn-default" *ngIf="!isAddMode">{{'nextBtn' | translate}}</a>
                          </li> -->
                          
                        </div>
                    
                    </div>
                  </div>
                </div>
                <div role="tabpanel1" class="tab-pane" id="toavoid" [ngClass]="{ active: index == 2 }">
                  <form #f="ngForm" class="form">
                  <div class="row">
                    
                    <div class="form-group input-group col-sm-4">
                      <input type="text" class="form-control small-control" disabled name="email" [(ngModel)]="slaonAdminMail" placeholder="{{'username' | translate}}">
                    </div>
                    <div class="col-sm-4">
                      <input type="password" class="form-control small-control" name="password" [(ngModel)]="userDetail.password" placeholder="{{'password' | translate}}" required>
                    </div>
                    <div class="col-sm-4">
                      <input type="password" class="form-control small-control" name="confirmpass" [(ngModel)]="confirmPass" placeholder="{{'confirmpass' | translate}}" required>
                    </div>
                    <div class="col-sm-12">
                      <div class="text-right">
                        <button class="btn btn-default mr-2" *ngIf="!isAddMode" [disabled]="f.invalid || (userDetail.password!=confirmPass)" (click)="saveCredential(f)">{{'saveBtn' | translate}}</button>
                        <!-- <a href="#toenanche" aria-controls="toenanche" role="tab" class="btn btn-default btn-secondary mr-2" data-toggle="tab" *ngIf="!isAddMode" (click)="index=1">Back</a> -->
                        
                        <button
                            type="button"
                            (click)="clear()"
                            class="btn btn-default btn-secondary" 
                          >
                            {{ "cancel" | translate }}
                          </button>
                        
                      </div>
                    </div>
                  
                  </div>
                </form>
                </div>
                <div role="tabpanel1" class="tab-pane" id="accessories" [ngClass]="{ active: index == 3 }">
                  <div id="customFields" >
                    
                    <ng-container *ngFor="let salStaff of staffs.controls; let i=index"  formArrayName="staffs">
                    <div [formGroupName]="i">                    
                      <div class="row">
                      <div class="col-sm-4">
                        <input type="text" class="form-control small-control" formControlName="name" value="" placeholder="{{'name' | translate}}">
                      </div>
                      <div class="col-sm-3">
                        <input type="text" class="form-control small-control" formControlName="surname" placeholder="{{'staffsurname' | translate}}">
                      </div>
                      <div class="col-sm-3">
                        <input type="text" class="form-control small-control" formControlName="phone_no" placeholder="{{'staffphoneno' | translate}}">
                      </div>
                      <div class="col-sm-1 text-right" *ngIf="i==0">
                        <a href="javascript:void(0);" class="addCF" (click)="addStaff()">+</a>
                      </div>
                      <div class="col-sm-1 text-right" *ngIf="i!=0">
                        <a href="javascript:void(0);" class="remCF" (click)="removeStaff(i)">X</a>
                      </div>
                  </div>
                  </div>
                </ng-container>
                  <div class="pt-3">
                    <div class="table-responsive">
                      <table id="example" class="table table-striped" style="width:100%; text-align: center;">
                        <thead>
                            <tr>
                              <th>{{'name' | translate}}</th>
                              <th>{{'staffsurname' | translate}}</th>
                              <th>{{'staffphoneno' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let salStaff of staffs.controls">
                            <td>{{salStaff.value.name}}</td>
                            <td>{{salStaff.value.surname}}</td>
                            <td>{{salStaff.value.phone_no}}</td>
                          </tr>
                          <!-- <tr>
                            <td>Test</td>
                            <td>123</td>
                            <td>1234567890</td>
                          </tr>
                          <tr>
                            <td>Test</td>
                            <td>123</td>
                            <td>1234567890</td>
                          </tr>
                          <tr>
                            <td>Test</td>
                            <td>123</td>
                            <td>1234567890</td>
                          </tr> -->
                        </tbody>
                      </table> 
                    </div>
                    <div class="col-sm-12">
                      <div class="text-right">
                        <a href="#toenanche" aria-controls="toavoid" *ngIf="isAddMode" role="tab" data-toggle="tab" class="btn btn-default btn-secondary mr-2" (click)="index=1">{{'backBtn'| translate}}</a>
                        <button class="btn btn-default mr-2" *ngIf="!isAddMode" (click)="onSubmit()">{{'saveBtn' | translate}}</button>
                        <button class="btn btn-default mr-2" *ngIf="isAddMode" (click)="onSubmit()">{{'saveBtn' | translate}}</button>
                        
                        <!-- <a href="#toavoid" aria-controls="toavoid" role="tab" data-toggle="tab" *ngIf="!isAddMode" class="btn btn-default btn-secondary mr-2" (click)="index=2">Back</a> -->
                        <button
                            type="button"
                            (click)="clear()"
                            class="btn btn-default btn-secondary" 
                          >
                            {{ "cancel" | translate }}
                          </button>
                        
                      </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  
  </div>
</form>
</div>
