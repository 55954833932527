import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/commons/alert.service';
@Component({
  selector: 'app-addhealth',
  templateUrl: './addhealth.component.html',
  styleUrls: ['./addhealth.component.css']
})
export class AddhealthComponent implements OnInit {
  healthform = new FormGroup({});
  submitted=false;
  productarray:any=[];
  constructor(private formBuilder: FormBuilder,public alertService:AlertService) { }

  ngOnInit(): void {
    this.productFormValidation();
  }
  productFormValidation(){
  this.healthform = this.formBuilder.group({    
    image_path_name:['',Validators.required],
    fileSource: [''],  
    problem_name:['',Validators.required],
    products:[''],
    curative_therapy:[''], 
  });
} 
get formValidation() { return this.healthform.controls; }
onFileChange(event:any) {  
  // if (event.target.files.length > 0) {
  //   const file = event.target.files[0];
  //   this.trainerform.patchValue({
  //     fileSource: file
  //   });
  // }
}
onSubmit()
{   
  this.submitted = true;    
     if (this.healthform.invalid) {      
     return;  
   }
}
onChange(index: number, _id: string,isChecked:any) {

  // if (isChecked.target.checked) {
  //   this.productarray.push(_id);
  // } 
  // else 
  // {
  //   if(this.productarray.indexOf(index)==0)
  //   {
  //     this.productarray.splice(this.productarray.indexOf(index),1);
  //   }
  //   else{
  //   this.productarray.splice(this.productarray.indexOf(index)-1,1);
  //   }
  // }
  // this.formValidation.products.setValue(this.productarray);
 
}
}
