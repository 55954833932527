import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export default class AppConstants {
  static APP_TITLE = 'Wee Me';

  static SERVICE_BASE_URL = environment.baseURL;
  static TOKEN_KEY = 'token';
  //static IS_LOGGEDIN = 'isLoggedin';

  static getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }
  static getImageHeaders() {
    return new HttpHeaders({
      'Content-Length': '2070',
      // 'Content-Type': 'multipart/form-data',
    });
  }
  static hair_length = [
    { id: 1, length: 'Short' },
    { id: 2, length: 'Medium' },
    { id: 3, length: 'Long' },
  ];
  static hair_type = [
    { id: 1, type: 'Smooth' },
    { id: 2, type: 'Wavy' },
    { id: 3, type: 'Curly' },
  ];
  static hair_color = [
    { id: 1, color: 'Light' },
    { id: 2, color: 'Dark' },
  ];
  static age = [
    { id: 1, age: 'Young' },
    { id: 2, age: 'Adult' },
    { id: 3, age: 'Senior' },
  ];
  static category_list = [
    { id: 1, category: 'Celebrity' },
    { id: 2, category: 'People' },
    { id: 3, category: 'Design' },
  ];
  static skinList = [
    { id: 1, skin_name: 'Normal' },
    { id: 2, skin_name: 'Desquamata' },
    { id: 3, skin_name: 'Sebum' },
    { id: 4, skin_name: 'Hyperhydrosis' },
    { id: 5, skin_name: 'Red' },
    { id: 6, skin_name: 'Tesa' },
    { id: 7, skin_name: 'Thinnnig' },
  ];
  static HairList = [
    { id: 1, hair_name: 'Normal' },
    { id: 2, hair_name: 'Semi Permanent Color' },
    { id: 3, hair_name: 'Oxidation Coloring' },
    { id: 4, hair_name: 'Dried And Treated' },
    { id: 5, hair_name: 'Dried Natural' },
    { id: 6, hair_name: 'Crepe' },
    { id: 7, hair_name: 'Porosis' },
    { id: 8, hair_name: 'Bleached' },
  ];
  static productTagFilter = [
    { id: 1, name: 'Hair Length - Short', field: 'hair_length', value: 1 },
    { id: 2, name: 'Hair Length - Medium', field: 'hair_length', value: 2 },
    { id: 3, name: 'Hair Length - Long', field: 'hair_length', value: 3 },
    { id: 4, name: 'Hair Type - Smooth', field: 'hair_type', value: 1 },
    { id: 5, name: 'Hair Type - Wavy', field: 'hair_type', value: 2 },
    { id: 6, name: 'Hair Type - Curly', field: 'hair_type', value: 3 },
    { id: 7, name: 'Hair Colour - Light', field: 'hair_color', value: 1 },
    { id: 8, name: 'Hair Colour - Colour', field: 'hair_color', value: 2 },
    { id: 9, name: 'Hair Colour - Dark', field: 'hair_color', value: 3 },
  ];

  static skinColorList = [
    { id: 1, skin_color: 'Olive' },
    { id: 2, skin_color: 'Golden' },
    { id: 3, skin_color: 'Amber' },
    { id: 4, skin_color: 'Clear' },
  ];

  static seasonList = [
    { id: 1, name: 'Winter' },
    { id: 2, name: 'Spring' },
    { id: 3, name: 'Summer' },
    { id: 4, name: 'Autunn' },
  ];

  static faceshapeList = [
    { id: 1, name: 'Squared' },
    { id: 2, name: 'Round' },
    { id: 3, name: 'Triangle' },
    { id: 4, name: 'Diamond' },
    { id: 5, name: 'Rectangular' },
    { id: 6, name: 'Oval' },
  ];

  static lifestyleList = [
    {id: 1, name: 'Active/Dynamic',isSelected :false},
    {id: 2, name: 'Smart',isSelected :false},
    {id: 3, name: 'Relax',isSelected :false}
  ]

  static hairwashfrequencyList = [
    {id: 1, name: 'Once a week'},
    {id: 2, name: 'Twice a week'},
    {id: 3, name: 'Everyday'}
  ]
}
