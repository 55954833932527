<app-sidebar>    
</app-sidebar>
<div class="body-padding-top">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-12">
        <h1 class="page-title">         
          <span *ngIf="isAddMode">{{'image.addimage'|translate}}</span>
          <span *ngIf="!isAddMode">{{'image.editimage'|translate}}</span>
        </h1>
      </div> 
    </div>
    <div class="row pt-3">
      <div class="col-12 col-xl-12">
        <form [formGroup]="imageform"  class="form" (ngSubmit)="onSubmit()">
          <div class="row mb-3">
            <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
              <label>
               {{'image.selectImage'|translate}}  <span *ngIf="isAddMode"  class="custom-error-star">*</span>
              </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-6" >
              <div class="input-group mb-2 "  *ngIf="isAddMode">
                  <input  formControlName="image_path_name"                      
                  id="file"                       
                  type="file"                       
                  class="form-control"               
                  (change)="onFileChange($event)" [ngClass]="{ 'is-invalid': submitted && formValidation.image_path_name.errors }"/>  
                 <div *ngIf="submitted && formValidation.image_path_name.errors" class="invalid-feedback">
                    <div *ngIf="formValidation.image_path_name.errors.required">{{'image.imageReq' |translate}}</div>
                </div>             
              </div>
              <div class="col-12 col-sm-4 col-xl-4 p-0 d-flex align-items-center"  *ngIf="!isAddMode">              
                <input style="display:none;" formControlName="image_path_name"                      
                id="file"                       
                type="file" 
                (change)="onFileChange($event)" >   
                <label for="file" class="glass-list mb-0" *ngIf="imageUrl">
                    <img src="{{imageUrl}}" class="glass-img"> 
                </label>  
                <label for="file" class="glass-list mb-0" *ngIf="!imageUrl">
                  <img src="{{baseURL}}{{imgurl}}" class="glass-img"> 
              </label> 
            </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
              <label>
              {{'image.selectCategory'|translate}} <span class="custom-error-star">*</span>
              </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-9">
              <div class="input-group">
                <select class="form-select form-control small-control"  formControlName="category" [ngClass]="{ 'is-invalid': submitted && formValidation.category.errors }">
                  <option value="" selected="selected"> {{'image.selectCategory'|translate}}</option>
                  <option value="{{category.id}}" *ngFor="let category of category_list">{{category.category}}</option>
                </select>
                <div *ngIf="submitted && formValidation.category.errors" class="invalid-feedback">
                  <div *ngIf="formValidation.category.errors.required">{{'image.categoryMsg'|translate}} </div>
              </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
              <label>
              {{'image.selectfaceshape'|translate}} <span class="custom-error-star">*</span>
              </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-9">
              <div class="input-group">
                <select class="form-select form-control small-control"  formControlName="faceshape" [ngClass]="{ 'is-invalid': submitted && formValidation.faceshape.errors }">
                  <option value="" selected="selected"> {{'image.selectfaceshape'|translate}} </option>
                  <option value="{{face.typeValue}}" *ngFor="let face of faceshapeList">{{face.typeName | titlecase }}</option>
                </select>
                <div *ngIf="submitted && formValidation.faceshape.errors" class="invalid-feedback">
                  <div *ngIf="formValidation.faceshape.errors.required"> {{'image.faceshapeMsg'|translate}} </div>
              </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
          <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
            <label>
             Products
            </label>
          </div>
          <div class="col-12 col-sm-12 col-xl-9">
            <div class="input-group">
                <ng-select bindLabel="product_name"
                      placeholder="Select Product"
                      appendTo="body"
                      [multiple]="true"
                      [searchable]="true" 
                      [clearable]="true"
                      formControlName="products"
                      [items]="productList"
                      
                      >
                      <ng-option [value]="item.id"  *ngFor="let item of productList">
                          
                          {{item.product_name}}
                      </ng-option>
                  </ng-select>
              </div>
          </div>
        </div>
          <div class="row mb-3">
            <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
              <label>
                {{'image.gender'|translate}} <span class="custom-error-star">*</span>
              </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-9">
              <div class="input-group images-radio-box">
                <p >
                  <input type="radio" id="male"   [checked]="gendervalue==1? true : false" name="gender" value="1" (change)="onItemChange($event)">
                  <label for="male">{{'image.male'|translate}}</label>
                </p>
                <p>
                  <input type="radio" id="female"  name="gender"  [checked]="gendervalue==2? true : false"  value="2" (change)="onItemChange($event)">
                  <label for="female">{{'image.female'|translate}}</label>
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 col-sm-12 col-xl-3 d-flex align-items-center">
              <label>
               {{'image.harmony'|translate}}<span class="custom-error-star">*</span>
              </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-9">
              <div class="input-group images-radio-box">
                <p >
                  <input type="radio"  id="yes"   [checked]="harmonyvalue==true? true : false" name="harmony" value="true" (change)="onharmonyChange($event)">
                  <label for="yes">{{'image.yes'|translate}}</label>
                </p>
                <p>
                  <input type="radio" id="no"  name="harmony"  [checked]="harmonyvalue==false? true : false"  value="false" (change)="onharmonyChange($event)">
                  <label for="no" >{{'image.no'|translate}}</label>
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 col-sm-12 col-xl-3">
              <label>
           {{'image.skinColour'|translate}}
              </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-9">
              <div class="input-group images-checkbox-box">            
                <div class="row w-100"  >
                  <div class="col-6 col-sm-4 col-md-3 col-lg-3" *ngFor="let skincolor of skinColorList;let i = index" >
                    <label class="container" >{{skincolor.skin_color}} 
                      <input type="checkbox"  id="{{skincolor.id}}" value="{{skincolor.id}}"  [checked]="skinColorArray.includes(skincolor.id)? true : false"  (change)="onChangeSkinColor(i,skincolor.id,$event)">
                      <span class="checkmark"></span>
                    </label> 
                  </div>
                </div>             
              </div>            
              </div>
            </div>  
           <div class="row mb-3">
            <div class="col-12 col-sm-12 col-xl-3">
              <label>
           {{'hairLength'|translate}}
              </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-9">
              <div class="input-group images-checkbox-box">            
                <div class="row w-100"  >
                  <div class="col-6 col-sm-4 col-md-3 col-lg-3" *ngFor="let length of hair_length;let i = index" >
                    <label class="container" >{{length.length}} 
                      <input type="checkbox"  id="{{length.id}}" value="{{length.id}}"  [checked]="hairLengthArray.includes(length.id)? true : false"  (change)="onChangeHairLength(i,length.id, $event)">
                      <span class="checkmark"></span>
                    </label> 
                  </div>
                </div>             
              </div>            
              </div>
            </div>  
           <div class="row mb-3">
            <div class="col-12 col-sm-12 col-xl-3">
              <label>
                {{'hairType'|translate}}
              </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-9">
              <div class="input-group images-checkbox-box">            
                <div class="row w-100"  >
                  <div class="col-6 col-sm-4 col-md-3 col-lg-3" *ngFor="let type of hair_type;let j = index" >
                    <label class="container" >{{type.type}} 
                      <input type="checkbox"  id="{{type.id}}" value="{{type.id}}"  [checked]="hairTypehArray.includes(type.id)? true : false" (change)="onChangeHairType(j,type.id, $event)" >
                      <span class="checkmark"></span>
                    </label> 
                  </div>
                </div>               
              </div>            
              </div>             
            </div>          
          <div class="row mb-3">
            <div class="col-12 col-sm-12 col-xl-3">
              <label>
            {{'haircolor'|translate}}
              </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-9">
              <div class="input-group images-checkbox-box">            
                <div class="row w-100"  >
                  <div class="col-6 col-sm-4 col-md-3 col-lg-3" *ngFor="let color of hair_color;let c = index" >
                    <label class="container" >{{color.color}} 
                      <input type="checkbox"  id="{{color.id}}" value="{{color.id}}" [checked]="hairColorArray.includes(color.id)? true : false"  (change)="onChangeHairColor(c,color.id, $event)" >
                      <span class="checkmark"></span>
                    </label> 
                  </div>
                </div>               
              </div>            
              </div>             
            </div>         
           <div class="row mb-3">
            <div class="col-12 col-sm-12 col-xl-3">
              <label>
             {{'age'|translate}}
              </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-9">
              <div class="input-group images-checkbox-box">            
                <div class="row w-100"  >
                  <div class="col-6 col-sm-4 col-md-3 col-lg-3" *ngFor="let age of age;let a = index" >
                    <label class="container" >{{age.age}} 
                      <input type="checkbox"  id="{{age.id}}"  value="{{age.id}}" [checked]="ageArray.includes(age.id)? true : false"  (change)="onChangeAge(a,age.id, $event)" >
                      <span class="checkmark"></span>
                    </label> 
                  </div>
                </div>               
              </div>            
              </div>             
            </div>        
          <div class="row mb-3">
            <div class="col-12 col-sm-12 col-xl-3">
              <label>
            {{'image.selectTag'|translate}}
              </label>
            </div>
            <div class="col-12 col-sm-12 col-xl-9">
              <div class="input-group images-checkbox-box scroll-tag">            
                <div class="row w-100 mr-0"  >
                  <div class="col-6 col-sm-4 col-md-3 col-lg-3" *ngFor="let tag of tagList;let i = index" >
                    <label class="container" >{{tag.tag_name}} 
                      <input type="checkbox"  id="{{tag.id}}" value="{{tag.id}}" [checked]="tagarray.includes(tag.id)? true : false" (change)="onChange(i,tag.id, $event)">
                      <span class="checkmark"></span>
                    </label> 
                  </div>
                </div>             
              </div> 
              </div>
            </div> 
          <div class="text-right">
            <button type="submit" class="btn btn-default mr-2">{{'saveBtn'|translate}}</button>
            <button type="button" class="btn btn-default btn-secondary" routerLink="/imageList">{{'cancel'|translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>




















       
 