import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import AppConstants from 'src/app/shared/commons/app.constants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  postData: any;

  constructor(public router: Router, public http: HttpClient) {}
  // Returns true when user is looged
  get isLoggedIn(): boolean {
    const user = localStorage.getItem('user');
    return user !== null ? true : false;
  }
  //Login Api call
  login(username: any, password: any, role: any) {
    // console.log(username);
    return this.http.post(
      AppConstants.SERVICE_BASE_URL + '/auth/local/user',
      { username, password },
      httpOptions
    );
  }
  addPushNotificationdeviceToken(token:any,id:any){
    return this.http.post(
     AppConstants.SERVICE_BASE_URL + '/api/common/Salon/'+id,token,httpOptions
    )
  }
}
