import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessagingService } from '../../../services/messaging.service';
import {AlertService} from '../../../shared/commons/alert.service'
import * as $ from 'jquery'
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  langFlag = true;
  toggleClass: boolean = false;
  index: any;
  userName: any = '';
  currenturl: string = '';
  browserLang: any = '';
  role: any = '';
  message:any;
  constructor(public router: Router, public translate: TranslateService,private messagingService:MessagingService,private alertService:AlertService) {
    translate.addLangs(['en', 'it']);
    if (localStorage.getItem('locale')) {
      this.browserLang = localStorage.getItem('locale');
      if (this.browserLang == 'it') this.langFlag = false;
      else this.langFlag = true;
      translate.use(this.browserLang.match(/it|en/) ? this.browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
      if (this.browserLang == 'it') this.langFlag = false;
      else this.langFlag = true;
    }
  }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.userName = localStorage.getItem('user');
    this.userName = this.userName.replace(/^"(.*)"$/, '$1');
    this.currenturl = this.router.url;
   // this.alertService.showToastNotification('test','test');
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.messagingService.currentMessage.subscribe((res:any)=>{
      if(res){
        this.message=res;
        // console.log('message',this.message);
      if(this.message && this.message.data){
      let text = this.translate.instant('order_placed');
      let title = this.translate.instant('new_order_arrives');
      this.alertService.showToastNotification(title,text+this.message.data.name)
    }
      }
    });
    
    if (this.currenturl == '/dashboard' || this.currenturl == '/addEditUser')
      this.index = 1;
    else if (this.currenturl == '/faceShapeInfo') this.index = 2;
    else if (
      this.currenturl == '/taglist' ||
      this.currenturl == '/addtag' ||
      this.currenturl == '/viewtagList'
    )
      this.index = 3;
    else if (
      this.currenturl == '/imageList' ||
      this.currenturl == '/addEditImage'
    )
      this.index = 4;
    else if (
      this.currenturl == '/trainerList' ||
      this.currenturl == '/addEditTrainer'
    )
      this.index = 5;
      else if (
      this.currenturl == '/effectList' ||
      this.currenturl == '/addEditEffect'
    )
      this.index = 11;
    else if (
      this.currenturl == '/productList' ||
      this.currenturl == '/addEditProduct'
    )
      this.index = 6;
    else if (
      this.currenturl == '/healthList' ||
      this.currenturl == '/addEditHealth'
    )
      this.index = 7;
    else if (
      this.currenturl == '/productTagList' ||
      this.currenturl == '/viewProductTagList'
    )
      this.index = 8;
    else if (this.currenturl == '/customerList') this.index = 9;
    else if (this.currenturl == '/orderList') this.index = 10;

    else this.index = 1;
  }

  logout() {
    localStorage.removeItem('role');
    localStorage.removeItem('edit_id');
    localStorage.removeItem('product_id');
    localStorage.removeItem('trainer_id');
    localStorage.removeItem('user');
    localStorage.removeItem('locale');
    localStorage.removeItem('faceshapeList');
    localStorage.removeItem('userId');
    this.router.navigateByUrl('/');
  }

  openSideBar() {
    this.toggleClass = !this.toggleClass;
  }

  changeLang(language: string) {
    if (language == 'it') {
      this.langFlag = false;
    } else {
      this.langFlag = true;
    }
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }
    
  
 
}
